import { orderInterface } from './orders';
import { packageItemInterface } from './packageItems';
import { companyServiceWithPivotInterface } from './companyServices';
import { attachmentInterface } from './attachments';
import axios from '@/helpers/forms/axios';

export interface boxInterface {
    id: number
    createdAt: string
    updatedAt: string
    weightGm: number
    lengthMm: number
    widthMm: number
    heightMm: number
    boxableId: number
    boxableType: string
    boxable: object
}

export interface boxInterfaceWithLocale extends boxInterface {
    weight: number
    length: number
    width: number
    height: number
}

export interface packageInterface {
    id: number
    hash: string
    createdAt: string
    updatedAt: string
    orderId: number
    order: orderInterface
    shippedAt: string
    arrivedAt: string
    lpn: string
    packageItems: packageItemInterface[]
    companyServices: companyServiceWithPivotInterface[]
    attachments: attachmentInterface[]
    box: boxInterfaceWithLocale
}

const createPackageFromConfiguration = async (
    orderId: number,
    configurationId: number,
    packageItems: { orderItemId: number, quantity: number }[],
): Promise<packageInterface> => {
    const response = await axios.post<{ package: packageInterface }>('/api/packages', {
        orderId,
        configurationId,
        packageItems,
    });

    return response.data.package;
};

const updatePackage = async (orderPackage: packageInterface): Promise<packageInterface> => {
    const response = await axios.patch<{ package: packageInterface }>(`/api/packages/${orderPackage.id}`, orderPackage);

    return response.data.package;
};

const deletePackage = async (packageId: number): Promise<void> => {
    return await axios.delete(`/api/packages/${packageId}`);
};

export default {
    createPackageFromConfiguration,
    updatePackage,
    deletePackage,
};
