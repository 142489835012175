import { companyServiceInterface } from '../repositories/companyServices';
import { moveInterface } from '../repositories/moves';
import { tagInterface } from '../repositories/tags';
import { baseShipmentItemInterface, shipmentItemGroupConfigurationInterface } from './inbound';
import { locationInterface } from './../warehouses/locations';
import { AmazonStatus, TransportStatus } from './fbaTransportV0';
import { PaginatedRequestFunction, PaginatedResults, moneyInterface, requestFilterInterface, sharedShipmentInterface } from '@/compiler/types';
import axios from '@/helpers/forms/axios';

export interface outboundShipmentItemLocationMoves extends moveInterface {
    sourceable: locationInterface
    arriveable: locationInterface
    partialQuantity: number
}

export interface outboundItemLocationMoveInterface extends moveInterface {
    sourceable: locationInterface
}

export interface outboundShipmentItemInterface extends baseShipmentItemInterface {
    quantity: number
    costPerItem: number
    expiryDate: string
    itemGroupConfigurations: shipmentItemGroupConfigurationInterface[]
    companyServices: companyServiceInterface[]
    locationMoves: outboundItemLocationMoveInterface[]
}

export interface outboundShipmentInterface extends sharedShipmentInterface {
    status: 'open' | 'shipped' | 'closed'
    shippedAt: string
    customerId: number
    shippedItemsCount: number
    isCasePacked: boolean
    outboundItems?: outboundShipmentItemInterface[]
    isCaseForwarding: boolean
    tags: tagInterface[]
    shipFromAddressId: number | null
    isLocked: boolean
    fbaTransportPlans?: {
        fbaShipmentId: string
        fbaShipmentName: string
        transportStatus: TransportStatus | null
        amazonStatus: AmazonStatus | null
        fbaTransportV0PlanId: string
        totalQuantity: number
        distinctSkuCount: number
    }[]
}

export enum TransportDestination {
    FBA = 'fba',
    SELF = 'self',
}

export const assessCharges = async (shipmentId: number): Promise<moneyInterface> => {
    const response = await axios.get<{ expectedChargeTotal: moneyInterface }>(`/api/shipments/outbound/${shipmentId}/assessCharges`);

    return response.data.expectedChargeTotal;
};

const setShipFromAddress = async (shipmentId: number, addressId: number): Promise<void> => {
    return await axios.post(`/api/shipments/outbound/${shipmentId}/set-ship-from-address`, {
        shipFromAddressId: addressId,
    });
};

const loadArchivedShipments: PaginatedRequestFunction<outboundShipmentInterface> = async (page: number) => {
    const response = await axios.get<PaginatedResults<outboundShipmentInterface>>(`/api/shipments/outbound/archived?page=${page}`);

    return response.data;
};

const batchArchive = async (shipmentIds: number[]): Promise<void> => {
    return await axios.post('/api/shipments/outbound/batch/archive', {
        shipmentIds,
    });
};

const batchUnarchive = async (shipmentIds: number[]): Promise<void> => {
    return await axios.post('/api/shipments/outbound/batch/unarchive', {
        shipmentIds,
    });
};

const markAsShipped = async (shipmentId: number): Promise<void> => {
    return await axios.get(`/api/shipments/outbound/${shipmentId}/markShipped`);
};

const markAsDeleted = async (shipmentId: number): Promise<void> => {
    return await axios.get(`/api/shipments/outbound/${shipmentId}/markDeleted`);
};

const switchCaseForwarding = async (shipmentId: number): Promise<void> => {
    return await axios.get(`/api/shipments/outbound/${shipmentId}/switchCaseForwarding`);
};

const toggleLock = async (shipmentId: number): Promise<void> => {
    return await axios.get(`/api/shipments/outbound/${shipmentId}/toggleLock`);
};

const close = async (shipmentId: number): Promise<void> => {
    return await axios.post(`/api/shipments/outbound/${shipmentId}/close`);
};

const isOutboundShipmentInterface = (shipment: sharedShipmentInterface): shipment is outboundShipmentInterface => {
    return (<outboundShipmentInterface>shipment).isCasePacked !== undefined;
};

const fetchOutboundShipments: PaginatedRequestFunction<outboundShipmentInterface> = async (page: number, filters?: requestFilterInterface[]) => {
    const url = new URL('/api/shipments/outbound', location.origin);
    url.searchParams.append('page', page.toString());
    if (filters) {
        filters.forEach((filter) => {
            url.searchParams.append(`filters[${filter.name}]`, filter.value);
        });
    }

    const response = await axios.get<PaginatedResults<outboundShipmentInterface>>(url.toString());

    return response.data;
};

const fetchOutboundShipment = async (shipmentId: number): Promise<outboundShipmentInterface> => {
    const response = await axios.get<{ shipment: outboundShipmentInterface }>(`/api/shipments/outbound/${shipmentId}`);

    return response.data.shipment;
};

const createOutboundShipment = async (name: string, warehouseId: number, notes?: string): Promise<{ shipmentId: number }> => {
    const response = await axios.post<{ shipmentId: number }>('/api/shipments/outbound', {
        name,
        warehouseId,
        notes,
    });

    return response.data;
};

const removeTagFromOutboundShipment = async (shipmentId: number, tagId: number): Promise<void> => {
    return await axios.delete(`/api/shipments/outbound/${shipmentId}/tags/delete-tag/${tagId}`);
};

const addTagToOutboundShipment = async (shipmentId: number, tagId: number): Promise<void> => {
    return await axios.post(`/api/shipments/outbound/${shipmentId}/tags/add-tag/${tagId}`);
};

// Items

const fetchOutboundShipmentItems = async (shipmentId: number): Promise<outboundShipmentItemInterface[]> => {
    const response = await axios.get<{ items: outboundShipmentItemInterface[] }>(`/api/shipments/outbound/${shipmentId}/outbound-shipment-item`);

    return response.data.items;
};

const createOutboundShipmentItem = async (shipmentId: number, itemId: number, quantity: number, expiryDate?: string): Promise<void> => {
    return await axios.post(`/api/shipments/outbound/${shipmentId}/outbound-shipment-item`, { itemId, quantity, expiryDate });
};

const updateOutboundShipmentItem = async (item: outboundShipmentItemInterface): Promise<void> => {
    return await axios.patch(`/api/shipments/outbound/${item.shipmentId}/outbound-shipment-item/${item.id}`, item);
};

const deleteOutboundShipmentItem = async (item: outboundShipmentItemInterface, force: boolean): Promise<void> => {
    const url = new URL(`/api/shipments/outbound/${item.shipmentId}/outbound-shipment-item/${item.id}`, location.origin);
    if (force) {
        url.searchParams.append('force', 'true');
    }

    return await axios.delete(url.toString());
};

export default {
    assessCharges,
    setShipFromAddress,
    loadArchivedShipments,
    batchArchive,
    batchUnarchive,
    markAsShipped,
    markAsDeleted,
    switchCaseForwarding,
    toggleLock,
    close,

    isOutboundShipmentInterface,
    fetchOutboundShipments,
    fetchOutboundShipment,
    createOutboundShipment,
    fetchOutboundShipmentItems,
    createOutboundShipmentItem,
    updateOutboundShipmentItem,
    deleteOutboundShipmentItem,
    removeTagFromOutboundShipment,
    addTagToOutboundShipment,
};
