import axios from '@/helpers/forms/axios';

export const convertToBundle = async (itemId: number): Promise<void> => {
    return await axios.get(`/api/inventory/${itemId}/convert-to-bundle`);
};

export const deleteFromBundle = async (bundleItemId: number, deletingItemId: number): Promise<void> => {
    return await axios.post(`/api/inventory/${bundleItemId}/bundles/delete-item`, {
        itemId: deletingItemId,
    });
};

export const addItemsToBundle = async (bundleItemId: number, itemIds: number[]): Promise<void> => {
    return await axios.post(`/api/inventory/${bundleItemId}/bundles/add-items`, { itemIds });
};
