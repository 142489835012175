<template>
    <button
        type="button"
        class="inline-flex items-center border font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
        :disabled="disabled"
        :class="getClasses"
        :dusk="text ? text.replace(/\s+/g, '') : null"
        v-bind="$attrs"
        @click="click"
    >
        <!-- Icon -->
        <component
            :is="icon as any"
            v-if="!isBusy && icon"
            :class="getSvgClasses"
        />

        <base-spinner
            v-show="isBusy"
            class="text-gray-300"
            :class="isIconOnly ? 'w-5 h-5 px-0.5' : 'w-4 h-4 -mx-2 mr-3'"
        />

        <span
            v-if="!isBusy"
            :class="{
                'max-w-0 group-hover:max-w-[6rem] overflow-hidden whitespace-nowrap transition-all duration-150': hoverText,
                'whitespace-nowrap': whitespaceNoWrap,
            }"
        ><slot>{{ text }}</slot></span>

        <span v-if="isBusy && !isIconOnly">Working</span>
    </button>

    <teleport to="body">
        <ConfirmActionModal
            v-if="confirmAction"
            :open="confirmingAction"
            :busy="busy"
            :title="confirmTitle"
            :description="confirmDescription"
            @close="confirmingAction = false"
            @confirmed="handleConfirmAction"
        />
    </teleport>
</template>

<script lang="ts" setup>
import { PropType, computed, isRef, ref } from 'vue';
import ConfirmActionModal from './ConfirmActionModal.vue';
import { BaseColour } from '@/compiler/types';
import useInertia from '@/composables/useInertia';

const props = defineProps({
    class: {
        type: String,
        default: null,
    },
    colour: {
        type: String as PropType<BaseColour>,
        default: BaseColour.Primary,
    },
    text: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: 'sm',
    },
    design: {
        type: String as PropType<'solid' | 'outline' | 'secondary'>,
        default: 'solid',
    },
    href: {
        type: String,
        default: null,
    },
    clickFunc: {
        type: Function,
        default: null,
    },
    icon: {
        type: Function,
        default: null,
    },
    busy: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    hoverText: {
        type: Boolean,
        default: false,
    },
    whitespaceNoWrap: {
        type: Boolean,
        default: false,
    },
    disableBusy: {
        type: Boolean,
        default: false,
    },
    confirmAction: {
        type: Boolean,
        default: false,
    },
    confirmTitle: {
        type: String,
        default: 'Click Confirm To Proceed',
    },
    confirmDescription: {
        type: String,
        default: 'This action requires a confirmation, click the Confirm button to continue.',
    },
});

const hrefClicked = ref(false);
const isBusy = computed(() => {
    return ((isRef(props.busy) ? props.busy.value : props.busy) || hrefClicked.value) && !props.disableBusy;
});
const isIconOnly = computed(() => {
    return !props.text || props.hoverText;
});

const getClasses = computed(() => {
    let classes = props.class ? props.class : '';
    classes += ` focus:ring-${props.colour}-500`;

    const sizeClasses: { [key: string]: string } = {
        'lg': 'py-3 text-base px-6',
        'md': 'py-2 text-base px-4',
        'sm': 'py-2 text-sm px-4',
        'xs': 'py-2 text-sm px-3',
        '2xs': 'py-2 text-xs px-2',
    };

    classes += ` ${sizeClasses[props.size]}`;

    if (isIconOnly.value) {
        if (props.size === '2xs') {
            classes = classes.replace('px-2', 'px-1');
        } else {
            classes = classes.replace('px-3', 'px-2').replace('px-4', 'px-2').replace('px-6', 'px-2');
        }
    }

    const designClasses: { [key: string]: string } = {
        outline: `bg-white text-${props.colour}-700 border-${props.colour}-300 hover:bg-${props.colour}-50`,
        secondary: `border-transparent text-${props.colour}-700 bg-${props.colour}-100 hover:bg-${props.colour}-200`,
        solid: `border-transparent text-white bg-${props.colour}-600 hover:bg-${props.colour}-700`,
    };

    classes += ` ${designClasses[props.design]}`;

    if (isBusy.value || props.disabled) {
        classes += ' opacity-50 cursor-not-allowed';
    }

    if (props.hoverText) {
        classes += ' group';
    }

    return classes;
});

const getSvgClasses = computed(() => {
    let classes = '';
    if (isIconOnly.value) {
        // Icon only button
        switch (props.size) {
            case 'xs':
                classes += 'h-4 w-4';
                break;
            case '2xs':
                classes += 'h-3 w-3';
                break;
            default:
                classes += 'h-5 w-5';
                break;
        }

        if (props.hoverText) {
            classes += ' transition-all group-hover:mr-1';
        }

        return classes;
    }

    const sizeClasses: { [key: string]: string } = {
        'lg': '-ml-1 mr-3 h-5 w-5',
        'md': '-ml-1 mr-3 h-5 w-5',
        'sm': '-ml-1 mr-2 h-5 w-5',
        'xs': '-ml-1 mr-2 h-4 w-4',
        '2xs': '-ml-1 mr-2 h-4 w-4',
    };

    classes += sizeClasses[props.size];

    return classes;
});

const confirmingAction = ref(false);

const handleConfirmAction = () => {
    confirmingAction.value = false;
    props.clickFunc();
};

const click = () => {
    if (isBusy.value) {
        return;
    }
    if (props.clickFunc) {
        if (props.confirmAction) {
            confirmingAction.value = true;
        } else {
            props.clickFunc();
        }
    } else {
        hrefClicked.value = true;
        setTimeout(() => {
            hrefClicked.value = false;
        }, 2000);

        if (props.href) {
            useInertia().visit(props.href);
        }
    }
};

/* Specify classes to prevent purgeCSS from stripping them - All Buttons
    bg-indigo-300 bg-teal-300 bg-red-300 bg-yellow-300 bg-green-300 bg-primary-300 bg-gray-300
    hover:bg-indigo-200 hover:bg-teal-200 hover:bg-red-200 hover:bg-yellow-200 hover:bg-green-200 hover:bg-indigo-200 hover:bg-gray-200
    active:text-indigo-800 active:text-teal-800 active:text-red-800 active:text-yellow-800 active:text-green-800 active:text-primary-800 active:text-gray-800
    active:bg-indigo-50 active:bg-teal-50 active:bg-red-50 active:bg-yellow-50 active:bg-green-50 active:bg-primary-50 active:bg-gray-50

    text-indigo-700 text-teal-700 text-red-700 text-yellow-700 text-green-700 text-primary-700 text-gray-700
    bg-indigo-100 bg-teal-100 bg-red-100 bg-yellow-100 bg-yellow-100 bg-green-100 bg-primary-100 bg-gray-100
    hover:bg-indigo-50 hover:bg-teal-50 hover:bg-red-50 hover:bg-yellow-50 hover:bg-green-50 hover:bg-primary-50 hover:bg-gray-50
    border-indigo-300 border-teal-300 border-red-300 border-yellow-300 border-green-300 border-primary-300 border-gray-300
    active:bg-indigo-200 active:bg-teal-200 active:bg-red-200 active:bg-yellow-200 active:bg-yellow-200 active:bg-green-200 active:bg-primary-200 active:bg-gray-200

    bg-indigo-600 bg-teal-600 bg-red-600 bg-yellow-600 bg-green-600 bg-primary-600 bg-gray-600
    active:bg-indigo-700 active:bg-teal-700 active:bg-red-700 active:bg-yellow-700 active:bg-green-700 active:bg-primary-700 active:bg-gray-700
    hover:bg-indigo-700 hover:bg-teal-700 hover:bg-red-700 hover:bg-yellow-700 hover:bg-green-700 hover:bg-primary-700 hover:bg-gray-700
    text-indigo-800 text-teal-800 text-red-800 text-yellow-800 text-green-800 text-primary-800 text-gray-800
    focus:ring-indigo-500 focus:ring-teal-500 focus:ring-red-500 focus:ring-yellow-500 focus:ring-green-500 focus:ring-primary-500 focus:ring-gray-500
*/
</script>
