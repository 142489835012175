<template>
    <base-slideover :open="open" :close-func="close" title="Switch Accounts" size="xl">
        <ServiceProviderSelectList v-if="userHasRoleOnCurrentServiceProvider" />

        <div v-if="!userHasRoleOnCurrentServiceProvider">
            <h2
                class="mt-6 text-center text-xl leading-9 font-extrabold text-gray-900"
            >
                Select a merchant account
            </h2>
            <MerchantSelectList />
        </div>
    </base-slideover>
</template>

<script lang="ts" setup>
import ServiceProviderSelectList from '@/components/users/ServiceProviderSelectList.vue';
import MerchantSelectList from '@/components/users/MerchantSelectList.vue';
import { useNarrative } from '@/composables/useNarrative';

const props = defineProps({
    open: Boolean,
});
const emit = defineEmits<{
    (e: 'close'): void
}>();
const { userHasRoleOnCurrentServiceProvider } = useNarrative();
const close = () => {
    emit('close');
};
</script>
