import axios from '@/helpers/forms/axios';

export interface fulfillmentInterface {
    id: string
    createdAt: string
    packageIdentifier: string
    providerConfirmedAt: string | null
}

export interface fulfillmentRequestInterface {
    id: string
    createdAt: string
    orderId: number
    providerFulfillmentOrderId: string
    receivedAt: string
    isAccepted: boolean
    acceptedAt: string | null
    isRejected: boolean
    rejectedAt: string | null
    fulfillments: fulfillmentInterface[]
}

const requestFulfillmentForOrder = async (orderId: number): Promise<void> => {
    return await axios.post('/api/fulfillment/request-fulfillment', {
        orderId,
    });
};

const rejectFulfillmentRequest = async (fulfillmentRequestId: string): Promise<void> => {
    return await axios.post('/api/fulfillment/reject', {
        fulfillmentRequestId,
    });
};

export default {
    requestFulfillmentForOrder,
    rejectFulfillmentRequest,
};
