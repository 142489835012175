<template>
    <BaseSlideover
        :id="id"
        :open="Boolean(items.length)"
        :close-func="close"
        title="Print FNSKU Labels"
        size="xl"
        zindex="30"
    >
        <div class="mt-2 px-2">
            <base-alert
                v-if="items.length === 0"
                class="my-2"
                :colour="BaseColour.Yellow"
            >
                No Items Found
            </base-alert>

            <base-table v-if="items.length" class="mt-1">
                <template #headers>
                    <base-table-header>SKU</base-table-header>
                    <base-table-header>Title</base-table-header>
                    <base-table-header>Quantity</base-table-header>
                    <base-table-header v-if="showExpiryDate">
                        Expiry Date
                    </base-table-header>
                    <base-table-header />
                </template>
                <tr
                    v-for="item in items"
                    :id="`printItemRow-${item.id}`"
                    :key="item.id"
                    class="bg-white"
                >
                    <base-table-cell padding="narrow">
                        {{ item.merchantSku }}
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        {{ item.title }}
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        <div class="mt-1 rounded-md shadow-sm relative">
                            <input
                                :id="`printItemQuantityInput-${item.id}`"
                                v-model="item.quantityToPrint"
                                class="border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 block min-w-0 w-full sm:text-sm sm:leading-5"
                                type="number"
                            >
                        </div>
                    </base-table-cell>
                    <base-table-cell v-if="showExpiryDate" padding="narrow">
                        <div class="mt-1 rounded-md shadow-sm relative">
                            <input
                                v-model="itemExpiryDate"
                                class="rounded-md border border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 block min-w-0 w-full sm:text-sm sm:leading-5"
                                type="date"
                                name="expiry-date"
                                :min="today"
                            >
                        </div>
                    </base-table-cell>
                    <base-table-cell padding="narrow">
                        <base-button
                            size="xs"
                            :icon="TrashIcon"
                            :colour="BaseColour.Red"
                            :click-func="() => deleteItem(item)"
                        />
                    </base-table-cell>
                </tr>
            </base-table>
        </div>

        <template #footer>
            <base-button :icon="PrinterIcon" text="Print" :click-func="print" :busy="printing" />
        </template>
    </BaseSlideover>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { PrinterIcon, TrashIcon } from '@heroicons/vue/20/solid';
import dayjs from 'dayjs';
import API from '@/api';
import { itemLabelInterface } from '@/api/inventory/labels';
import { itemInterface } from '@/api/repositories/items';
import { BaseColour } from '@/compiler/types';
import useValidation from '@/composables/useValidation';
import { useLabelPrintsStore } from '@/stores/overlays/labelPrints';
import { useNarrative } from '@/composables/useNarrative';
import BaseSlideover from '@/components/elements/BaseSlideover.vue';

interface printItemInterface extends itemInterface {
    quantityToPrint: number
    expiryDate: ''
}

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
});

const items = ref<(printItemInterface[])>([]);

const labelPrintsStore = useLabelPrintsStore();
const { currentServiceProvider } = useNarrative();

labelPrintsStore.$onAction(({ after }) => {
    after(() => {
        if (labelPrintsStore.useSlideoverId !== props.id) {
            return;
        }

        items.value = labelPrintsStore.getPrintLabelItems.map(item => ({ ...item, quantityToPrint: 0, expiryDate: '' }));
    });
});

const { validateExpiryDate } = useValidation();
const printing = ref(false);

const itemExpiryDate = computed({
    get: () => labelPrintsStore.getItemExpiryDate,
    set: (value) => {
        if (!validateExpiryDate(value)) {
            return;
        }

        labelPrintsStore.itemExpiryDate = value;
    },
});

const showExpiryDate = computed((): boolean => {
    return Boolean(currentServiceProvider.value.viewSettings?.shipments?.addItems?.showExpiryDate?.enabled);
});

const close = () => {
    labelPrintsStore.clearLabels();
};

const print = () => {
    if (itemExpiryDate.value && !validateExpiryDate(itemExpiryDate.value)) {
        return;
    }

    printing.value = true;

    const data = getLabelData();

    API.requestFnskuLabels(data).finally(() => {
        printing.value = false;
    });
};

const getLabelData = (): Array<itemLabelInterface> => {
    const data = [];
    if (showExpiryDate.value) {
        for (let i = 0; i < items.value.length; i++) {
            if (itemExpiryDate.value) {
                data.push({
                    id: items.value[i].id,
                    count: items.value[i].quantityToPrint,
                    expiryDate: itemExpiryDate.value,
                });
            } else {
                data.push({
                    id: items.value[i].id,
                    count: items.value[i].quantityToPrint,
                });
            }
        }

        return data;
    } else {
        for (let i = 0; i < items.value.length; i++) {
            data.push({
                id: items.value[i].id,
                count: items.value[i].quantityToPrint,
            });
        }
        return data;
    }
};

const deleteItem = (item: itemInterface) => {
    const itemIndex = items.value.findIndex(findItem => findItem.id !== item.id);

    items.value.splice(itemIndex, 1);
};

const today = dayjs().add(1, 'day').format('YYYY-MM-DD');
</script>
