import { AppEntitlementEnum } from '@/compiler/types';
import { sendForm } from '@/helpers/forms/form';
import axios from '@/helpers/forms/axios';

export interface addEntitlementFormInterface {
    serviceProviderId: number
    entitlement: AppEntitlementEnum
}

const addEntitlement = async (form: addEntitlementFormInterface): Promise<any> => {
    return await sendForm({
        method: 'post',
        uri: '/api/admin/serviceProviderEntitlements/addEntitlement',
        form,
    });
};

export interface serviceProvidersResponseInterface {
    id: number
    name: string
    entitlements: AppEntitlementEnum[]
}

const fetchServiceProviders = async (): Promise<serviceProvidersResponseInterface[]> => {
    const response = await axios.get<{ serviceProviders: serviceProvidersResponseInterface[] }>('/api/admin/serviceProviderEntitlements/serviceProviders');

    return response.data.serviceProviders;
};

export default {
    fetchServiceProviders,
    addEntitlement,
};
