import axios from '@/helpers/forms/axios';
import { sendForm } from '@/helpers/forms/form';

export const fetchMerchantTrainingPdf = async (): Promise<{ pdfUrl: string }> => {
    const response = await axios.get<{ pdfUrl: string }>('/api/serviceProviderConfiguration/merchantTraining/pdf');

    return response.data;
};

export interface setMerchantTrainingPdfFormInterface {
    pdfUrl: string
}

export const setMerchantTrainingPdf = async (form: setMerchantTrainingPdfFormInterface): Promise<void> => {
    return sendForm({
        method: 'POST',
        uri: '/api/serviceProviderConfiguration/merchantTraining/pdf',
        form,
    });
};

export default {
    fetchMerchantTrainingPdf,
    setMerchantTrainingPdf,
};
