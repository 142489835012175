import { computed, reactive, ref } from 'vue';
import useAzlabelsTray, { scaleData } from './useAzlabelsTray';
import usePopupNotifications from './usePopupNotifications';
import { fetchStation, fetchStations, printerInfoInterface, stationInterface } from '@/api/repositories/stations';
import { SparkForm } from '@/helpers/forms/form';
import API from '@/api';

import { useStationsStore } from '@/stores/stations';
import { DetailedPrintJob } from '@/api/printing';

export interface scaleRequestMetadata {
    entityId: number
    entityType: 'package' | 'order'
}

export interface readScaleEvent {
    failed: boolean
    data?: object
    metadata: scaleRequestMetadata
}

export interface printData {
    url: string
    labelType: 'barcode' | 'shipping'
    paths?: {
        pdf: string
        zpl?: string
        epl?: string
    }
    printerInfo?: printerInfoInterface
}

export default function useStations() {
    const { notifyError } = usePopupNotifications();
    const stationsStore = useStationsStore();
    const currentStation = computed(() => stationsStore.currentStation);
    const gettingScaleData = ref(false);

    const currentStationStatuses = computed(() => {
        return {
            shippingLabelPrinter: stationsStore.shippingLabelPrinterStatus,
            barcodeLabelPrinter: stationsStore.barcodeLabelPrinterStatus,
            scale: stationsStore.scaleStatus,
        };
    });

    const station = ref<stationInterface>();
    const allStations = ref([] as Array<stationInterface>);

    const getAllStations = async () => {
        allStations.value = await fetchStations();
    };

    const getStation = async (stationId: number) => {
        station.value = await fetchStation(stationId);
    };

    async function readScale(): Promise<scaleData | false> {
        const scale = currentStation.value?.scale;

        if (!scale) {
            notifyError('No scale found, please check your station settings.');

            return false;
        }

        return await useAzlabelsTray().readScaleFromTray(scale);
    }

    function handlePrintJob(job: DetailedPrintJob): Promise<void> {
        return new Promise((resolve, reject) => {
            useAzlabelsTray().printToTray(job)
                .then(() => {
                    API.printing.completePrint(job.id).then(() => {
                        resolve();
                    });
                })
                .catch((error) => {
                    API.printing.printFailed(job.id, error.message).then(() => {
                        reject(error);
                    });
                });
        });
    }

    function downloadLabelsIfNoAvailablePrinter(data: printData): void {
        const pdfUrl = data.paths?.pdf || data.url;

        if (!currentStation.value) {
            downloadPdf(pdfUrl);

            return;
        }

        if (data.labelType === 'barcode' && currentStation.value.barcodeLabelPrinterInfo) {
            return;
        }
        if (data.labelType === 'shipping' && currentStation.value.shippingLabelPrinterInfo) {
            return;
        }

        downloadPdf(pdfUrl);
    }

    function downloadPdf(pdfPath: string) {
        if (pdfPath.includes('http')) {
            window.open(pdfPath, '_blank');

            return;
        }

        const url = `https://api.azlabels.com${pdfPath}`;
        window.open(url, '_blank');
    }

    const stationForm = reactive(new SparkForm({
        userId: null as number | null,
        name: '',
        hostAddress: '',
        scanner: '',
        shippingLabelPrinter: '',
        shippingLabelPrinterConfiguration: {
            size: '7.62x2.54',
            flip: false,
            orientation: 'auto',
            density: 120,
        },
        barcodeLabelPrinter: '',
        barcodeLabelPrinterConfiguration: {
            size: '7.62x2.54',
            flip: false,
            orientation: 'auto',
            density: 120,
        },
        paperPrinter: '',
        scale: '',
    }));

    type DpiSetting = { keywords: string[], value: number } | { default: number };
    type BrandSettings = DpiSetting[];
    type Brand = 'dymo' | 'zebra' | 'rollo' | 'jiose' | 'unknown';

    const autoDetectBrand = (identifier: string): Brand => {
        identifier = identifier.toLowerCase();
        if (identifier.includes('dymo')) {
            return 'dymo';
        }
        if (identifier.includes('zebra')) {
            return 'zebra';
        }
        if (identifier.includes('rollo')) {
            return 'rollo';
        }
        if (identifier.includes('jiose')) {
            return 'jiose';
        }

        return 'unknown';
    };

    const dpiSettings: Record<Brand, BrandSettings> = {
        zebra: [
            { keywords: ['203dpi', '200dpi'], value: 80 },
            { keywords: ['gx430t', '170xi4', '300 dpi', '300dpi', 'zd420', 'zd620'], value: 120 },
            { default: 80 },
        ],
        rollo: [
            { keywords: ['300 dpi', '300dpi'], value: 120 },
            { default: 80 },
        ],
        jiose: [
            { default: 80 },
        ],
        dymo: [
            { default: 120 },
        ],
        unknown: [
            { default: 120 },
        ],
    };

    const autoDetectDensity = (identifier: string): number => {
        identifier = identifier.toLowerCase();
        const settings = dpiSettings[autoDetectBrand(identifier)];

        const keywordSettings = settings.filter(setting => 'keywords' in setting) as { keywords: string[], value: number }[];
        for (const setting of keywordSettings) {
            for (const keyword of setting.keywords) {
                if (identifier.includes(keyword)) {
                    return setting.value;
                }
            }
        }

        const defaultSetting = settings.find(setting => 'default' in setting) as { default: number };

        return defaultSetting.default;
    };

    return {
        station,
        currentStation,
        currentStationStatuses,
        isCheckingDevices: computed(() => stationsStore.isCheckingDevices),
        lastDeviceCheck: computed(() => stationsStore.lastDeviceCheck),
        loadCurrentStation: stationsStore.loadCurrentStation,
        refreshDevices: stationsStore.refreshDevices,
        getStation,
        readScale,
        handlePrintJob,
        downloadLabelsIfNoAvailablePrinter,
        allStations,
        getAllStations,
        stationForm,
        gettingScaleData,
        autoDetectBrand,
        autoDetectDensity,
    };
}
