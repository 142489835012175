import { axiosWithoutCaseMiddleware } from '@/helpers/forms/axios';

export interface orderMapColumnInterface {
    key: string
    title: string
}

export interface orderMapRowInterface {
    [key: string]: string
}

interface mappingDataResponseInterface {
    rows: orderMapRowInterface[]
    columns: orderMapColumnInterface[]
}

const fetchUploadMappingData = async (): Promise<mappingDataResponseInterface> => {
    const response = await (await axiosWithoutCaseMiddleware.get<mappingDataResponseInterface>('/api/orders/sheetData'));

    return response.data;
};

export default {
    fetchUploadMappingData,
};
