import { router } from '@inertiajs/vue3';
import { useKeyModifier } from '@vueuse/core';
import { useNarrativeStore } from '@/stores/narrative';

const controlKeyState = useKeyModifier('Control');
const metaKeyState = useKeyModifier('Meta');

export default function useInertia() {
    const narrativeStore = useNarrativeStore();

    const getHeaders = () => {
        const headers: Partial<{
            'X-Selected-Client-Id': string
            'X-Service-Provider-Id': string
        }> = {};

        const merchantId = narrativeStore.currentMerchant?.id;
        if (merchantId) {
            headers['X-Selected-Client-Id'] = merchantId.toString();
        }

        const currentServiceProvider = narrativeStore.currentServiceProvider;
        if (currentServiceProvider) {
            headers['X-Service-Provider-Id'] = currentServiceProvider.id.toString();
        }

        return headers;
    };

    const visit = (href: string) => {
        if (controlKeyState.value || metaKeyState.value) {
            window.open(href, '_blank');
            return;
        }

        router.visit(href, {
            headers: getHeaders(),
        });
    };

    return {
        visit,
        getHeaders,
    };
};
