<template>
    <div>
        <label
            v-if="label"
            :for="id"
            class="block text-sm leading-5 font-medium text-gray-700"
        >{{ label }}</label>
        <div class="mt-2 sm:col-span-2">
            <div class="flex items-center">
                <span class="rounded-md shadow-sm">
                    <input
                        :id="id"
                        ref="fileRef"
                        :name="formProp"
                        class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium hover:text-gray-500 focus:outline-none active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                        :class="isForm && form.errors.has(formProp) ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : 'focus:border-primary-300 focus:ring-primary text-gray-700'"
                        type="file"
                    >
                </span>
            </div>
        </div>

        <!-- Errors don't show if this field not present, weird Vue issue -->
        <span v-if="form && form.busy" class="hidden" />

        <!-- Error Text -->
        <p v-if="isForm && form.errors.has(formProp)" class="mt-1 text-sm text-red-600">
            <span>{{ form.errors.get(formProp) }}</span>
        </p>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { SparkFormType } from '@/helpers/forms/form';

const props = defineProps({
    id: {
        type: String,
        default: () => {
            return `Input-${Math.floor(Math.random() * 100000)}${1}`;
        },
    },
    label: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    form: {
        type: Object,
        validator: (prop: SparkFormType<any>) => Object.prototype.hasOwnProperty.call(prop, 'busy'),
        default: null,
    },
    formProp: {
        type: String,
        default: null,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const fileRef = ref();
const isForm = computed(() => {
    return Boolean(props.form && props.formProp);
});

defineExpose({
    fileRef,
});
</script>
