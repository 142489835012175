import { usePopupNotificationsStore } from '@/stores/overlays/popupNotifications';

export default function usePopupNotifications() {
    const popupNotificationsStore = usePopupNotificationsStore();

    const notifySuccess = (message: string): void => {
        popupNotificationsStore.newPopupNotification({
            type: 'success',
            title: message,
            permanent: false,
        });
    };

    const notifyError = (message: string): void => {
        popupNotificationsStore.newPopupNotification({
            type: 'error',
            title: message,
            permanent: false,
        });
    };
    const notifyAction = (message: string, actionText: string, action: () => void): void => {
        popupNotificationsStore.newPopupNotification({
            type: 'action',
            title: message,
            actionText,
            action,
            permanent: false,
        });
    };

    return {
        notifySuccess,
        notifyError,
        notifyAction,
    };
}
