import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import toArray from 'lodash/toArray';
import { ref } from 'vue';

interface errorInterface {
    [key: string]: Array<string> | errorInterface
}

/**
 * Spark form error collection class.
 */
export class SparkFormErrors {
    errors = ref<errorInterface>();

    /**
     * Determine if the collection has any errors.
     */
    hasErrors = () => {
        return !isEmpty(this.errors.value);
    };

    /**
     * Determine if the collection has errors for a given field.
     */
    has = (field: string): boolean => {
        return Boolean(
            Array.isArray( // If not an array, it is an object of other errors
                get(this.errors.value, field, false),
            ),
        );
    };

    /**
     * Get all of the raw errors for the collection.
     */
    all = (): errorInterface => {
        return this.errors.value || {};
    };

    /**
     * Get all of the errors for the collection in a flat array.
     */
    flatten = () => {
        return flatten(toArray((this.errors.value as any)));
    };

    /**
     * Get the first error message for a given field.
     */
    get = (field: string): string | void => {
        if (this.errors.value && this.has(field)) {
            return (get(this.errors.value, field) as string[])[0];
        }
    };

    /**
     * Get the array of error messages for a given field.
     */
    getAll = (field: string): string[] | void => {
        if (this.has(field)) {
            return get(this.errors, field);
        }
    };

    /**
     * Set the raw errors for the collection.
     */
    set = (errors: errorInterface) => {
        this.errors.value = errors;
    };

    /**
     * Remove errors from the collection.
     */
    forget = (field?: string) => {
        if (typeof field === 'undefined') {
            this.errors.value = {};
        } else if (this.errors.value) {
            delete this.errors.value[field];
        }
    };
};
