import { itemInterface } from '../repositories/items';
import axios from '@/helpers/forms/axios';

interface reconcileResponse {
    items: itemInterface[]
    status: string
}

export const fetchReconcileItems = async (clearCache: boolean, skus: Array<string> = []): Promise<reconcileResponse> => {
    let url = '/api/inventory/reconcile';

    if (clearCache) {
        url += '?clearCache=true';
    }

    if (skus.length) {
        const startChar = clearCache ? '&' : '?';

        url += `${startChar}skus[]=${skus.map(sku => encodeURIComponent(sku)).join('&skus[]=')}`;
    }

    const response = await axios.get<reconcileResponse>(url);

    return response.data;
};
