import { orderItemInterface } from './orderItems';
import { packageInterface } from './packages';
import axios from '@/helpers/forms/axios';

export interface packageItemInterface {
    id: number
    createdAt: string
    updatedAt: string
    orderItemId: number
    orderItem: orderItemInterface
    packageId: number
    package: packageInterface
    quantity: number
    toteBarcode: string
    packedAt: string
    packedByUserId: number
    lotCode: string
}

const deleteOrderPackageItem = async (packageItemId: number): Promise<void> => {
    await axios.delete<void>(`/api/package-items/${packageItemId}`);
};

export default {
    deleteOrderPackageItem,
};
