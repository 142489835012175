import { MerchantUserRole } from '../repositories/merchants';
import { ServiceProviderUserRole } from '../repositories/serviceProviders';
import { AppEntitlementEnum, AppPermissionEnum } from '@/compiler/types';
import axios from '@/helpers/forms/axios';

const fetchCurrentPermissions = async () => {
    const response = await axios.get<{ [key in AppPermissionEnum]: boolean }>('/api/narrative/permissions');

    return response.data;
};

const fetchCurrentEntitlements = async () => {
    const response = await axios.get<AppEntitlementEnum[]>('/api/narrative/entitlements');

    return response.data;
};

const fetchCurrentUserRoles = async () => {
    const response = await axios.get<{
        serviceProvider: ServiceProviderUserRole[]
        merchant: MerchantUserRole[]
        platform: Array<'developer'>
    }>('/api/narrative/roles');

    return response.data;
};

export default {
    fetchCurrentPermissions,
    fetchCurrentUserRoles,
    fetchCurrentFeatures: fetchCurrentEntitlements,
};
