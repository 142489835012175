import { computed } from 'vue';
import { AnalyticsKeys } from './useAnalytics';
import { MerchantUserRole, merchantInterface } from '@/api/repositories/merchants';
import { ServiceProviderUserRole, serviceProviderInterface } from '@/api/repositories/serviceProviders';
import { userInterface } from '@/api/repositories/users';
import { useCurrentUserStore } from '@/stores/currentUser';
import { useNarrativeStore } from '@/stores/narrative';

export const useNarrative = () => {
    const narrativeStore = useNarrativeStore();
    const currentUserStore = useCurrentUserStore();

    const setCurrentMerchantFromId = (merchantId: number) => {
        if (!narrativeStore.currentServiceProvider || !currentUserStore.userHasRoleOnCurrentServiceProvider) {
            return;
        }

        const merchant = narrativeStore.currentServiceProvider.merchants.find(merchant => merchant.id === merchantId);

        if (merchant) {
            narrativeStore.setCurrentMerchant(merchant);
        }
    };

    const showMerchantOnIndex = computed(() => {
        if (!currentUserStore.userHasRoleOnCurrentServiceProvider) {
            return false;
        }

        if (narrativeStore.currentMerchant) {
            return false;
        }

        return true;
    });

    const getMerchantFromId = (merchantId: number): merchantInterface | undefined => {
        if (!currentUserStore.userHasRoleOnCurrentServiceProvider) {
            return;
        }

        return narrativeStore.currentServiceProvider?.merchants.find(merchant => merchant.id === merchantId);
    };

    const logoUrl = computed(() => {
        if (!narrativeStore.currentServiceProvider?.logoFile) {
            return '';
        }

        if (narrativeStore.currentServiceProvider.logoFile.includes('http')) {
            return narrativeStore.currentServiceProvider.logoFile;
        }

        return `/storage/${narrativeStore.currentServiceProvider.logoFile}`;
    });

    const faviconUrl = computed(() => {
        if (!narrativeStore.currentServiceProvider?.faviconFile) {
            return '';
        }

        if (narrativeStore.currentServiceProvider.faviconFile.includes('http')) {
            return narrativeStore.currentServiceProvider.faviconFile;
        }

        return `/storage/${narrativeStore.currentServiceProvider.faviconFile}`;
    });

    return {
        env: computed((): string => narrativeStore.env),
        appName: computed((): string => narrativeStore.appName),
        appBaseDomain: computed((): string => narrativeStore.appBaseDomain),
        stripeKey: computed((): string => narrativeStore.stripeKey),
        currentServiceProvider: computed((): serviceProviderInterface => {
            const serviceProvider = narrativeStore.currentServiceProvider;
            if (!serviceProvider) throw new Error('Service provider is required');

            return serviceProvider;
        }),
        currentMerchant: computed((): merchantInterface => {
            const currentMerchant = narrativeStore.currentMerchant;
            if (!currentMerchant) throw new Error('Merchant is required');

            return currentMerchant;
        }),
        currentUser: computed((): userInterface => {
            const user = currentUserStore.currentUser;
            if (!user) throw new Error('User is required');

            return user;
        }),
        currentServiceProviderOptional: computed(() => narrativeStore.currentServiceProvider),
        currentMerchantOptional: computed(() => narrativeStore.currentMerchant),
        currentUserOptional: computed(() => currentUserStore.currentUser),
        userHasRoleOnCurrentServiceProvider: computed((): boolean => currentUserStore.userHasRoleOnCurrentServiceProvider()),
        userHasRoleOnCurrentMerchant: computed((): boolean => currentUserStore.userHasRoleOnCurrentMerchant()),
        userIsOwnerOnServiceProvider: computed((): boolean => currentUserStore.userHasRoleOnCurrentServiceProvider(ServiceProviderUserRole.Owner)),
        userIsOfficeEmployeeOnServiceProvider: computed((): boolean => currentUserStore.userHasRoleOnCurrentServiceProvider(ServiceProviderUserRole.OfficeEmployee)),
        userIsOwnerOnMerchant: computed((): boolean => currentUserStore.userHasRoleOnCurrentMerchant(MerchantUserRole.Owner)),
        impersonatingUserId: computed((): number | null => narrativeStore.impersonatingUserId),
        sentryPublicDsn: computed((): string | undefined => narrativeStore.sentryPublicDsn),
        releaseTag: computed((): string | undefined => narrativeStore.releaseTag),
        analyticsKeys: computed((): AnalyticsKeys => narrativeStore.analyticsKeys),
        showMerchantOnIndex,
        setCurrentMerchantFromId,
        getMerchantFromId,
        refreshServiceProvider: narrativeStore.refreshServiceProvider,
        refreshMerchant: narrativeStore.refreshMerchant,
        logoUrl,
        faviconUrl,
    };
};
