import { defineStore } from 'pinia';
import { Ref } from 'vue';
import dayjs from 'dayjs';
import { DataFetcherFunction } from '@/compiler/types';
import useRelativeTimeCheck from '@/composables/useRelativeTimeCheck';

export interface OperationProblem {
    code: string
    message: string
}

export enum OperationStatus {
    InProgress = 'in_progress',
    Succeeded = 'succeeded',
    Failed = 'failed',
}

export interface Operation {
    getId(): string
    getCreatedAt(): string
    getStatus(): OperationStatus
    getProgress(): number
    getDescription(): string
    getWarnings(): OperationProblem[]
    getErrors(): OperationProblem[]
    fetchUpdate(): Promise<any>
}

export interface ActionableOnSuccessOperation extends Operation {
    executeAction(): Promise<void>
    actionText(): string
    actionIcon(): Function
}

export interface OperationCheck {
    lastCheckedAt: string
    relativeLastCheck: Ref<string | null>
    checking: boolean
    stopChecking: () => void
    check: () => void
}

interface State {
    _operations: Operation[]
    operationSources: { [key: string]: DataFetcherFunction<Operation> }
    operationChecks: { [key: string]: OperationCheck }
}

export const useOperationsStore = defineStore('operations', {
    state: (): State => ({
        _operations: [],
        operationSources: {},
        operationChecks: {},
    }),
    getters: {
        operations(): Operation[] {
            return this._operations.sort((a, b) => b.getCreatedAt().localeCompare(a.getCreatedAt()));
        },
        activeOperationsCount(): number {
            return this._operations.filter(operation => operation.getStatus() === OperationStatus.InProgress).length;
        },
    },
    actions: {
        set(operation: Operation): void {
            const existingOperationIndex = this._operations.findIndex(existingOperation => existingOperation.getId() === operation.getId());

            if (existingOperationIndex >= 0) {
                this._operations[existingOperationIndex] = operation;
            } else {
                this._operations.unshift(operation);

                this.operationChecks[operation.getId()] = initOperationCheck(operation);
            }
        },
        addOperationSource(key: string, source: DataFetcherFunction<Operation>): void {
            this.operationSources[key] = source;
        },
        async loadOperationsFromSources(): Promise<void> {
            const promises: Promise<void>[] = [];

            Object.keys(this.operationSources).forEach((key) => {
                promises.push(this.operationSources[key](1, 10, [], '').then((response) => {
                    response.data.forEach(operation => this.set(operation));
                }));
            });

            await Promise.all(promises);
        },
    },
});

const initOperationCheck = (operation: Operation): OperationCheck => {
    const operationsStore = useOperationsStore();

    const callback = () => {
        const currentOperation = operationsStore.operations.find(op => op.getId() === operation.getId());
        if (!currentOperation || currentOperation.getStatus() !== OperationStatus.InProgress) {
            stopChecking();

            return;
        };

        useOperationsStore().operationChecks[operation.getId()].checking = true;

        operation.fetchUpdate().then(() => {
            const updatedOperation = operationsStore.operations.find(op => op.getId() === operation.getId());
            if (!updatedOperation || updatedOperation.getStatus() !== OperationStatus.InProgress) {
                stopChecking();
            };

            useOperationsStore().operationChecks[operation.getId()].lastCheckedAt = dayjs().toISOString();
        }).finally(() => {
            useOperationsStore().operationChecks[operation.getId()].checking = false;
        });
    };

    const { relativeLastCheck, stopChecking } = useRelativeTimeCheck(() => operationsStore.operationChecks[operation.getId()].lastCheckedAt, {
        seconds: 5,
        callback,
    });

    return {
        lastCheckedAt: dayjs().toISOString(),
        relativeLastCheck,
        checking: false,
        stopChecking,
        check: callback,
    };
};
