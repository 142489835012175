import axios from '@/helpers/forms/axios';

export enum EntitlementType {
    BaseAccess = 'base_access',
    AmazonShipments = 'amazon_shipments',
    Locations = 'locations',
    Orders = 'orders',
}

export interface entitlementInterface {
    serviceProviderId: number
    type: EntitlementType
    sourceSubscriptionId?: string
    deletedAt?: string
};

export interface planEntitlementInterface {
    planId: string
    type: EntitlementType
};

const fetchPlanEntitlementMap = async (planIds: string[]): Promise<planEntitlementInterface[]> => {
    const response = await axios.post<{ data: planEntitlementInterface[] }>('/api/service-provider-entitlements/plan-map', { planIds });

    return response.data.data;
};

export default {
    fetchPlanEntitlementMap,
};
