<template>
    <!-- Barcode scanner Modal -->
    <div
        v-if="barcodeScannerStore.isOpen && barcodeScannerStore.getScannerOptions"
        class="fixed z-10 inset-0 overflow-y-scroll w-full h-screen"
    >
        <div>
            <transition-group
                enter-active-class="ease-out duration-300"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div key="blur" class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <div
                    key="content"
                    class="absolute top-0 flex min-h-screen text-center w-full sm:block"
                >
                    <div
                        class="bg-transparent text-left overflow-hidden transition-all w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div
                            class="w-full bg-gray-800 h-16 flex flex-row justify-center items-center px-2"
                            style="touch-action: none;"
                        >
                            <div class="flex flex-col">
                                <h1 class="text-white text-xl font-semibold self-center">
                                    {{
                                        barcodeScannerStore.getScannerOptions.title
                                    }}
                                </h1>
                                <span class="text-white text-sm self-center">
                                    {{
                                        truncate(barcodeScannerStore.getScannerOptions.tip, 70)
                                    }}
                                </span>
                            </div>
                            <div v-if="barcodeScannerStore.getScannerOptions.canSkip" class="ml-4">
                                <base-button
                                    text="Skip"
                                    :colour="BaseColour.Gray"
                                    :click-func="skip"
                                />
                            </div>

                            <div class="ml-4">
                                <base-button
                                    text="Error"
                                    :colour="BaseColour.Red"
                                    :click-func="() => showManageOrderExceptionOverlay = true"
                                />
                            </div>

                            <teleport to="body">
                                <AddOrderExceptionOverlay
                                    :open="showManageOrderExceptionOverlay"
                                    :order-id="currentOrderItem?.orderId"
                                    :item-id="currentOrderItem?.itemId"
                                    @close="showManageOrderExceptionOverlay = false"
                                />
                            </teleport>

                            <div v-if="barcodeScannerStore.isLoading" class="ml-4">
                                <base-spinner class="h-5 w-5 text-gray-300" />
                            </div>
                        </div>

                        <div
                            style="touch-action: none;"
                            class="h-screen bg-gray-800 w-full flex flex-col items-center justify-between px-2 z-10"
                        >
                            <div
                                v-show="quaggaReady"
                                id="barcodeCamera"
                                class="absolute z-20"
                                style="touch-action: none;"
                            />
                            <div class="w-full lg:w-1/2 mt-4 z-30">
                                <base-alert
                                    v-if="incorrectScan"
                                    :colour="BaseColour.Yellow"
                                >
                                    {{ scanError }}: {{ barcode }}
                                </base-alert>
                                <base-alert
                                    v-if="correct"
                                    :colour="BaseColour.Green"
                                >
                                    Correct barcode scanned
                                </base-alert>
                            </div>

                            <div class="text-center z-30">
                                <div
                                    :class="{
                                        'backdrop-blur-sm bg-black/30 py-2 px-6 rounded-md': quaggaReady,
                                        'mt-40': quaggaReady && !incorrectScan,
                                        'mt-28': quaggaReady && incorrectScan,
                                    }"
                                >
                                    <p
                                        v-if="correctResult.startsWith !== 'TOTE'"
                                        class="text-xl font-semibold text-gray-200"
                                    >
                                        Scan
                                    </p>
                                    <div
                                        v-if="correctResult.startsWith !== 'TOTE'"
                                        class="text-4xl font-semibold text-white"
                                    >
                                        <ul>
                                            <li v-for="(match, index) in correctResult.exact" :key="index">
                                                {{ match }}
                                            </li>
                                        </ul>
                                    </div>
                                    <p
                                        v-if="correctResult.startsWith === 'TOTE'"
                                        class="text-4xl font-semibold text-white"
                                    >
                                        Scan any Tote
                                    </p>
                                </div>
                            </div>
                            <div class="invisible mb-56" />
                        </div>
                        <BarcodeScannerItemDetailsPanel
                            :options="barcodeScannerStore.getScannerOptions"
                        />
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import Quagga, { QuaggaJSResultObject } from '@ericblade/quagga2';
import BarcodeScannerItemDetailsPanel from '@/components/elements/scanner/BarcodeScannerItemDetailsPanel.vue';
import { ScannerType } from '@/composables/usePickOrders';
import AddOrderExceptionOverlay from '@/components/orders/overlays/AddOrderExceptionOverlay.vue';
import { BaseColour } from '@/compiler/types';
import { useBarcodeScannerStore } from '@/stores/barcodeScanner';
import useFormatters from '@/composables/useFormatters';

const barcodeScannerStore = useBarcodeScannerStore();
const { truncate } = useFormatters();

const quaggaReady = ref(false);
const correct = ref(false);
const barcode = ref('');
const scannedTotes = ref<string[]>([]);

interface correctResultInterface {
    exact?: string[]
    startsWith?: string
    excluding?: string[]
}

const correctResult = computed((): correctResultInterface => {
    if (!barcodeScannerStore.getScannerOptions) {
        return {};
    }

    switch (barcodeScannerStore.getScannerOptions.type) {
        case ScannerType.Location:
            return {
                exact: [barcodeScannerStore.getScannerOptions.pick?.locations?.[0].code || 'NA'],
            };
        case ScannerType.Item:
            return {
                exact: barcodeScannerStore.getScannerOptions.pick?.item.identifiers
                    .filter(identifier => ['EAN', 'UPC'].includes(identifier.identifierType))
                    .map(identifier => identifier.identifier),
            };
        case ScannerType.Tote:
            if (barcodeScannerStore.getScannerOptions.orderItem?.pickData && barcodeScannerStore.getScannerOptions.orderItem.pickData.toteCode) {
                return {
                    exact: [barcodeScannerStore.getScannerOptions.orderItem.pickData.toteCode],
                };
            } else {
                return {
                    startsWith: 'TOTE',
                    excluding: scannedTotes.value,
                };
            }
        default:
            return {};
    }
});

const barcodeIsCorrect = computed((): boolean => {
    if (!correctResult.value.exact && !correctResult.value.startsWith) {
        // If there is no correct result, the barcode is always correct
        return true;
    }

    if (correctResult.value.excluding?.includes(barcode.value)) {
        // Scanned barcode is excluded from matches
        return false;
    }

    if (correctResult.value.exact?.includes(barcode.value)) {
        // Scanned barcode is an exact match
        return true;
    }

    if (correctResult.value.startsWith && barcode.value.startsWith(correctResult.value.startsWith)) {
        // Scanned barcode matches startsWith condition
        return true;
    }

    return false;
});

const incorrectScan = computed((): boolean => {
    if (!barcode.value) {
        return false;
    }

    if (barcodeIsCorrect.value) {
        return false;
    }

    if (correct.value) {
        return false;
    }

    return true;
});

const scanError = computed((): string => {
    if (!correctResult.value) {
        return 'Incorrect Scan';
    }

    if (correctResult.value.excluding?.includes(barcode.value)) {
        return 'Tote already used';
    }

    return 'Incorrect Scan';
});

const startScan = () => {
    if (!barcodeScannerStore.getScannerOptions?.usingCamera) {
        return;
    }

    const target = document.querySelector('#barcodeCamera');

    if (!target) {
        return;
    }

    if (quaggaReady.value) {
        return;
    }

    Quagga.init({
        inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target,
            constraints: {
                aspectRatio: { ideal: 1 },
            },
        },
        locator: {
            halfSample: true,
            patchSize: 'medium', // x-small, small, medium, large, x-large
            debug: {
                showCanvas: false,
            },
        },
        decoder: {
            readers: ['code_128_reader', 'ean_reader', 'upc_reader'],
        },
    }, (err) => {
        if (err) {
            console.log(err); // eslint-disable-line no-console
            return;
        }

        quaggaReady.value = true;

        Quagga.onDetected((result) => {
            handleScan(result);
        });
        Quagga.start();
    });
};

const handleScan = (result: QuaggaJSResultObject) => {
    if (result) {
        let countDecodedCodes = 0;
        let err = 0;
        result.codeResult.decodedCodes.forEach((error) => {
            if (error.error !== undefined) {
                countDecodedCodes++;
                err += error.error;
            }
        });

        if ((err / countDecodedCodes) >= 0.1) {
            return;
        }
        if (!result.codeResult.code) {
            return;
        }

        const invalidChars = ['!', '(', '.'];
        for (let i = 0; i < invalidChars.length; i++) {
            if (result.codeResult.code.includes(invalidChars[i])) {
                return;
            }
        }

        if (err / countDecodedCodes < 0.1 && result.codeResult.code) {
            handleBarcode(result.codeResult.code);
        }
    }
};

const handleBarcode = (code: string) => {
    barcode.value = code.trim();

    barcodeScannerStore.setBarcodeScan(code);

    if (barcodeIsCorrect.value) {
        if (correct.value) {
            // This barcode has already been processed as correct, wait until the correct status is updated by a new screen
            return false;
        }

        if (barcodeScannerStore.getScannerOptions?.type === ScannerType.Tote) {
            scannedTotes.value.push(code);
        }

        barcodeScannerStore.setCorrectBarcodeScan(code);

        barcode.value = '';
        correct.value = true;

        setTimeout(() => { correct.value = false; }, 1000);
    }
};

const skip = () => {
    barcodeScannerStore.setScanSkipped(true);

    barcode.value = '';

    if (barcodeScannerStore.getScannerOptions?.type === 'pickTote') {
        close();
    }
};

const close = () => {
    Quagga.stop().then(() => {
        quaggaReady.value = false;
    });

    barcodeScannerStore.setOpen(false);
    barcodeScannerStore.setScannerOptions(null);
};

watch(() => barcodeScannerStore.getScannerOptions, () => {
    // Update correct status when new barcode pushed
    correct.value = false;
});

barcodeScannerStore.$onAction(({ name, after }) => {
    after(() => {
        if (name === 'setHardwareScan') {
            handleBarcode(barcodeScannerStore.hardwareScan);
        }
        if (name === 'setOpen' && barcodeScannerStore.isOpen === true) {
            setTimeout(() => {
                startScan();
            }, 150);
        }
        if (name === 'setClosed') {
            close();
        }
    });
});

const showManageOrderExceptionOverlay = ref(false);

const currentOrderItem = computed(() => {
    return barcodeScannerStore.getScannerOptions?.orderItem;
});
</script>
