import { Currency } from 'dinero.js';
import axios from '@/helpers/forms/axios';
import { sendForm } from '@/helpers/forms/form';
import {
    chargeInterface,
    chargeItemInterface,
    invoiceInterface,
    merchantReceivablesInterface,
    storeClientServiceChargeFormInterface,
    storeQuickAdjustmentFormInterface,
} from '@/compiler/billing';
import { PaginatedRequestFunction, PaginatedResults, moneyInterface, requestFilterInterface } from '@/compiler/types';
import useLocalization from '@/composables/useLocalization';

const indexCharges: PaginatedRequestFunction<chargeInterface> = async (page: number, filters?: requestFilterInterface[]) => {
    const url = new URL('/api/billing/charges', location.origin);

    url.searchParams.append('page', page.toString());
    if (filters) {
        filters.forEach((filter) => {
            url.searchParams.append(`filters[${filter.name}]`, filter.value);
        });
    }

    const response = await axios.get<PaginatedResults<chargeInterface>>(url.toString());

    return response.data;
};

const showCharge = async (chargeId: number): Promise<[chargeInterface, Array<chargeItemInterface & Record<'chargeableItem', any>>]> => {
    const response = await axios.get<{
        charge: chargeInterface
        chargeItems: Array<chargeItemInterface & Record<'chargeableItem', any>>
    }>(`/api/billing/charges/${chargeId}`);

    return [
        response.data.charge,
        response.data.chargeItems,
    ];
};

const deleteCharge = async (chargeId: number): Promise<boolean> => {
    await axios.delete(`/api/billing/charges/${chargeId}`);
    return true;
};

const getPendingChargesTotalPrice = async (): Promise<moneyInterface> => {
    const response = await axios.get<moneyInterface>('/api/billing/charges/pending-total-price');

    const { displayCurrency } = useLocalization();

    return {
        amount: response.data.amount ?? 0,
        currency: response.data.currency ?? displayCurrency.value,
    };
};

const getCurrentBillingCycleEnd = async (): Promise<string | null> => {
    const response = await axios.get<{ billingCycleEndDate: string | null }>('/api/billing/charges/current-billing-cycle-end-date');

    return response.data.billingCycleEndDate;
};

const storeQuickAdjustment = async (form: storeQuickAdjustmentFormInterface): Promise<boolean> => {
    await sendForm({
        method: 'post',
        uri: '/api/billing/charges/quick-adjustment',
        form,
    });

    return true;
};

const storeClientServiceCharge = async (form: storeClientServiceChargeFormInterface): Promise<boolean> => {
    await sendForm({
        method: 'post',
        uri: '/api/billing/charges/client-service-charge',
        form,
    });

    return true;
};

const indexInvoices = async (filters?: requestFilterInterface[]): Promise<invoiceInterface[]> => {
    const url = new URL('/api/billing/invoices', location.origin);

    if (filters) {
        filters.forEach((filter) => {
            url.searchParams.append(`filters[${filter.name}]`, filter.value);
        });
    }

    const response = await axios.get<{ invoices: invoiceInterface[] }>(url.toString());

    return response.data.invoices;
};

const createInvoice = async (chargeIds?: number[]): Promise<invoiceInterface> => {
    const response = await axios.post<{ invoice: invoiceInterface }>('/api/billing/invoices', { chargeIds });

    return response.data.invoice;
};

const finalizeInvoice = async (invoiceId: number): Promise<void> => {
    return axios.post(`/api/billing/invoices/${invoiceId}/finalize`);
};

const createOnStripe = async (invoiceId: number): Promise<void> => {
    return axios.post(`/api/billing/invoices/${invoiceId}/create-on-stripe`);
};

const geDownloadInvoiceRedirectUrl = (invoiceId: number): string => {
    return `/api/billing/invoices/${invoiceId}/download/pdf`;
};

const collectInvoice = async (invoiceId: number): Promise<void> => {
    return axios.post(`/api/billing/invoices/${invoiceId}/collect`);
};

const deleteInvoice = async (invoiceId: number): Promise<void> => {
    return axios.delete(`/api/billing/invoices/${invoiceId}`);
};

const markInvoiceAsPaid = async (invoiceId: number): Promise<void> => {
    return await axios.post(`/api/billing/invoices/${invoiceId}/mark-as-paid`);
};

const voidInvoice = async (invoiceId: number): Promise<void> => {
    return await axios.post(`/api/billing/invoices/${invoiceId}/void`);
};

const getReceivables = async (): Promise<merchantReceivablesInterface[]> => {
    interface result {
        merchantId: number
        openChargesCount: number
        draftInvoicesCount: number
        unpaidInvoicesCount: number
        openChargesAmount: string
        draftInvoicesAmount: string
        unpaidInvoicesAmount: string
        billingCurrency: Currency
    }

    return (await axios.get<result[]>('/api/billing/receivables')).data
        .map(datum => ({
            merchantId: datum.merchantId,
            openChargesCount: datum.openChargesCount,
            draftInvoicesCount: datum.draftInvoicesCount,
            unpaidInvoicesCount: datum.unpaidInvoicesCount,
            openChargesAmount: Number.parseFloat(datum.openChargesAmount),
            draftInvoicesAmount: Number.parseFloat(datum.draftInvoicesAmount),
            unpaidInvoicesAmount: Number.parseFloat(datum.unpaidInvoicesAmount),
            currency: datum.billingCurrency,
        }));
};

const getChargesForChargeable = async (entityType: string, entityId: string): Promise<chargeInterface[]> => {
    const url = new URL('/api/billing/charges/for-chargeable', location.origin);
    url.searchParams.append('chargeable_id', entityId);
    url.searchParams.append('chargeable_type', entityType);

    const response = await axios.get<{ data: chargeInterface[] }>(url.toString());

    return response.data.data;
};

export default {
    indexCharges,
    showCharge,
    deleteCharge,
    getPendingChargesTotalPrice,
    storeQuickAdjustment,
    storeClientServiceCharge,
    getCurrentBillingCycleEnd,
    getChargesForChargeable,

    // Invoices
    indexInvoices,
    createInvoice,
    finalizeInvoice,
    createOnStripe,
    geDownloadInvoiceRedirectUrl,
    collectInvoice,
    deleteInvoice,
    markInvoiceAsPaid,
    voidInvoice,

    // Receivables
    getReceivables,
};
