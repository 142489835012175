import { defineStore } from 'pinia';

export interface PopupNotification {
    type: 'error' | 'success' | 'action'
    title: string
    permanent: boolean
    actionText?: string
    action?: () => void
}

interface State {
    popupNotifications: PopupNotification[]
    blockNotifications: boolean
}

export const usePopupNotificationsStore = defineStore('popupNotifications', {
    state: (): State => ({
        popupNotifications: [],
        blockNotifications: false,
    }),
    getters: {
        getPopupNotifications: state => state.popupNotifications,
    },
    actions: {
        newPopupNotification(value: PopupNotification): void {
            if (this.blockNotifications) {
                return;
            }

            if (this.isPopupDuplicate(value)) {
                return;
            }

            this.popupNotifications.push(value);

            const removeAfter = value.type === 'success' ? 2300 : 6300;

            this.removeEarliestPopup(removeAfter);
        },
        async removeEarliestPopup(removeAfter: number): Promise<void> {
            await new Promise(resolve => setTimeout(() => resolve(''), removeAfter));

            const updatedNotifications = this.popupNotifications.filter(notification => notification.permanent);
            updatedNotifications.shift();

            this.popupNotifications = updatedNotifications;
        },
        // If the most recent notification is the same as the new one and it is still visible, don't add it
        isPopupDuplicate(value: PopupNotification): boolean {
            if (this.popupNotifications.length === 0) {
                return false;
            }

            const lastNotification = this.popupNotifications[this.popupNotifications.length - 1];

            if (lastNotification.title !== value.title) {
                return false;
            }

            return true;
        },
    },
});
