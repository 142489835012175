import axios from '@/helpers/forms/axios';

export interface imageInterface {
    id: number
    largeUrl: string
    thumbnailUrl: string
}

export const deleteImage = async (imageId: number): Promise<void> => {
    return axios.delete(`/api/images/${imageId}`);
};
