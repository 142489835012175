<template>
    <base-modal
        :icon="ExclamationTriangleIcon"
        :colour="BaseColour.Gray"
        :open="open"
        size="lg"
        :close-func="close"
    >
        <h4 class="text-xl mb-2" dusk="confirm-action-modal-title">
            {{ title }}
        </h4>

        <base-alert :colour="BaseColour.Gray" :show-icon="false">
            <span v-html="description" />
        </base-alert>

        <template #footer>
            <base-button
                class="ml-2"
                text="Confirm"
                :colour="BaseColour.Gray"
                :busy="busy"
                :click-func="confirm"
            />
        </template>
    </base-modal>
</template>

<script lang="ts" setup>
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid';
import { BaseColour } from '@/compiler/types';

defineProps({
    open: {
        type: Boolean,
        default: false,
    },
    busy: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
});

const emit = defineEmits({
    close: () => true,
    confirmed: () => true,
});

const confirm = () => {
    emit('confirmed');
};
const close = () => {
    emit('close');
};
</script>
