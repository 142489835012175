import { defineStore } from 'pinia';
import { channelInterface } from '@/api/channels';
import API from '@/api';

interface State {
    channels: channelInterface[]
    loadChannelsPromise: Promise<any> | null
}

export interface amazonChannelDetails {
    domain: string
    region: 'NA' | 'EU' | 'FE'
    countryCode: string
}

export const useChannelsStore = defineStore('channels', {
    state: (): State => ({
        channels: [],
        loadChannelsPromise: null,
    }),
    getters: {
        getChannels: state => state.channels,
        hasActiveChannels: state => Boolean(state.channels.filter(channel => channel.connectionStatus === 'active').length),
    },
    actions: {
        async loadChannels(): Promise<void> {
            if (this.loadChannelsPromise) return;
            if (this.channels.length) return;

            this.loadChannelsPromise = this.refreshChannels();

            return this.loadChannelsPromise;
        },
        async refreshChannels(): Promise<void> {
            return API.channels.fetchChannels().then((channels) => {
                this.channels = channels;
            }).finally(() => {
                this.loadChannelsPromise = null;
            });
        },
        getAmazonChannelDetails(channel: channelInterface): amazonChannelDetails {
            switch (channel.countryId) {
                case 'A2Q3Y263D00KWC':
                    return {
                        domain: '.com.br',
                        region: 'NA',
                        countryCode: 'BR',
                    };
                case 'A2EUQ1WTGCTBG2':
                    return {
                        domain: '.ca',
                        region: 'NA',
                        countryCode: 'CA',
                    };
                case 'A1AM78C64UM0Y8':
                    return {
                        domain: '.com.mx',
                        region: 'NA',
                        countryCode: 'MX',
                    };
                case 'A2VIGQ35RCS4UG':
                    return {
                        domain: '.ae',
                        region: 'EU',
                        countryCode: 'AE',
                    };
                case 'A1PA6795UKMFR9':
                    return {
                        domain: '.de',
                        region: 'EU',
                        countryCode: 'DE',
                    };
                case 'ARBP9OOSHTCHU':
                    return {
                        domain: '.eg',
                        region: 'EU',
                        countryCode: 'EG',
                    };
                case 'A1RKKUPIHCS9HS':
                    return {
                        domain: '.es',
                        region: 'EU',
                        countryCode: 'ES',
                    };
                case 'A13V1IB3VIYZZH':
                    return {
                        domain: '.fr',
                        region: 'EU',
                        countryCode: 'FR',
                    };
                case 'A1F83G8C2ARO7P':
                    return {
                        domain: '.co.uk',
                        region: 'EU',
                        countryCode: 'GB',
                    };
                case 'A21TJRUUN4KGV':
                    return {
                        domain: '.in',
                        region: 'EU',
                        countryCode: 'IN',
                    };
                case 'APJ6JRA9NG5V4':
                    return {
                        domain: '.it',
                        region: 'EU',
                        countryCode: 'IT',
                    };
                case 'A1805IZSGTT6HS':
                    return {
                        domain: '.nl',
                        region: 'EU',
                        countryCode: 'NL',
                    };
                case 'A1C3SOZRARQ6R3':
                    return {
                        domain: '.pl',
                        region: 'EU',
                        countryCode: 'PL',
                    };
                case 'A17E79C6D8DWNP':
                    return {
                        domain: '.sa',
                        region: 'EU',
                        countryCode: 'SA',
                    };
                case 'A2NODRKZP88ZB9':
                    return {
                        domain: '.se',
                        region: 'EU',
                        countryCode: 'SE',
                    };
                case 'A33AVAJ2PDY3EV':
                    return {
                        domain: '.com.tr',
                        region: 'EU',
                        countryCode: 'TR',
                    };
                case 'A19VAU5U5O7RUS':
                    return {
                        domain: '.sg',
                        region: 'FE',
                        countryCode: 'SG',
                    };
                case 'A39IBJ37TRP1C6':
                    return {
                        domain: '.com.au',
                        region: 'FE',
                        countryCode: 'AU',
                    };
                case 'A1VC38T7YXB528':
                    return {
                        domain: '.co.jp',
                        region: 'FE',
                        countryCode: 'JP',
                    };
                case 'ATVPDKIKX0DER':
                default:
                    return {
                        domain: '.com',
                        region: 'NA',
                        countryCode: 'US',
                    };
            }
        },
    },
});
