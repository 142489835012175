import { companyServiceWithPivotInterface } from './companyServices';
import { itemInterface } from './items';
import { listingInterface } from './listings';
import { itemLocationMoveInterface } from './moves';
import { orderInterface, pickDataInterface } from './orders';
import axios from '@/helpers/forms/axios';

export interface orderItemInterface {
    id: number
    createdAt: string
    updatedAt: string
    orderId: number
    order: orderInterface
    itemId: number
    item: itemInterface
    listingId: number
    listing: listingInterface
    quantity: number
    pickedAt?: string
    pickData?: pickDataInterface
    companyServices: companyServiceWithPivotInterface[]
    locationMoves: itemLocationMoveInterface[]
};

const updateOrderItemLocationMoves = async (orderId: number, orderItemId: number, locationId: number, quantity: number): Promise<orderItemInterface> => {
    const response = await axios.post<{ orderItem: orderItemInterface }>(`/api/orders/${orderId}/updateItems/${orderItemId}/locationMoves`, { locationId, quantity });

    return response.data.orderItem;
};

export default {
    updateOrderItemLocationMoves,
};
