import { computed } from '@vue/reactivity';
import { onMounted, onUnmounted, watch } from 'vue';
import { useNarrative } from './useNarrative';
import axios from '@/helpers/forms/axios';
import { useCrispWidget } from '@/stores/crispWidget';
import { useIntercomWidgetStore } from '@/stores/intercomWidget';

interface customerSupportIntegrationData {
    integrationProvider: 'crisp' | 'intercom'
    identifier: string
    identityHash: string
}

export default function useCustomerSupportIntegration() {
    const narrative = useNarrative();
    const intercomWidget = useIntercomWidgetStore();
    const crispWidget = useCrispWidget();

    const onMerchantPortal = computed(() => {
        return narrative.currentUserOptional.value
            && narrative.currentServiceProvider.value
            && narrative.currentMerchantOptional.value
            && !narrative.userHasRoleOnCurrentServiceProvider.value
            && narrative.userHasRoleOnCurrentMerchant.value;
    });

    const install = async () => {
        if (!onMerchantPortal.value) {
            return;
        }

        const customerSupportIntegrationDataResponse = await axios.get<customerSupportIntegrationData | null>('/api/customer-support-integration');
        const customerSupportIntegrationData = customerSupportIntegrationDataResponse.data;

        if (customerSupportIntegrationData === null) {
            return;
        }

        switch (customerSupportIntegrationData.integrationProvider) {
            case 'intercom':
                intercomWidget.install(customerSupportIntegrationData.identifier);
                intercomWidget.boot({
                    appId: customerSupportIntegrationData.identifier,
                    userId: narrative.currentUser.value.id,
                    userName: narrative.currentUser.value.name,
                    userEmail: narrative.currentUser.value.email,
                    identityHash: customerSupportIntegrationData.identityHash,
                    merchant: {
                        id: narrative.currentMerchant.value.id,
                        name: narrative.currentMerchant.value.name,
                    },
                });
                break;

            case 'crisp':
                crispWidget.install(customerSupportIntegrationData.identifier);
                crispWidget.boot({
                    userName: narrative.currentUser.value.name,
                    userEmail: narrative.currentUser.value.email,
                    identityHash: customerSupportIntegrationData.identityHash,
                    merchantName: narrative.currentMerchant.value.name,
                });
                break;
        }
    };

    const uninstall = () => {
        if (intercomWidget.installed) {
            intercomWidget.shutdown();
        }
    };

    const run = () => {
        onMounted(install);
        onUnmounted(uninstall);
        watch(narrative.currentMerchantOptional, install);
    };

    return {
        run,
    };
}
