<template>
    <div
        class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
    >
        <div class="flex-1 flex justify-between sm:hidden">
            <a
                href="#"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                @click="table.pageNumberClick(-1)"
            >Previous</a>
            <a
                href="#"
                class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                @click="table.pageNumberClick(1)"
            >Next</a>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div class="flex flex-row">
                <p v-if="!hideText" class="text-sm text-gray-700">
                    Showing
                    <span class="font-medium">{{ table.currentItemStart }}</span>
                    to
                    <span class="font-medium">{{ table.currentItemEnd }}</span>
                    of
                    <span class="font-medium">
                        {{ table.filteredItems.length }}
                        <span
                            v-if="table.itemsLoading"
                            class="font-medium"
                        >+</span>
                    </span>
                    results
                    <a
                        v-if="table.items.length !== table.filteredItems.length"
                        class="ml-2 text-primary-500 cursor-pointer italic"
                        @click="table.clearFilters()"
                    >(Clear Filters)</a>
                </p>

                <base-badge
                    v-if="table.itemsLoading"
                    size="sm"
                    :colour="BaseColour.Yellow"
                    class="self-center ml-2"
                >
                    <base-spinner class="h-3 mr-1" />Loading More...
                </base-badge>
            </div>

            <div>
                <nav
                    class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                >
                    <a
                        href="#selectPage"
                        class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        @click="table.pageNumberClick(-1)"
                    >
                        <span class="sr-only">Previous</span>
                        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                    </a>
                    <template v-for="pos in [-3, -2, -1]" :key="pos">
                        <a
                            v-if="table.pageNumberPos(pos) !== -1"
                            href="#selectPage"
                            aria-current="page"
                            class="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                            @click="table.pageNumberClick(pos)"
                        >{{ table.pageNumberPos(pos) + 1 }}</a>
                    </template>
                    <a
                        href="#selectPage"
                        class="z-10 bg-primary-50 border-primary-500 text-primary-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                    >{{ table.pageNumber + 1 }}</a>
                    <template v-for="pos in [1, 2, 3]" :key="pos">
                        <a
                            v-if="table.pageNumberPos(pos) !== -1"
                            href="#selectPage"
                            aria-current="page"
                            class="relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                            @click="table.pageNumberClick(pos)"
                        >{{ table.pageNumberPos(pos) + 1 }}</a>
                    </template>
                    <a
                        href="#selectPage"
                        class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        @click="table.pageNumberClick(1)"
                    >
                        <span class="sr-only">Next</span>
                        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                    </a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType, UnwrapRef, watch } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';
import { AppTable } from '@/helpers/tables';
import { BaseColour } from '@/compiler/types';

const props = defineProps({
    table: {
        type: Object as PropType<UnwrapRef<AppTable<any>>>,
        default: null,
    },
    hideText: {
        type: Boolean,
        default: false,
    },
});

watch(() => props.table.currentItems, (newValue) => {
    if (props.table.filteredItems.length && newValue.length === 0) {
        props.table.setPageNumber(props.table.pageCount);
    }
});
</script>
