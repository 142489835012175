<template>
    <input
        :id="id"
        v-model="model"
        type="checkbox"
        class="focus:ring-primary-500 border-gray-300 text-primary-600 rounded w-4 h-4"
        :class="inputClass"
    >
    <label :for="id" v-bind="$attrs">
        <slot />
    </label>
</template>

<script lang="ts" setup>
defineProps<{
    id: string
    inputClass?: string
}>();

const model = defineModel<boolean>();
</script>
