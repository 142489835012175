import { UnwrapRef } from 'vue';
import integrations from './integrations';
import axios from '@/helpers/forms/axios';
import { SparkFormType, sendForm } from '@/helpers/forms/form';

export interface userNotificationInterface {
    id: string
    createdAt: string
    notificationType: 'action' | 'error' | 'success'
    body: string
    actionText: string
    actionUrl: string
    actionType: 'print_labels'
    actionData: object
    read: boolean
}

export const serviceProviderConfigurableNotifications = [
    'amazonCredentialsUnauthorized',
    'billingCycleEndsTomorrow',
    'billOfLadingAddedToOutboundShipment',
    'cardDeclined',
    'cartonErrorItemDiscrepancyReport',
    'newInboundShipmentCreated',
    'channelCredentialsUpdated',
    'dailyManagerReport',
    'newTeamCreated',
    'merchantSupportRequest',
] as const;
export type ServiceProviderConfigurableNotificationsEnum = typeof serviceProviderConfigurableNotifications[number];

export const merchantConfigurableNotifications = [
    'amazonShipmentCreated',
    'billingFailed',
    'inboundShipmentReceivingReport',
    'invoiceReadyForPayment',
    'itemsRequireFixing',
    'amazonShipmentClosed',
] as const;
export type MerchantConfigurableNotificationsEnum = typeof merchantConfigurableNotifications[number];

export interface notificationSettingInterface {
    disabled: boolean
    emails: string[]
}

export const fetchRecentUserNotifications = async (): Promise<userNotificationInterface[]> => {
    const response = await axios.get<{ data: userNotificationInterface[] }>('/api/notifications/users/recent');

    return response.data.data;
};

export const updateUserNotificationsRead = async () => {
    return await axios.get('/api/notifications/users/read');
};

export const fetchMerchantNotificationSettings = async (merchantId: number): Promise<Partial<Record<MerchantConfigurableNotificationsEnum, notificationSettingInterface>>> => {
    const response = await axios.get<Partial<Record<MerchantConfigurableNotificationsEnum, notificationSettingInterface>>>(`/api/notifications/merchants/${merchantId}/settings`);

    return response.data;
};

export const updateMerchantNotificationSettings = async (form: UnwrapRef<SparkFormType<object>>, merchantId: number): Promise<void> => {
    await sendForm({
        method: 'POST',
        uri: `/api/notifications/merchants/${merchantId}/settings`,
        form,
    });
};

export const fetchServiceProviderNotificationSettings = async (): Promise<Partial<Record<ServiceProviderConfigurableNotificationsEnum, notificationSettingInterface>>> => {
    const response = await axios.get<Partial<Record<ServiceProviderConfigurableNotificationsEnum, notificationSettingInterface>>>('/api/notifications/serviceProviders/settings');

    return response.data;
};

export const updateServiceProviderNotificationSettings = async (form: UnwrapRef<SparkFormType<object>>): Promise<void> => {
    await sendForm({
        method: 'POST',
        uri: '/api/notifications/serviceProviders/settings',
        form,
    });
};

export const fetchServiceProviderToMerchantNotificationSettings = async (): Promise<Partial<Record<MerchantConfigurableNotificationsEnum, { disabled: boolean }>>> => {
    const response = await axios.get<Partial<Record<MerchantConfigurableNotificationsEnum, { disabled: boolean }>>>('/api/notifications/serviceProviders/toMerchantSettings');

    return response.data;
};

export const updateServiceProviderToMerchantNotificationSettings = async (form: UnwrapRef<SparkFormType<object>>): Promise<void> => {
    await sendForm({
        method: 'POST',
        uri: '/api/notifications/serviceProviders/toMerchantSettings',
        form,
    });
};

export default {
    integrations,
    fetchRecentUserNotifications,
    updateUserNotificationsRead,
    fetchMerchantNotificationSettings,
    updateMerchantNotificationSettings,
    fetchServiceProviderNotificationSettings,
    updateServiceProviderNotificationSettings,
    fetchServiceProviderToMerchantNotificationSettings,
    updateServiceProviderToMerchantNotificationSettings,
};
