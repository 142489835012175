<template>
    <div
        aria-live="assertive"
        class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
    >
        <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
            <base-notification
                v-for="(notification, index) in notifications"
                :key="index"
                :notification="notification"
            />
            <base-notification
                v-for="(notification, index) in popupNotificationsStore.getPopupNotifications"
                :key="index"
                :notification="notification"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import { defaultIntertiaPropsInterface } from '@/compiler/types';
import { PopupNotification, usePopupNotificationsStore } from '@/stores/overlays/popupNotifications';

const popupNotificationsStore = usePopupNotificationsStore();
const pagePropErrors = computed(() => (usePage().props as unknown as defaultIntertiaPropsInterface).errors);
const pageFlashErrors = computed(() => (usePage().props as unknown as defaultIntertiaPropsInterface).flash.errorMessages);
const pageFlashSuccess = computed(() => (usePage().props as unknown as defaultIntertiaPropsInterface).flash.successMessages);

const notifications = computed(() => {
    const notificationsObject: PopupNotification[] = [];

    if (Object.keys(pagePropErrors.value).length) {
        Object.values(pagePropErrors.value).forEach((error) => {
            const notification: PopupNotification = {
                type: 'error',
                title: error,
                permanent: true,
            };

            notificationsObject.push(notification);
        });
    }

    if (pageFlashErrors.value) {
        pageFlashErrors.value.forEach((message) => {
            const notification: PopupNotification = {
                type: 'error',
                title: message,
                permanent: true,
            };

            notificationsObject.push(notification);
        });
    }

    if (pageFlashSuccess.value) {
        pageFlashSuccess.value.forEach((message) => {
            const notification: PopupNotification = {
                type: 'success',
                title: message,
                permanent: true,
            };

            notificationsObject.push(notification);
        });
    }

    return notificationsObject;
});
</script>
