<template>
    <div class="flex-1 flex flex-col mb-2 mt-1">
        <div class="w-full flex md:ml-0">
            <label for="tableFilter" class="sr-only">{{ placeholder }}</label>
            <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        />
                    </svg>
                </div>
                <input
                    id="tableFilter"
                    class="block w-full h-full px-10 py-2 rounded-md text-gray-900 placeholder-gray-500 focus:ring-primary-500 focus:border-primary-500 border-gray-300 sm:text-sm"
                    :placeholder="placeholder"
                    type="search"
                    :value="table.filterSearch"
                    @input="table.updateFilterSearch(getValueFromEvent($event))"
                >
                <div
                    v-if="table.filters.length"
                    class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    @click="table.toggleFilterOpen()"
                >
                    <ChevronDownIcon
                        class="h-5 w-5"
                        :class="filterOpen ? 'text-primary-400' : 'text-gray-400'"
                    />
                </div>
            </div>
        </div>
        <transition
            enter-active-class="ease-out duration-200 transition origin-top"
            enter-from-class="scale-y-75 opacity-0"
            enter-to-class="scale-y-100 opacity-100"
            leave-active-class="ease-in duration-200 transition origin-top"
            leave-from-class="scale-y-100 opacity-100"
            leave-to-class="scale-y-75 opacity-0"
        >
            <div v-if="filterOpen" class="flex mt-2">
                <base-card class="min-w-max px-1">
                    <div
                        v-for="filter in table.filterInputs('text')"
                        :key="filter.column"
                        class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5"
                    >
                        <label
                            class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                        >
                            {{
                                filter.label
                            }}
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-3">
                            <div class="max-w-xs rounded-md shadow-sm">
                                <input
                                    v-model="filter.value"
                                    :placeholder="filter.label"
                                    type="text"
                                    class="border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 block min-w-0 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="filter in table.filterInputs('number')"
                        :key="filter.column"
                        class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5"
                    >
                        <label
                            class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                        >
                            {{
                                filter.label
                            }}
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-3">
                            <div class="flex flex-row justify-between">
                                <div class="rounded-md shadow-sm relative w-full">
                                    <div
                                        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                                    >
                                        <HashtagIcon class="h-4 w-4 text-gray-400" />
                                    </div>
                                    <input
                                        v-model="filter.from"
                                        type="number"
                                        class="pl-10 border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 block min-w-0 w-full sm:text-sm sm:leading-5 placeholder-gray-400"
                                    >
                                </div>
                                <div class="self-center text-gray-500 text-sm mx-2">
                                    To
                                </div>
                                <div class="rounded-md shadow-sm relative w-full">
                                    <div
                                        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                                    >
                                        <HashtagIcon class="h-4 w-4 text-gray-400" />
                                    </div>
                                    <input
                                        v-model="filter.to"
                                        type="number"
                                        class="pl-10 border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 block min-w-0 w-full sm:text-sm sm:leading-5"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-for="filter in table.filterInputs('date')"
                        :key="filter.column"
                        class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5"
                    >
                        <label
                            class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                        >
                            {{
                                filter.label
                            }}
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-3">
                            <div class="flex flex-row justify-between">
                                <div class="rounded-md shadow-sm relative w-full">
                                    <div
                                        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                                    >
                                        <CalendarIcon class="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        v-model="filter.from"
                                        type="date"
                                        class="pl-10 border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 block min-w-0 w-full sm:text-sm sm:leading-5 placeholder-gray-400"
                                    >
                                </div>
                                <div class="self-center text-gray-500 text-sm mx-2">
                                    To
                                </div>
                                <div class="rounded-md shadow-sm relative w-full">
                                    <div
                                        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                                    >
                                        <CalendarIcon class="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        v-model="filter.to"
                                        type="date"
                                        class="pl-10 border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 block min-w-0 w-full sm:text-sm sm:leading-5"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-for="filter in table.filterInputs('select')"
                        :key="filter.column"
                        class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5"
                    >
                        <label
                            class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                        >
                            {{
                                filter.label
                            }}
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-3">
                            <div class="max-w-xs rounded-md shadow-sm">
                                <select
                                    v-model="filter.value"
                                    class="focus:ring-primary-500 focus:border-primary-500 shadow-sm border-gray-300 rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 truncate"
                                >
                                    <option
                                        v-for="option in table.selectFilterOptions(filter)"
                                        :key="option"
                                        :value="option"
                                    >
                                        {{ snakeToTitleCase(option) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="text-right mt-2">
                        <base-button
                            :icon="TrashIcon"
                            text="Clear Filters"
                            design="outline"
                            :colour="BaseColour.Gray"
                            :click-func="table.clearFilters"
                        />
                    </div>
                </base-card>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" setup>
import { PropType, UnwrapRef, computed } from 'vue';
import { CalendarIcon, ChevronDownIcon, HashtagIcon, TrashIcon } from '@heroicons/vue/20/solid';
import { AppTable } from '@/helpers/tables';
import useFormatters from '@/composables/useFormatters';
import { BaseColour } from '@/compiler/types';

const props = defineProps({
    table: {
        type: Object as PropType<UnwrapRef<AppTable<any>>>,
        default: null,
    },
    placeholder: {
        type: String,
        default: null,
    },
});

const emit = defineEmits<{
    (e: 'updateFilterInput', column: string, prop: 'value' | 'from' | 'to', value: string): void
}>();

const { snakeToTitleCase } = useFormatters();
const updateFilterInput = (column: string, prop: 'value' | 'from' | 'to', value: string) => {
    emit('updateFilterInput', column, prop, value);
};
const filterOpen = computed(() => props.table.filterOpen);

const getValueFromEvent = (event: Event): string => {
    return (event.target as HTMLInputElement).value;
};
</script>
