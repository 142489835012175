import { defineStore } from 'pinia';
import { itemInterface } from '@/api/repositories/items';

interface State {
    printLabelItems: itemInterface[]
    itemExpiryDate: string
    useSlideoverId: string
}

export const useLabelPrintsStore = defineStore('labelPrints', {
    state: (): State => ({
        printLabelItems: [],
        itemExpiryDate: '',
        useSlideoverId: 'printItemFnskuLabelsSlideover',
    }),
    getters: {
        getPrintLabelItems: state => state.printLabelItems,
        getItemExpiryDate: state => state.itemExpiryDate,
    },
    actions: {
        printLabels(value: { items: itemInterface[], expiryDate: string }): void {
            this.printLabelItems = value.items;
            this.itemExpiryDate = value.expiryDate;
        },
        clearLabels(): void {
            this.printLabelItems = [];
            this.itemExpiryDate = '';
        },
        resetSlideoverId(): void {
            this.useSlideoverId = 'printItemFnskuLabelsSlideover';
        },
    },
});
