<template>
    <ActionIcon
        ref="iconRef"
        tooltip="Copy to Clipboard"
        :click-func="() => copyValue(value)"
        :icon="ClipboardDocumentCheckIcon"
    />

    <span v-if="text" v-bind="$attrs" class="cursor-pointer" @click="iconRef?.$el.click()">{{ text }}</span>
</template>

<script lang="ts" setup>
import { ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import ActionIcon from './ActionIcon.vue';

defineProps({
    value: {
        type: String,
        required: true,
    },
    text: {
        type: String,
        default: undefined,
    },
});

const iconRef = ref<InstanceType<typeof ActionIcon> | null>(null);

const copyValue = (text: string) => {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
};
</script>
