import axios from 'axios';
import { Currency } from 'dinero.js';

interface exchangeRateResponse {
    [key: string]: { [key: string]: number }
}

let exchangeRatesPromise: Promise<{ [key: string]: number }> | null = null;
const fetchExchangeRates = async (baseCurrency: Currency): Promise<{ [key: string]: number }> => {
    if (exchangeRatesPromise) return exchangeRatesPromise;

    exchangeRatesPromise = new Promise<{ [key: string]: number }>((resolve, reject) => {
        axios.get<exchangeRateResponse>(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${baseCurrency.toLowerCase()}.json`)
            .then((response) => {
                resolve(formatExchangeRatesFromResponse(response.data, baseCurrency));
            }).catch((error) => {
                console.error(error);

                axios.get<exchangeRateResponse>(`https://latest.currency-api.pages.dev/v1/currencies/${baseCurrency.toLowerCase()}.json`).then((response) => {
                    resolve(formatExchangeRatesFromResponse(response.data, baseCurrency));
                }).catch((error) => {
                    reject(error);
                });
            });
    }).finally(() => {
        exchangeRatesPromise = null;
    });

    return await exchangeRatesPromise;
};

const formatExchangeRatesFromResponse = (response: exchangeRateResponse, baseCurrency: Currency): { [key: string]: number } => {
    const exchangeRates = response[baseCurrency.toLowerCase()];

    const formattedExchangeRates = Object.fromEntries(
        Object.entries(exchangeRates).map(([key, value]) => [key.toUpperCase(), value]),
    );

    return formattedExchangeRates;
};

export default {
    fetchExchangeRates,
};
