import axios from '@/helpers/forms/axios';

export const validAdjustmentReasons = ['lost', 'found', 'damaged', 'other'];

export interface adjustmentInterface {
    id: number
    notes: string
    reason: string
}

const fetchAdjustment = async (id: number): Promise<adjustmentInterface> => {
    const response = await axios.get<adjustmentInterface>(`/api/adjustments/${id}`);

    return response.data;
};

export default {
    fetchAdjustment,
};
