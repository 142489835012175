import { merchantInterface } from './merchants';
import { serviceProviderInterface } from './serviceProviders';
import axios from '@/helpers/forms/axios';
import { moneyInterface } from '@/compiler/types';

export interface stripePlatformAccountInterface {
    id: string
    createdAt: string
    updatedAt: string
    accountId: string
    platformBrand: string
};

export interface stripeServiceProviderAccountInterface {
    id: string
    createdAt: string
    updatedAt: string
    accountId: string
    serviceProvider: serviceProviderInterface
};

export interface stripeMerchantCustomerPaymentMethod {
    id: string
    createdAt: string
    updatedAt: string
    paymentMethodId: string
    paymentMethodType: string
    publicLastFour: string
    stripeMerchantCustomerId: string
};

export interface stripeServiceProviderCustomerPaymentMethod {
    id: string
    createdAt: string
    updatedAt: string
    paymentMethodId: string
    paymentMethodType: string
    publicLastFour: string
};

export interface stripeServiceProviderCustomerInterface {
    id: string
    createdAt: string
    updatedAt: string
    customerId: string
    serviceProvider: serviceProviderInterface
    platformAccount: stripePlatformAccountInterface
    defaultPaymentMethod: stripeServiceProviderCustomerPaymentMethod | null
    paymentMethods: stripeServiceProviderCustomerPaymentMethod[]
};

export interface stripeMerchantCustomerInterface {
    id: string
    createdAt: string
    updatedAt: string
    customerId: string
    merchant: merchantInterface
    serviceProviderAccount: stripeServiceProviderAccountInterface
    defaultPaymentMethod: stripeMerchantCustomerPaymentMethod
    paymentMethods: stripeMerchantCustomerPaymentMethod[]
};

const fetchStripeServiceProviderAccount = async (): Promise<stripeServiceProviderAccountInterface> => {
    const response = await axios.get<{ stripeServiceProviderAccount: stripeServiceProviderAccountInterface }>('/api/payment-providers/stripe/accounts/service-provider');

    return response.data.stripeServiceProviderAccount;
};

const createStripeServiceProviderCustomer = async (): Promise<stripeServiceProviderCustomerInterface> => {
    const response = await axios.post<{ stripeServiceProviderCustomer: stripeServiceProviderCustomerInterface }>('/api/payment-providers/stripe/customers/service-provider/create');

    return response.data.stripeServiceProviderCustomer;
};

const createStripeMerchantCustomer = async (): Promise<stripeMerchantCustomerInterface> => {
    const response = await axios.post<{ stripeMerchantCustomer: stripeMerchantCustomerInterface }>('/api/payment-providers/stripe/customers/merchant/create');

    return response.data.stripeMerchantCustomer;
};

const fetchStripeServiceProviderCustomer = async (): Promise<stripeServiceProviderCustomerInterface> => {
    const response = await axios.get<{ stripeServiceProviderCustomer: stripeServiceProviderCustomerInterface }>('/api/payment-providers/stripe/customers/service-provider');

    return response.data.stripeServiceProviderCustomer;
};

const fetchStripeMerchantCustomer = async (): Promise<stripeMerchantCustomerInterface> => {
    const response = await axios.get<{ stripeMerchantCustomer: stripeMerchantCustomerInterface }>('/api/payment-providers/stripe/customers/merchant');

    return response.data.stripeMerchantCustomer;
};

const deletePaymentMethodFromStripeServiceProviderCustomer = async (stripeServiceProviderCustomerId: string, paymentMethodId: string): Promise<void> => {
    await axios.post(`/api/payment-providers/stripe/customers/service-provider/${stripeServiceProviderCustomerId}/payment-methods/delete`, { paymentMethodId });
};

const deletePaymentMethodFromStripeMerchantCustomer = async (stripeMerchantCustomerId: string, paymentMethodId: string): Promise<void> => {
    await axios.post(`/api/payment-providers/stripe/customers/merchant/${stripeMerchantCustomerId}/payment-methods/delete`, { paymentMethodId });
};

const setPaymentMethodAsDefaultForStripeServiceProviderCustomer = async (stripeServiceProviderCustomerId: string, paymentMethodId: string): Promise<void> => {
    await axios.post(`/api/payment-providers/stripe/customers/service-provider/${stripeServiceProviderCustomerId}/payment-methods/default`, { paymentMethodId });
};

const setPaymentMethodAsDefaultForStripeMerchantCustomer = async (stripeMerchantCustomerId: string, paymentMethodId: string): Promise<void> => {
    await axios.post(`/api/payment-providers/stripe/customers/merchant/${stripeMerchantCustomerId}/payment-methods/default`, { paymentMethodId });
};

const getStripeMerchantCustomerBalance = async (stripeMerchantCustomerId: string): Promise<moneyInterface> => {
    const response = await axios.get<{ balance: moneyInterface }>(`/api/payment-providers/stripe/customers/merchant/${stripeMerchantCustomerId}/balance`);

    return {
        amount: response.data.balance.amount,
        currency: response.data.balance.currency,
    };
};

const addStripePaymentMethodForMerchant = async (stripeMerchantCustomerId: string, stripePaymentMethodId: string): Promise<void> => {
    await axios.post(`/api/payment-providers/stripe/customers/merchant/${stripeMerchantCustomerId}/payment-methods`, { stripePaymentMethodId });
};

const addStripePaymentMethodForServiceProvider = async (stripeServiceProviderCustomerId: string, stripePaymentMethodId: string): Promise<void> => {
    await axios.post(`/api/payment-providers/stripe/customers/service-provider/${stripeServiceProviderCustomerId}/payment-methods`, { stripePaymentMethodId });
};

const fetchStripeTokenForStripeMerchantCustomer = async (stripeMerchantCustomerId: string): Promise<string> => {
    const response = await axios.get<{ clientSecret: string }>(`/api/payment-providers/stripe/customers/merchant/${stripeMerchantCustomerId}/setup-intent`);

    return response.data.clientSecret;
};

const fetchStripeTokenForStripeServiceProviderCustomer = async (stripeServiceProviderCustomerId: string): Promise<string> => {
    const response = await axios.get<{ clientSecret: string }>(`/api/payment-providers/stripe/customers/service-provider/${stripeServiceProviderCustomerId}/setup-intent`);

    return response.data.clientSecret;
};

export default {
    fetchStripeServiceProviderAccount,
    createStripeServiceProviderCustomer,
    fetchStripeServiceProviderCustomer,
    createStripeMerchantCustomer,
    fetchStripeMerchantCustomer,
    getStripeMerchantCustomerBalance,
    addStripePaymentMethodForServiceProvider,
    addStripePaymentMethodForMerchant,
    deletePaymentMethodFromStripeServiceProviderCustomer,
    deletePaymentMethodFromStripeMerchantCustomer,
    setPaymentMethodAsDefaultForStripeServiceProviderCustomer,
    setPaymentMethodAsDefaultForStripeMerchantCustomer,
    fetchStripeTokenForStripeServiceProviderCustomer,
    fetchStripeTokenForStripeMerchantCustomer,
};
