import { customerAddressInterface, customerInterface } from './customers';
import { orderReturnItemInterface } from './orderReturnItems';
import { attachmentInterface } from './attachments';
import { boxInterface } from './packages';
import { orderQuoteSummaryInterface } from './orders';
import { trackingNumberInterface } from './trackingNumbers';
import axios from '@/helpers/forms/axios';

export interface orderReturnInterface {
    id: number
    createdAt: string
    updatedAt: string
    customerId: number
    customer: customerInterface
    boxId: number
    shippingFromAddressId: number
    shippingFromAddress: customerAddressInterface
    warehouseId: number
    returnId: string
    orderId: string
    channelOrderId: string
    exchangeOrderId: number
    approvedForShippingAt: string
    cancelledAt: string
    shippedAt: string
    receivedAt: string
    expectedStateEvaluatedAt: string
    rejectedAt: string
    rejectedReturnShippedAt: string
    resolvedAt: string
    closedAt: string
    internalNotes: string
    customerNotes: string
    warehouseNotes: string
    orderReturnItems: orderReturnItemInterface[]
    returnItemsCount: number
    box: boxInterface
    status: string
    quotes: orderQuoteSummaryInterface | null
}

export interface orderReturnViewInterface extends orderReturnInterface {
    shippingFromAddress: customerAddressInterface
    box: boxInterface
    trackingNumbers: Array<trackingNumberInterface>
    attachments: attachmentInterface[]
}

export interface orderReturnAddItemInterface {
    id: string
    itemId: number
    reason: string
    type: string
    notes: string
}

const fetchOrderReturns = async (): Promise<orderReturnInterface[]> => {
    const response = await axios.get<{ returns: orderReturnInterface[] }>('/api/order-returns');
    return response.data.returns;
};

const fetchOrderReturn = async (orderReturnId: number): Promise<orderReturnViewInterface> => {
    const response = await axios.get<{ return: orderReturnViewInterface }>(`/api/order-returns/${orderReturnId}`);
    return response.data.return;
};

const updateOrderReturnItem = async (orderReturnId: number, orderReturnItem: orderReturnItemInterface): Promise<orderReturnItemInterface> => {
    const response = await axios.put<{ orderReturnItem: orderReturnItemInterface }>(
        `/api/order-returns/${orderReturnId}/items/${orderReturnItem.id}`,
        orderReturnItem,
    );

    return response.data.orderReturnItem;
};

const updateOrderReturn = async (orderReturn: orderReturnInterface) => {
    await axios.put(`/api/order-returns/${orderReturn.id}`, orderReturn);
};

const markOrderReturnAsClosed = async (orderReturnId: number): Promise<string> => {
    const response = await axios.post<{ href: string }>(`/api/order-returns/${orderReturnId}/markAsClosed`);
    return response.data.href;
};

const deleteOrderReturns = async (ids: number[]): Promise<null> => {
    await axios.post('/api/order-returns/delete', { ids });
    return null;
};

const getOrderReturnShippingQuotes = async (orderReturnId: number, refresh: boolean = false): Promise<orderQuoteSummaryInterface | null> => {
    let url = `/api/order-returns/${orderReturnId}/getQuote`;
    if (refresh) {
        url += '?refresh=true';
    }
    const response = await axios.get<{ quotes: orderQuoteSummaryInterface }>(url);

    return response.data.quotes;
};

const purchaseOrderReturnLabel = async (orderReturnId: number): Promise<[]> => {
    const response = await axios.post<{ attachments: [] }>(`/api/order-returns/${orderReturnId}/purchaseLabel`);

    return response.data.attachments;
};

export default {
    fetchOrderReturns,
    fetchOrderReturn,
    updateOrderReturnItem,
    updateOrderReturn,
    markOrderReturnAsClosed,
    deleteOrderReturns,
    getOrderReturnShippingQuotes,
    purchaseOrderReturnLabel,
};
