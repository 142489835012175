export default function useSounds(shouldPlaySounds: boolean) {
    const confirmationSound = new Audio('https://res.cloudinary.com/jasara-technology/video/upload/v1652258467/soundfx/Selection_x6ug9k.mp3');
    const errorSound = new Audio('https://res.cloudinary.com/jasara-technology/video/upload/v1679502311/soundfx/error-sfx_neezcd.mp3');

    const playSound = (audio: HTMLAudioElement) => {
        audio.currentTime = 0;
        audio.play();
    };

    const playConfirmationSound = () => {
        if (!shouldPlaySounds) return;

        playSound(confirmationSound);
    };

    const playErrorSound = () => {
        if (!shouldPlaySounds) return;

        playSound(errorSound);
    };

    return {
        playConfirmationSound,
        playErrorSound,
    };
};
