import { ComputePositionConfig, Middleware, arrow, autoUpdate, computePosition, size } from '@floating-ui/dom';

interface appOptionsInterface {
    atLeastSameSizeAsReference: boolean
    triggerType: 'click' | 'mouseenter'
    addArrow: boolean
}

export default function useFloatingUi(
    uniqueId: string,
    floatingUiOptions: Partial<ComputePositionConfig> = {},
    appOptions?: Partial<appOptionsInterface>,
) {
    const floatingUiTriggerSelector = `.floatingUiTrigger-${uniqueId}`;
    const floatingUiElementSelector = `.floatingUiElement-${uniqueId}`;

    const constructMiddleware = (): Middleware[] => {
        const middleware: Middleware[] = floatingUiOptions.middleware ?? [];

        if (appOptions?.atLeastSameSizeAsReference) {
            middleware.push(size({
                apply({ reference, floating }) {
                    const floatingUiElement = document.querySelector(floatingUiElementSelector);

                    if (!(floatingUiElement instanceof HTMLElement)) return;

                    if (reference.width > floating.width) {
                        Object.assign(floatingUiElement.style, {
                            width: `${reference.width}px`,
                        });
                    }
                },
            }));
        }

        if (appOptions?.addArrow) {
            const floatingUiElement = document.querySelector(floatingUiElementSelector);

            if (!(floatingUiElement instanceof HTMLElement)) return middleware;

            const arrowElement = floatingUiElement.querySelector('.floatingUiArrow');

            if (!(arrowElement instanceof HTMLElement)) return middleware;

            middleware.push(arrow({ element: arrowElement }));
        }

        return middleware;
    };

    const updateFloatingUiElement = () => {
        floatingUiOptions.middleware = constructMiddleware();

        const floatingUiTrigger = document.querySelector(floatingUiTriggerSelector);
        const floatingUiElement = document.querySelector(floatingUiElementSelector);

        if (!(floatingUiTrigger instanceof HTMLElement)) return;
        if (!(floatingUiElement instanceof HTMLElement)) return;

        const cleanup = autoUpdate(
            floatingUiTrigger,
            floatingUiElement,
            getUpdatePositionCallback(floatingUiTrigger, floatingUiElement),
        );

        checkForRemove(cleanup);
    };

    const getUpdatePositionCallback = (floatingUiTrigger: HTMLElement, floatingUiElement: HTMLElement): () => void => {
        return () => {
            computePosition(floatingUiTrigger, floatingUiElement, floatingUiOptions).then(({ x, y, placement, middlewareData }) => {
                Object.assign(floatingUiElement.style, {
                    left: `${x}px`,
                    top: `${y}px`,
                });

                const arrowElement = floatingUiElement.querySelector('.floatingUiArrow');

                if (!(arrowElement instanceof HTMLElement)) return;

                const arrowData = middlewareData.arrow;
                const arrowX = arrowData?.x;
                const arrowY = arrowData?.y;

                const staticSide = {
                    top: 'bottom',
                    right: 'left',
                    bottom: 'top',
                    left: 'right',
                }[placement.split('-')[0]];

                if (!staticSide) return;

                Object.assign(arrowElement.style, {
                    left: arrowX != null ? `${arrowX}px` : '',
                    top: arrowY != null ? `${arrowY}px` : '',
                    right: '',
                    bottom: '',
                    [staticSide]: '-4px',
                });
            });
        };
    };

    const checkForRemove = (cleanup: Function) => {
        const checkForRemoveInterval = setInterval(() => {
            const floatingUiTrigger = document.querySelector(floatingUiTriggerSelector);

            if ((floatingUiTrigger instanceof HTMLElement)) return;

            cleanup();

            clearInterval(checkForRemoveInterval);
        }, 500);
    };

    const checkInterval = setInterval(() => {
        const floatingUiTrigger = document.querySelector(floatingUiTriggerSelector);

        if (!(floatingUiTrigger instanceof HTMLElement)) return;

        floatingUiTrigger.addEventListener(appOptions?.triggerType ?? 'click', updateFloatingUiElement);

        clearInterval(checkInterval);
    }, 500);

    return {
        updateFloatingUiElement,
    };
}
