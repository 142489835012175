import API from '@/api';
import { imageInterface } from '@/api/repositories/images';
import { useLargeImageStore } from '@/stores/overlays/largeImage';

interface itemWithImages {
    images: imageInterface[]
}

export default function useImages() {
    const largeImageStore = useLargeImageStore();

    const imageUrl = (item: itemWithImages, size: 'thumbnail' | 'large' = 'thumbnail'): string => {
        if (item.images.length) {
            const property = size === 'thumbnail' ? 'thumbnailUrl' : 'largeUrl';
            return item.images[0][property];
        }

        return '';
    };

    const openLargeImage = (item: itemWithImages): void => {
        largeImageStore.setLargeImageSrc(imageUrl(item, 'large'));
    };

    const deleteImage = (imageId: number) => {
        return API.deleteImage(imageId);
    };

    return {
        imageUrl,
        openLargeImage,
        deleteImage,
    };
}
