import axios, { fetchPaginatedDataFunction } from '@/helpers/forms/axios';

export interface ExportOperation {
    id: string
    createdAt: string
    description: string
    userId: number
    progress: number
    filename: string
    failedAt: string | null
    failureMessage: string | null
}

const getOperations = fetchPaginatedDataFunction<ExportOperation>('/api/reports/exports/operations');

const fetchOperation = async (operationId: string): Promise<ExportOperation> => {
    const response = await axios.get<{ data: ExportOperation }>(`/api/reports/exports/operations/${operationId}`);

    return response.data.data;
};

const getDownloadUrlForOperation = async (operationId: string): Promise<string> => {
    const response = await axios.get<{ downloadUrl: string }>(`/api/reports/exports/operations/${operationId}/download`);

    return response.data.downloadUrl;
};

const exportInventoryItems = async (): Promise<ExportOperation> => {
    const response = await axios.get<{ data: ExportOperation }>('/api/reports/exports/inventory');

    return response.data.data;
};

const exportCharges = async (): Promise<ExportOperation> => {
    const response = await axios.get<{ data: ExportOperation }>('/api/reports/exports/charges');

    return response.data.data;
};

const exportChargeItems = async (chargeId: number | null = null): Promise<ExportOperation> => {
    const url = new URL('/api/reports/exports/charge-items', location.origin);
    if (chargeId) {
        url.searchParams.append('charge_id', chargeId.toString());
    }

    const response = await axios.get<{ data: ExportOperation }>(url.toString());

    return response.data.data;
};

export default {
    getOperations,
    fetchOperation,
    getDownloadUrlForOperation,

    exportInventoryItems,
    exportCharges,
    exportChargeItems,
};
