import { listingInterface } from '../channels/listings';
import { bundleInterface } from './bundles';
import { companyServiceInterface } from './companyServices';
import { identifierInterface } from './identifiers';
import { imageInterface } from './images';
import { itemGroupConfigurationInterface } from './itemGroupConfigurations';
import { locationInterface } from './../warehouses/locations';
import { tagInterface } from './tags';
import axios from '@/helpers/forms/axios';

export interface itemHistoryInterface {
    date: string
    type: string
    id: number
    quantity: number
    stockStatus: 'complete' | 'inbound' | 'allocated'
}

export const itemConditions = [
    'new',
    'used_like_new',
    'used_very_good',
    'used_good',
    'used_acceptable',
    'used_poor',
    'collectible_like_new',
    'collectible_very_good',
    'collectible_good',
    'collectible_acceptable',
    'collectible_poor',
    'used_refurbished',
    'refurbished',
] as const;
export type ItemConditionEnum = typeof itemConditions[number];

export const itemConditionTypes = [
    'new',
    'used',
    'collectible',
    'refurbished',
] as const;
export type ItemConditionTypeEnum = typeof itemConditionTypes[number];

export interface itemInterface {
    id: number
    teamId: number
    bundle: bundleInterface
    bundleId: number
    containingBundles: bundleInterface[]
    locations: locationInterface[]
    listings?: listingInterface[]
    title: string
    merchantSku: string
    fnsku: string
    asin: string
    images: imageInterface[]
    identifiers: identifierInterface[]
    searchableIdentifiers: string
    itemGroupConfigurations: itemGroupConfigurationInterface[]
    companyServices: companyServiceInterface[]
    prepInstructions: string[]
    costPerItem?: number
    quantityInStock: number
    history?: itemHistoryInterface[]
    weightGm: number
    lengthMm: number
    widthMm: number
    heightMm: number
    condition: ItemConditionEnum
    conditionNote: string
    availableQuantity?: number
    unavailableQuantity?: number
    allocatedQuantity?: number
    inboundQuantity?: number
    searchableTags: string[]
    tags?: tagInterface[]
}

const fetchInventoryItem = async (itemId: number): Promise<itemInterface> => {
    const response = await axios.get<{ item: itemInterface }>(`/api/inventory/${itemId}`);

    return response.data.item;
};

const removeTagFromInventoryItem = async (itemId: number, tagId: number): Promise<void> => {
    return await axios.delete(`/api/inventory/${itemId}/tags/delete-tag/${tagId}`);
};

const addTagToInventoryItem = async (itemId: number, tagId: number): Promise<void> => {
    return await axios.post(`/api/inventory/${itemId}/tags/add-tag/${tagId}`);
};

export default {
    fetchInventoryItem,
    removeTagFromInventoryItem,
    addTagToInventoryItem,
};
