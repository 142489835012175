import axios from '@/helpers/forms/axios';

export interface countryInterface {
    code: string
    name: string
}

export interface subdivisionInterface {
    abbreviation: string
    name: string
}

const fetchCountries = async (): Promise<countryInterface[]> => {
    const response = await axios.get<countryInterface[]>('/api/addresses/countries');

    return response.data;
};

const fetchSubdivisions = async (countryCode: string): Promise<subdivisionInterface[]> => {
    const response = await axios.get<subdivisionInterface[]>(`/api/addresses/countries/${countryCode}/subdivisions`);

    return response.data;
};

export default {
    fetchCountries,
    fetchSubdivisions,
};
