<template>
    <BaseBadge
        :colour="statusColour"
        size="sm"
    >
        <div class="flex-none rounded-full mr-1" :class="circleClass">
            <div class="h-2 w-2 rounded-full bg-current" />
        </div>
        <span>{{ snakeToTitleCase(operation.getStatus().toLocaleLowerCase()) }}</span>
        <span v-if="operation.getStatus() === OperationStatus.InProgress" class="ml-1"> - {{ progress }}%</span>
    </BaseBadge>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue';
import { BaseColour } from '@/compiler/types';
import BaseBadge from '@/components/elements/BaseBadge.vue';
import useFormatters from '@/composables/useFormatters';
import { Operation, OperationStatus } from '@/stores/operations/operations';

const props = defineProps({
    operation: {
        type: Object as PropType<Operation>,
        required: true,
    },
});

const emit = defineEmits<{
    (e: 'updated'): void
}>();

const { snakeToTitleCase } = useFormatters();

const statusColour = computed((): BaseColour => {
    switch (props.operation.getStatus()) {
        case OperationStatus.InProgress:
            return BaseColour.Yellow;
        case OperationStatus.Succeeded:
            return BaseColour.Green;
        case OperationStatus.Failed:
            return BaseColour.Red;
        default:
            return BaseColour.Yellow;
    }
});

const circleClass = computed((): string => {
    switch (props.operation.getStatus()) {
        case OperationStatus.InProgress:
            return 'text-yellow-500 bg-yellow-100/10 animate-pulse';
        case OperationStatus.Succeeded:
            return 'text-green-400 bg-green-400/10';
        case OperationStatus.Failed:
            return 'text-red-400 bg-red-400/10';
        default:
            return 'text-gray-500 bg-gray-100/10';
    }
});

const progress = computed(() => {
    return Math.ceil(
        Math.min(
            100,
            Math.max(1, props.operation.getProgress()),
        ),
    );
});
</script>
