import { computed } from 'vue';
import { AppEntitlementEnum } from '@/compiler/types';
import { useCurrentUserStore } from '@/stores/currentUser';

export default function useAppEntitlements() {
    const currentUserStore = useCurrentUserStore();

    return {
        hasAmazonShipmentsEntitlement: computed(() => currentUserStore.hasEntitlement(AppEntitlementEnum.AmazonShipments)),
        hasLocationsEntitlement: computed(() => currentUserStore.hasEntitlement(AppEntitlementEnum.Locations)),
        hasOrdersEntitlement: computed(() => currentUserStore.hasEntitlement(AppEntitlementEnum.Orders)),
    };
};
