import { moveInterface } from '../repositories/moves';
import { itemInterface } from '../repositories/items';
import axios from '@/helpers/forms/axios';

export interface locationInterface {
    id: number
    warehouseId: number
    code: string
    type: locationTypeType
    currentItems: {
        quantity: number
        itemId: number
    }[]
    moves?: moveInterface[]
}

export type locationTypeType = 'shelf' | 'pallet' | 'bin_small' | 'bin_medium' | 'bin_large' | 'bin_xlarge';

export const validLocationTypes: locationTypeType[] = ['shelf', 'pallet', 'bin_small', 'bin_medium', 'bin_large', 'bin_xlarge'];

const fetchLocations = async (warehouseId: number, merchantId: number | null = null): Promise<{
    locations: locationInterface[]
    items: itemInterface[]
}> => {
    const response = await axios.get<{
        locations: locationInterface[]
        items: itemInterface[]
    }>(`/api/warehouses/${warehouseId}/locations`, {
        params: {
            merchantId,
        },
    });

    return response.data;
};

const fetchLocation = async (warehouseId: number, locationId: number): Promise<locationInterface> => {
    const response = await axios.get<{ location: locationInterface }>(`/api/warehouses/${warehouseId}/locations/${locationId}`);

    return response.data.location;
};

const fetchItemLocations = async (itemId: number): Promise<Array<locationInterface>> => {
    const response = await axios.get<{ locations: locationInterface[] }>(`/api/inventory/${itemId}/locations`);

    return response.data.locations;
};

const exportLocations = async (warehouseId: number): Promise<string> => {
    const response = await axios.get<{ downloadUrl: string }>(`/api/warehouses/${warehouseId}/locations/export`);

    return response.data.downloadUrl;
};

export default {
    fetchLocations,
    fetchLocation,
    fetchItemLocations,
    exportLocations,
};
