<template>
    <th :class="thClasses" @click="click">
        <div
            v-if="$slots.default"
            class="flex flex-row justify-between whitespace-nowrap items-center"
        >
            <slot />

            <component
                :is="table.sortIcon(sortableProp)"
                v-if="sortableProp"
                class="h-5 w-5 ml-1"
                :class="table.sortColour(sortableProp)"
            />
        </div>
    </th>
</template>

<script lang="ts" setup>
import { PropType, UnwrapRef, computed } from 'vue';
import { AppTable } from '@/helpers/tables';

const props = defineProps({
    padding: {
        type: String,
        validator: (prop: string) => ['narrow'].includes(prop),
        default: null,
    },
    class: {
        type: String,
        default: null,
    },
    sortableProp: {
        type: String,
        default: null,
    },
    table: {
        type: Object as PropType<UnwrapRef<AppTable<any>>>,
        default: null,
    },
});

const thClasses = computed(() => {
    let classes = 'border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider break-words first:pl-6 last:pr-6 ';
    classes += props.padding ? 'px-1 py-1 first:pl-4 last:pr-4' : 'px-6 py-3';
    classes += (props.class ? ` ${props.class}` : '');
    return classes;
});

const click = () => {
    if (props.table && props.sortableProp) {
        props.table.sortClicked(props.sortableProp);
    }
};
</script>
