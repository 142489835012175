import { defineStore } from 'pinia';

interface State {
    trayInUsePromise: Promise<void> | null
}

// This store is used to track whether the tray is in use. It is used to prevent the tray from being used by multiple
// components at once. This is necessary because the tray can only have a single open connection at a time.
// It is separate from the azlabelsTray store so that the useAzlabelsTray composable can use the azlabelsTray store
export const useAzlabelsTrayPromiseStore = defineStore('azlabelsTrayPromise', {
    state: (): State => ({
        trayInUsePromise: null,
    }),
    actions: {
        setTrayInUsePromise(value: Promise<void> | null): void {
            this.trayInUsePromise = value;
        },
    },
});
