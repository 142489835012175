import { serviceProviderUserMembershipInvitationInterface } from '../repositories/users';
import axios from '@/helpers/forms/axios';

const fetchServiceProviderPendingInvitations = async (): Promise<serviceProviderUserMembershipInvitationInterface[]> => {
    const response = await axios.get<{ data: serviceProviderUserMembershipInvitationInterface[] }>('/api/serviceProviders/invitations');

    return response.data.data;
};

const resendServiceProviderInvitation = async (token: string): Promise<void> => {
    await axios.get(`/api/users/invitations/serviceProvider/${token}/resend`);
};

const fetchPortal = async (): Promise<object> => {
    const response = await axios.get('/api/service-provider-billing/portal');

    return response.data.data?.portalSession;
};

export default {
    fetchServiceProviderPendingInvitations,
    resendServiceProviderInvitation,
    fetchPortal,
};
