import axios from '@/helpers/forms/axios';

export interface packageConfigurationInterface {
    id: number
    hash: string
    createdAt: string
    updatedAt: string
    companyId: number
    name: string
    type: string
    weightGm: number
    lengthMm: number
    widthMm: number
    heightMm: number
}

const fetchPackageConfigurations = async (): Promise<packageConfigurationInterface[]> => {
    const response = await axios.get<{ configurations: packageConfigurationInterface[] }>('/api/package-configurations');

    return response.data.configurations;
};

export default {
    fetchPackageConfigurations,
};
