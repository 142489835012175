import { orderInterface, orderQuoteSummaryInterface, packOrderInterface } from '../repositories/orders';
import axios from '@/helpers/forms/axios';

interface batchResponseInterface {
    id: string
    progress: number
    finishedAt: string
    failedJobs: number
}

const assignPacks = async (orders: orderInterface[]): Promise<void> => {
    return await axios.post('/api/orders/assignPacksBySelected', { orders });
};

const unassignPacks = async (orders: orderInterface[]): Promise<void> => {
    return await axios.post('/api/orders/unassignPacks', { orders });
};

const assignPackFromTote = async (toteCode: string): Promise<void> => {
    await axios.post('/api/orders/assignPackFromTote', { toteCode });
};

const generatePackingSlip = async (orderId: number): Promise<string> => {
    const response = await axios.get<{ url: string }>(`/api/orders/${orderId}/packingSlip`);

    return response.data.url;
};

const selectOrderService = async (orderId: number, objectId: string): Promise<orderQuoteSummaryInterface> => {
    const response = await axios.post<{ quotes: orderQuoteSummaryInterface }>(`/api/orders/${orderId}/selectService`, { objectId });

    return response.data.quotes;
};

const getShippingQuotes = async (orderId: number, refresh: boolean = false): Promise<orderQuoteSummaryInterface | null> => {
    let url = `/api/orders/${orderId}/getQuote`;
    if (refresh) {
        url += '?refresh=true';
    }
    const response = await axios.get<{ quotes: orderQuoteSummaryInterface | null }>(url);

    return response.data.quotes;
};

const updatePackOrders = async (orders: packOrderInterface[]): Promise<packOrderInterface[]> => {
    const response = await axios.post<{ orders: packOrderInterface[] }>('/api/orders/batch/index', { orders });

    return response.data.orders;
};

const purchaseLabels = async (orders: orderInterface[], printPackingSlips: boolean): Promise<string> => {
    const response = await axios.post<{ batchId: string }>('/api/orders/purchaseLabels', { orders, printPackingSlips });

    return response.data.batchId;
};

const purchaseLabelsBatchData = async (batchId: string): Promise<batchResponseInterface> => {
    const response = await axios.get<batchResponseInterface>(`/api/jobBatch/${batchId}`);

    return response.data;
};

const assignPacksByUser = async (ordersToPackQuantity: number): Promise<void> => {
    return await axios.post('/api/orders/assignPacksByUser', {
        ordersToPackQuantity,
    });
};

const assignPacksByPriority = async (ordersToPackQuantity: number): Promise<void> => {
    return await axios.post('/api/orders/assignPacksByPriority', {
        ordersToPackQuantity,
    });
};

const assignPacksByQuantity = async (ordersToPackQuantity: number): Promise<void> => {
    return await axios.post('/api/orders/assignPacksByQuantity', {
        ordersToPackQuantity,
    });
};

export default {
    assignPacks,
    unassignPacks,
    assignPackFromTote,
    generatePackingSlip,
    selectOrderService,
    getShippingQuotes,
    updatePackOrders,
    purchaseLabels,
    purchaseLabelsBatchData,
    assignPacksByUser,
    assignPacksByPriority,
    assignPacksByQuantity,
};
