<template>
    <Head :title="title ? `${headerName} - ${title}` : `${headerName} - Dashboard`">
        <slot />
    </Head>
</template>

<script lang="ts" setup>
import { Head } from '@inertiajs/vue3';
import { computed } from 'vue';
import { useNarrative } from '@/composables/useNarrative';

defineProps({
    title: String,
});
const { appName, currentServiceProviderOptional } = useNarrative();
const headerName = computed((): string => {
    if (currentServiceProviderOptional.value) {
        return currentServiceProviderOptional.value.name;
    }

    return appName.value;
});
</script>
