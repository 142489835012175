import { PusherPrivateChannel } from 'laravel-echo/dist/channel';
import useFormatters from '@/composables/useFormatters';

export interface UserWebsocketSubscriberCallback {
    (event: string, data: object): void
};

export interface UserWebsocketSubscriber {
    id: string
    callback: UserWebsocketSubscriberCallback
    onOneTab?: boolean
};

export class UserWebsocketListener {
    constructor(
        userWebsocketChannel: PusherPrivateChannel,
        defaultSubscriber?: UserWebsocketSubscriber,
    ) {
        if (defaultSubscriber) {
            this.subscribers.push(defaultSubscriber);
        }
        this.userWebsocketChannel = userWebsocketChannel;
        this.userWebsocketChannel.listenToAll(this.handleEvent);

        this.userWebsocketChannel.listenForWhisper('setAlreadyHandledActions', (handledActions: number[]) => {
            localStorage.setItem('alreadyHandledActions', JSON.stringify(handledActions));
        });
    }

    subscribers: UserWebsocketSubscriber[] = [];
    userWebsocketChannel: PusherPrivateChannel;

    addSubscriber = (subscriber: UserWebsocketSubscriber) => {
        this.subscribers.push(subscriber);
    };

    removeSubscriber = (id: string) => {
        this.subscribers = this.subscribers.filter(subscriber => subscriber.id !== id);
    };

    handleEvent = (event: string, data: object) => {
        if (event.includes('setAlreadyHandledActions')) return;

        const camelCaseData = useFormatters().keysToCamelCase(data);

        for (const subscriber of this.subscribers) {
            if (subscriber.onOneTab) {
                this.handleSingleTabSubscriber(subscriber, event, camelCaseData);

                continue;
            }

            subscriber.callback(event, camelCaseData);
        }
    };

    handleSingleTabSubscriber = (
        subscriber: UserWebsocketSubscriber,
        event: string,
        data: object,
        skipHidden: boolean = true,
    ) => {
        setTimeout(() => {
            if (this.checkNotificationAlreadyHandled(event, data)) {
                return;
            }
            if (skipHidden && document.visibilityState === 'hidden') {
                // If this is not the currently visible tab, wait for a bit and then retry handling
                // If the notification was already handled by a visible tab, if will stop
                // If the notification was not already handled, it will not check the visibility state and handle the action
                setTimeout(() => {
                    this.handleSingleTabSubscriber(subscriber, event, data, false);
                }, 500);

                return;
            }

            subscriber.callback(event, data);
        }, Math.floor(Math.random() * (50 - 10) + 10)); // Add a random pause to avoid race conditions
    };

    checkNotificationAlreadyHandled = (event: string, data: object): boolean => {
        const alreadyHandledActions = JSON.parse(localStorage.getItem('alreadyHandledActions') || '[]') as number[];

        const eventHash = useFormatters().cyrb53Hash(`${event}-${JSON.stringify(data)}`);

        if (alreadyHandledActions.includes(eventHash)) {
            return true;
        }

        if (alreadyHandledActions.length >= 5) {
            alreadyHandledActions.length = 6;
            alreadyHandledActions.shift();
        }
        alreadyHandledActions.push(eventHash);

        localStorage.setItem('alreadyHandledActions', JSON.stringify(alreadyHandledActions));

        this.userWebsocketChannel.whisper('setAlreadyHandledActions', alreadyHandledActions);

        return false;
    };
}
