<template>
    <div>
        <label v-if="label" :for="id" class="block text-sm font-medium text-gray-700">{{ label }}</label>
        <div class="rounded-md shadow-sm flex" :class="{ 'mt-1': label }">
            <div class="relative flex items-stretch flex-grow focus-within:z-10">
                <!-- Prefix -->
                <span
                    v-if="prefix"
                    class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                >{{ prefix }}</span>

                <!-- Icon -->
                <div
                    v-if="icon"
                    class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                    :class="prefix ? 'pl-20' : 'pl-3'"
                >
                    <component :is="icon" class="h-5 w-5 text-gray-400" />
                </div>

                <!-- Input -->
                <input
                    :id="id"
                    ref="inputElementRef"
                    :value="calculatedValue()"
                    :type="type"
                    :name="formProp ? formProp : name"
                    :class="inputClasses"
                    :placeholder="placeholder"
                    :autofocus="autofocus"
                    :required="required"
                    :step="step"
                    :disabled="disabled"
                    :autocomplete="autocomplete"
                    @input="handleInput(getValueFromEvent($event))"
                >

                <!-- Errors don't show if this field not present, weird Vue issue -->
                <span v-if="form && form.busy" class="hidden" />

                <!-- Error Icon -->
                <div
                    v-if="isForm && form.errors.has(formProp) && !onlyErrorBorder"
                    class="inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                    :class="prefix ? '-ml-8' : 'absolute'"
                >
                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
            </div>

            <!-- Suffix -->
            <span
                v-if="suffix"
                class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
            >{{ suffix }}</span>

            <!-- Weight Button -->
            <span
                v-if="weightFunc"
                class="hover:bg-gray-100 cursor-pointer group inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                :class="{ 'cursor-pointer': !weightBusy, 'cursor-wait': weightBusy }"
                @click="weightFunc"
            >
                <ScaleIcon
                    class="h-5 w-5 text-primary-300 group-hover:text-primary-500"
                    aria-hidden="false"
                />

                <div v-if="weightBusy" class="ml-1">
                    <base-spinner class="h-4 w-4" />
                </div>
            </span>
        </div>

        <!-- Error Text -->
        <p v-if="showErrorText" class="mt-1 text-sm text-red-600">
            <span v-if="error">{{ error }}</span>
            <span v-else>{{ form.errors.get(formProp) }}</span>
        </p>
    </div>
</template>

<script lang="ts" setup>
import { PropType, computed, ref } from 'vue';
import { ExclamationCircleIcon, ScaleIcon } from '@heroicons/vue/20/solid';
import get from 'lodash/get';
import { SparkFormType } from '../../../helpers/forms/form';

const props = defineProps({
    id: {
        type: String,
        default: () => {
            return `Input-${Math.floor(Math.random() * 100000)}${1}`;
        },
    },
    label: {
        type: String,
        default: null,
    },
    prefix: {
        type: String,
        default: null,
    },
    suffix: {
        type: String,
        default: null,
    },
    icon: {
        type: Function,
        default: null,
    },
    type: {
        type: String,
        default: 'text',
    },
    name: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: null,
    },
    autocomplete: {
        type: String,
        default: null,
    },
    form: {
        type: Object,
        validator: (prop: SparkFormType<any>) => Object.prototype.hasOwnProperty.call(prop, 'busy'),
        default: null,
    },
    formProp: {
        type: String,
        default: null,
    },
    weightFunc: {
        type: Function as PropType<(payload: MouseEvent) => void>,
        default: null,
    },
    weightBusy: {
        type: Boolean,
        default: false,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    error: {
        type: String,
        default: null,
    },
    value: {
        type: [String, Number],
        default: null,
    },
    onlyErrorBorder: {
        type: Boolean,
        default: false,
    },
    step: {
        type: [Number, String],
        default: 1,
    },
});

const model = defineModel();

const inputClasses = computed(() => {
    let classes = 'block w-full sm:text-sm shadow-sm';

    /* This line required to fix weird Vue issue, otherwise error classes don't show */
    if (props.form) {
        if (props.form.busy.value) {
            classes += '';
        };
    }

    if ((props.form && props.form.errors.has(props.formProp)) || props.error) {
        classes += ' border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';
        if (!props.onlyErrorBorder) {
            classes += ' pr-10';
        }
    } else {
        classes += ' border-gray-300 placeholder-gray-300 focus:ring-primary-500 focus:border-primary-500';
    }

    if (props.disabled) {
        classes += ' text-gray-300 cursor-not-allowed';
    }

    if (props.icon) {
        classes += ' pl-10';
    }
    if (props.suffix && props.prefix) {
        classes += ' flex-1 px-3 py-2 rounded-none';
    } else if (props.suffix) {
        classes += ' flex-1 px-3 py-2 rounded-none rounded-l-md';
    } else if (props.prefix) {
        classes += ' flex-1 px-3 py-2 rounded-none rounded-r-md';
    } else {
        // No prefix or suffix
        classes += ' rounded-md';
    }

    return classes;
});

const isForm = computed(() => {
    return Boolean(props.form && props.formProp);
});

const showErrorText = computed((): boolean => {
    if (props.onlyErrorBorder) {
        return false;
    }
    if (props.error) {
        return true;
    }
    if (isForm.value && props.form.errors.has(props.formProp)) {
        return true;
    }

    return false;
});

const calculatedValue = () => {
    if (isForm.value) {
        return get(props.form, props.formProp);
    } else if (props.value !== null) {
        return props.value;
    } else if (model.value !== null) {
        return model.value;
    }

    return null;
};

const handleInput = (value: any) => {
    if (isForm.value) {
        if (props.type === 'number') {
            value = Number.isNaN(Number.parseFloat(value)) ? 0 : Number.parseFloat(value);
        }

        props.form.setProperty(props.formProp, value);
    } else {
        model.value = value;
    }
};

const getValueFromEvent = (event: Event): string | number => {
    const targetValue = (event.target as HTMLInputElement).value;

    if (props.type === 'number') {
        return Number.parseFloat(targetValue);
    }

    return targetValue;
};

const inputElementRef = ref<HTMLInputElement>();

const focus = () => {
    inputElementRef.value?.focus();
};

defineExpose({
    focus,
});
</script>
