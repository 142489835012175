import axios from '@/helpers/forms/axios';
import { sendForm } from '@/helpers/forms/form';

export interface mailIntegrationInterface {
    id: string
    serviceProviderId: number
}

export interface mailgunMailIntegrationInterface extends mailIntegrationInterface {
    host: string
    domain: string
}

interface indexMailIntegrationsResponseInterface {
    mailgun: mailgunMailIntegrationInterface | null
}

export const fetchIntegrations = async (): Promise<indexMailIntegrationsResponseInterface> => {
    const response = await axios.get<indexMailIntegrationsResponseInterface>('/api/notifications/serviceProviders/mailIntegrations');

    return response.data;
};

export interface createMailgunMailIntegrationFormInterface {
    host: string
    secret: string
    domain: string
}

export const createMailgunIntegration = async (form: createMailgunMailIntegrationFormInterface): Promise<void> => {
    return sendForm({
        method: 'POST',
        uri: '/api/notifications/serviceProviders/mailIntegrations/mailgun',
        form,
    });
};

export const deleteIntegration = async (integrationId: string): Promise<void> => {
    return axios.delete(`/api/notifications/serviceProviders/mailIntegrations/${integrationId}`);
};

export const sendTestEmail = async (): Promise<void> => {
    return axios.post('/api/notifications/serviceProviders/mailIntegrations/test');
};

export default {
    fetchIntegrations,
    createMailgunIntegration,
    deleteIntegration,
    sendTestEmail,
};
