import { defineStore } from 'pinia';

export interface intercomWidgetSettings {
    appId: string
    userId: number
    userName: string
    userEmail: string
    identityHash: string
    merchant?: {
        id: number
        name: string
    }
    merchants?: {
        id: number
        name: string
    }[]
}

const formatSettings = (given: intercomWidgetSettings) => {
    const settings: any = {
        app_id: given.appId,
        user_id: String(given.userId),
        user_hash: given.identityHash,
        name: given.userName,
        email: given.userEmail,
    };
    if (given.merchant) {
        settings.company = {
            company_id: String(given.merchant.id),
            name: given.merchant.name,
        };
    }

    if (given.merchants) {
        settings.companies = [];
        given.merchants.forEach((merchant) => {
            settings.companies.push({
                company_id: String(merchant.id),
                name: merchant.name,
            });
        });
    }

    return settings;
};

export const useIntercomWidgetStore = defineStore('intercomWidget', {
    state: () => ({
        installed: false,
        booted: false,
    }),
    actions: {
        install(appId: string) {
            if (this.installed) {
                console.warn('Intercom widget already installed');
                return;
            }

            // THIS CODE IS PROVIDED BY INTERCOM
            /* eslint-disable prefer-rest-params */

            (function () {
                const w: any = window;
                const ic = w.Intercom;

                if (typeof ic === 'function') {
                    ic('reattach_activator');
                    ic('update', w.intercomSettings);
                } else {
                    const d = document;

                    const i = function () {
                        (<any>i).c(arguments); // ignore
                    };

                    (<any>i).q = [];

                    (<any>i).c = function (args: any) {
                        (<any>i).q.push(args);
                    };

                    w.Intercom = i;

                    const l = function () {
                        const s = d.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = `https://widget.intercom.io/widget/${appId}`;
                        const x = d.getElementsByTagName('script')[0];
                        (<any>x).parentNode.insertBefore(s, x);
                    };

                    if (document.readyState === 'complete') {
                        l();
                    } else if (w.attachEvent) {
                        w.attachEvent('onload', l);
                    } else {
                        w.addEventListener('load', l, false);
                    }
                }
            })();

            /* eslint-enable */

            this.installed = true;
        },
        boot(settings: intercomWidgetSettings) {
            (<any>window).Intercom('boot', formatSettings(settings));
            this.booted = true;
        },
        update(settings: intercomWidgetSettings) {
            (<any>window).Intercom('update', formatSettings(settings));
        },
        shutdown() {
            if (!this.booted) {
                return;
            }
            (<any>window).Intercom('shutdown');
            this.booted = false;
        },
    },
});
