import { UnwrapRef } from 'vue';
import axios from '@/helpers/forms/axios';
import { SparkFormType, sendForm } from '@/helpers/forms/form';

export interface webhookInterface {
    id: number
    companyId: number
    url: string
    types: {
        [key: string]: {
            [key: string]: boolean
        }
    }
    secret: string
}

interface fetchWebhooksResponse {
    webhooks: webhookInterface[]
    types: string[]
}

const fetchServiceProviderWebhooks = async (): Promise<fetchWebhooksResponse> => {
    const response = await axios.get<{
        webhooks: webhookInterface[]
        types: string[]
    }>('/api/webhooks');

    return response.data;
};

const fetchMerchantWebhooks = async (merchantId: number): Promise<fetchWebhooksResponse> => {
    const response = await axios.get<fetchWebhooksResponse>(`/api/merchants/${merchantId}/webhooks`);

    return response.data;
};

const createServiceProviderWebhook = async (form: UnwrapRef<SparkFormType<object>>): Promise<webhookInterface> => {
    const response = await sendForm({
        method: 'post',
        uri: '/api/webhooks',
        form,
    });

    return response.webhook;
};

const createMerchantWebhook = async (merchantId: number, form: UnwrapRef<SparkFormType<object>>): Promise<webhookInterface> => {
    const response = await sendForm({
        method: 'post',
        uri: `/api/merchants/${merchantId}/webhooks`,
        form,
    });

    return response.webhook;
};

const updateWebhook = async (webhookId: number, form: UnwrapRef<SparkFormType<object>>): Promise<webhookInterface> => {
    const response = await sendForm({
        method: 'put',
        uri: `/api/webhooks/${webhookId}`,
        form,
    });

    return response.webhook;
};

const deleteWebhook = async (webhookId: number): Promise<void> => {
    await axios.delete(`/api/webhooks/${webhookId}`);
};

export default {
    fetchServiceProviderWebhooks,
    fetchMerchantWebhooks,
    createServiceProviderWebhook,
    createMerchantWebhook,
    updateWebhook,
    deleteWebhook,
};
