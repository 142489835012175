import { itemInterface } from './items';
import axios from '@/helpers/forms/axios';

export interface moveInterface {
    id: number
    createdAt: string
    updatedAt: string
    sourcedAt: string
    sourcedByUserId: number
    arrivedAt: string
    arrivedByUserId: number
    item: itemInterface
    itemId: number
    arrivable: object
    arrivableId: number
    arrivableType: string
    sourceable: object
    sourceableId: number
    sourceableType: string
    quantity: number
    status: string
    notes: string
}

export const saveMove = async (moveId: number, quantity: number): Promise<moveInterface[]> => {
    const response = await axios.patch<{ move: moveInterface[] }>(`/api/moves/${moveId}`, {
        quantity,
    });

    return response.data.move;
};

export const deleteMove = async (moveId: number): Promise<boolean> => {
    await axios.delete(`/api/moves/${moveId}`);
    return true;
};
