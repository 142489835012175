import { computed, reactive, ref } from 'vue';
import get from 'lodash/get';
import useSounds from './useSounds';
import usePopupNotifications from './usePopupNotifications';
import useAppEntitlements from './useAppEntitlements';
import { useNarrative } from './useNarrative';
import { useBarcodeScannerStore } from '@/stores/barcodeScanner';
import { AppTable } from '@/helpers/tables';
import useInertia from '@/composables/useInertia';
import API from '@/api';
import { pickInterface, pickSettingsInterface } from '@/api/repositories/orders';
import { orderItemInterface } from '@/api/repositories/orderItems';

export enum ScannerType {
    Location = 'pickLocation',
    Item = 'pickItem',
    Tote = 'pickTote',
}

export interface scannerOptions {
    title: string
    canSkip: boolean
    tip: string
    totalItems: number
    currentItem: number
    pick: pickInterface
    orderItem: orderItemInterface
    type: ScannerType
    usingCamera: boolean
}

export default function usePickOrders() {
    const barcodeScannerStore = useBarcodeScannerStore();
    const { currentServiceProvider } = useNarrative();
    if (!currentServiceProvider.value) throw new Error('Service Provider is required');

    const { hasLocationsEntitlement } = useAppEntitlements();
    const { notifySuccess, notifyError } = usePopupNotifications();

    const picksTable = reactive(new AppTable<pickInterface>([]));
    const picksTableItems = computed((): pickInterface[] => picksTable.items ?? []);
    const pickSettings = ref<pickSettingsInterface>();
    const currentScanType = ref<ScannerType>(hasLocationsEntitlement.value ? ScannerType.Location : ScannerType.Item);
    const initialPickCount = ref(0);
    const currentPickNumber = ref(1);
    const scanning = ref(false);
    const printingPickList = ref(false);
    const markingAsPicked = ref(false);
    const processingCode = ref(false);
    const usingCamera = ref(false);

    const currentPick = computed((): pickInterface | undefined => {
        return picksTableItems.value.find((pickItem) => {
            return pickItem.orders.some((order) => {
                return order.orderItems.some(orderItem => !orderItem.pickedAt);
            });
        });
    });

    const currentOrderItem = computed((): orderItemInterface | undefined => {
        const unpickedOrder = currentPick.value?.orders.find((order) => {
            return order.orderItems.some(orderItem => !orderItem.pickedAt);
        });

        if (!unpickedOrder) {
            return undefined;
        }

        return unpickedOrder.orderItems.find(orderItem => !orderItem.pickedAt);
    });

    const orderItemRemainingQuantity = (orderItem: orderItemInterface): number => {
        return orderItem.quantity - (orderItem.pickData?.quantityPicked ?? 0);
    };

    const pickRequiredQuantity = (pick: pickInterface): number => {
        return pick.orders.reduce((total, order) => {
            return total += order.orderItems.reduce((orderItemQuantity, orderItem) => {
                return orderItemQuantity += orderItem.quantity;
            }, 0);
        }, 0);
    };

    const pickRemainingQuantity = (pick: pickInterface): number => {
        return pick.orders.reduce((total, order) => {
            return total += order.orderItems.reduce((orderItemQuantity, orderItem) => {
                return orderItemQuantity += orderItemRemainingQuantity(orderItem);
            }, 0);
        }, 0);
    };

    const currentUnitNumber = computed(() => {
        if (!currentPick.value) return 1;

        return (pickRequiredQuantity(currentPick.value) - pickRemainingQuantity(currentPick.value)) + 1;
    });

    const itemScanType = computed((): string => {
        if (!currentOrderItem.value) return '';

        if (currentOrderItem.value.pickData && currentOrderItem.value.pickData.toteCode) {
            return `Scan the tote for this order: ${currentOrderItem.value.pickData.toteCode}`;
        } else {
            return 'Scan any tote to assign it to this order';
        }
    });

    const canSkipItemScan = computed((): boolean => {
        let canSkip = true;
        const skipSetting = pickSettings.value?.itemScan || 'optional';
        if (skipSetting === 'required') {
            canSkip = false;
        }
        if (skipSetting === 'required_first_pick' && currentUnitNumber.value === 1) {
            canSkip = false;
        }

        return canSkip;
    });

    const currentIdentifiers = computed((): string[] => {
        if (!currentPick.value) return [];

        return currentPick.value.item.identifiers
            .filter(identifier => ['EAN', 'UPC'].includes(identifier.identifierType))
            .map(identifier => identifier.identifier);
    });

    const totalUnits = computed(() => picksTableItems.value.reduce((total, pick) => { return total + pickRequiredQuantity(pick); }, 0));

    const statusColour = (status: string) => {
        let colour = 'indigo';

        switch (status) {
            case 'pending':
                colour = 'red';
                break;
            case 'in_progress':
                colour = 'indigo';
                break;
            case 'picked':
                colour = 'green';
                break;
        }

        return `bg-${colour}-100 text-${colour}-800`;
    };

    const loadPicks = async () => {
        const response = await API.repositories.orders.fetchPickOrders();

        picksTable.items = response.picks;
        initialPickCount.value = picksTable.items.length;
        currentPickNumber.value = 1;
        pickSettings.value = response.pickSettings;
    };

    const printPickList = async () => {
        printingPickList.value = true;
        API.orders.picks.printPickList().then((url) => {
            printingPickList.value = false;

            window.open(url, '_blank');
        });
    };

    const markAsPicked = (requestPicks: pickInterface[]) => {
        markingAsPicked.value = true;

        API.orders.picks.markAsPicked(requestPicks).then((responsePicks) => {
            markingAsPicked.value = false;
            picksTable.items = responsePicks;

            if (!picksTable.items.length) {
                useInertia().visit('/staff/orders');
            }
        });
    };

    const handleInvalidPickState = () => {
        notifyError('Invalid state for picking, please report this error to support.');
    };

    const showScanner = (options: Partial<scannerOptions>) => {
        if (!currentPick.value || !currentOrderItem.value) {
            return handleInvalidPickState();
        }

        processingCode.value = false;
        scanning.value = true;

        const defaultOptions: scannerOptions = {
            title: 'Scan',
            canSkip: false,
            tip: '',
            totalItems: initialPickCount.value,
            currentItem: currentPickNumber.value,
            pick: currentPick.value,
            orderItem: currentOrderItem.value,
            type: currentScanType.value,
            usingCamera: usingCamera.value,
        };

        barcodeScannerStore.setScannerOptions(Object.assign(defaultOptions, options));
        barcodeScannerStore.setOpen(true);
        barcodeScannerStore.setLoading(false);
    };

    const updateOrderToteCode = (orderId: number, toteCode: string) => {
        picksTable.items = picksTable.items.map((pick) => {
            pick.orders = pick.orders.map((order) => {
                if (order.id !== orderId) {
                    return order;
                }

                order.toteCode = toteCode;

                order.orderItems = order.orderItems.map((orderItem) => {
                    if (!orderItem.pickData) {
                        orderItem.pickData = {};
                    }

                    orderItem.pickData.toteCode = toteCode;

                    return orderItem;
                });
                return order;
            });
            return pick;
        });
    };

    const completePicks = () => {
        barcodeScannerStore.setClosed();

        notifySuccess('Picks completed for all items.');

        useInertia().visit('/staff/orders');
    };

    const scanItem = () => {
        if (!currentPick.value) return handleInvalidPickState();

        currentScanType.value = ScannerType.Item;

        showScanner({
            title: `Scan Item (${currentUnitNumber.value} of ${pickRequiredQuantity(currentPick.value)})`,
            canSkip: canSkipItemScan.value,
            tip: `Scan Item Barcode: ${currentIdentifiers.value.join(', ')}`,
        });
    };

    const scanTote = () => {
        currentScanType.value = ScannerType.Tote;

        showScanner({
            title: 'Scan Tote',
            canSkip: false,
            tip: itemScanType.value,
        });
    };

    const canSkipLocationScan = get(pickSettings.value, 'location_scan', 'optional') === 'optional';

    const scanLocation = () => {
        if (!currentPick.value) return handleInvalidPickState();

        if (!currentPick.value.locations.length) {
            scanItem();

            return;
        }

        currentScanType.value = ScannerType.Location;

        showScanner({
            title: 'Scan Location',
            canSkip: canSkipLocationScan,
            tip: `Scan item location to verify: ${currentPick?.value.locations[0].code}`,
        });
    };

    const handleCurrentPickLastScan = () => {
        picksTable.items.splice(0, 1);

        if (!picksTable.items.length) {
            completePicks();

            return;
        }

        currentPickNumber.value++;

        if (hasLocationsEntitlement.value) {
            scanLocation();

            return;
        }

        scanItem();
    };

    const nextScan = (): void => {
        if (!currentPick.value) {
            return completePicks();
        }

        switch (currentScanType.value) {
            case ScannerType.Location: {
                return scanItem();
            }
            case ScannerType.Item: {
                return scanTote();
            }
            case ScannerType.Tote: {
                if (pickRemainingQuantity(currentPick.value) === 0) {
                    return handleCurrentPickLastScan();
                }

                return scanItem();
            }
            default:
        }
    };

    const rescan = (): void => {
        switch (currentScanType.value) {
            case ScannerType.Location: {
                return scanLocation();
            }
            case ScannerType.Item: {
                return scanItem();
            }
            case ScannerType.Tote: {
                return scanTote();
            }
            default:
        }
    };

    const getPickData = (code: string | null, skipped: boolean) => {
        const data = {
            type: currentScanType.value,
            skipped,
            code: null as string | null,
        };

        if (!skipped) {
            data.code = code;
        }

        return data;
    };

    const addPickData = (code: string | null, skipped: boolean) => {
        if (!currentOrderItem.value) return handleInvalidPickState();

        barcodeScannerStore.setLoading(true);

        const data = getPickData(code, skipped);

        API.orders.picks.updatePickData(currentOrderItem.value.id, data).then((pickData) => {
            const pickIndex = picksTableItems.value.findIndex(item => item.id === currentPick.value!.id);
            const orderIndex = picksTableItems.value[pickIndex].orders.findIndex(order => order.id === currentOrderItem.value?.orderId);
            if (orderIndex === -1) return;

            const orderItemIndex = picksTableItems.value[pickIndex].orders[orderIndex].orderItems.findIndex(orderItem => orderItem.id === currentOrderItem.value?.id);

            // Update pick data
            if (currentScanType.value === ScannerType.Tote && code) {
                updateOrderToteCode(
                    picksTableItems.value[pickIndex].orders[orderIndex].id,
                    code,
                );
            }

            picksTableItems.value[pickIndex].orders[orderIndex].orderItems[orderItemIndex].pickData = pickData;
            const remainingQuantity = orderItemRemainingQuantity(picksTableItems.value[pickIndex].orders[orderIndex].orderItems[orderItemIndex]);
            if (currentScanType.value === ScannerType.Tote && remainingQuantity <= 0) {
                picksTableItems.value[pickIndex].orders[orderIndex].orderItems[orderItemIndex].pickedAt = Date.now().toString();
            }

            nextScan();
        }).catch(() => {
            barcodeScannerStore.setLoading(false);
            rescan();
        });
    };

    const startScan = () => {
        if (!picksTable.items.length) {
            useInertia().visit('/staff/orders');

            return;
        }

        if (hasLocationsEntitlement.value) {
            scanLocation();
        } else {
            scanItem();
        }
    };

    const startScanWithCamera = () => {
        usingCamera.value = true;

        startScan();
    };

    const startScanWithoutCamera = () => {
        usingCamera.value = false;

        startScan();
    };

    barcodeScannerStore.$onAction(({ name, after }) => {
        after(() => {
            if (name === 'setCorrectBarcodeScan' && barcodeScannerStore.correctBarcodeScan !== '') {
                if (!processingCode.value) {
                    processingCode.value = true;
                    useSounds(currentServiceProvider.value.viewSettings.sounds?.enabled ?? true).playConfirmationSound();
                    addPickData(barcodeScannerStore.correctBarcodeScan, false);
                }

                barcodeScannerStore.setCorrectBarcodeScan('');
            }
            if (name === 'setScanSkipped' && barcodeScannerStore.scanSkipped !== false) {
                barcodeScannerStore.setScanSkipped(false);
                if (!processingCode.value) {
                    processingCode.value = true;
                    addPickData(null, true);
                }
            }
        });
    });

    return {
        picksTable,
        loadPicks,
        startScanWithCamera,
        startScanWithoutCamera,
        printPickList,
        printingPickList,
        statusColour,
        markAsPicked,
        markingAsPicked,
        totalUnits,
        addPickData,
        orderItemRemainingQuantity,
        pickRemainingQuantity,
        pickRequiredQuantity,
    };
};
