<template>
    <td
        :class="tdClasses"
        v-bind="$attrs"
        @click="handleClick"
    >
        <BaseLink
            v-if="href"
            :href="href"
            @click.prevent="() => {}"
        >
            <slot />
        </BaseLink>

        <slot v-if="!href" />
    </td>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useKeyModifier } from '@vueuse/core';
import BaseLink from '../BaseLink.vue';
import useInertia from '@/composables/useInertia';

const props = defineProps({
    padding: {
        type: String,
        validator: (prop: string) => ['narrow', 'dense'].includes(prop),
        default: null,
    },
    class: {
        type: String,
        default: null,
    },
    href: {
        type: String,
        default: null,
    },
    clickFunc: {
        type: Function,
        default: null,
    },
});

const tdClasses = computed(() => {
    let classes = 'text-sm font-medium text-gray-900 first:pl-6 last:pr-6';
    classes += props.href ? ' table-cell align-middle' : '';
    classes += props.padding ? ' px-1 py-1 first:pl-4 last:pr-4' : ' px-6 py-3';
    classes += (props.class ? ` ${props.class}` : '');
    return classes;
});

const controlKeyState = useKeyModifier('Control');
const metaKeyState = useKeyModifier('Meta');

const handleClick = () => {
    if (props.clickFunc) {
        props.clickFunc();
    }
    if (props.href) {
        if (controlKeyState.value || metaKeyState.value) {
            window.open(props.href, '_blank');

            return;
        }

        useInertia().visit(props.href);
    }
};
</script>
