import dayjs from 'dayjs';
import { firstBy } from 'thenby';
import { ComputedRef, Ref, computed, reactive, ref } from 'vue';
import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/vue/20/solid';
import get from 'lodash/get';
import remove from 'lodash/remove';

export interface filterInterface {
    type: 'date' | 'text' | 'select' | 'request' | 'number'
    column?: string
    columns?: string[]
    label?: string
    value?: string
    from?: string
    to?: string
    requestFilter?: boolean
}

export interface sortColumnInterface {
    column: string
    order: 'asc' | 'desc'
    customOrder?: Array<string>
}

export interface tableItemInterface {
    id: number | string
    checked?: boolean
    [name: string]: any
}

export class AppTable<T> {
    constructor(data: Array<tableItemInterface & T>, filters: Array<filterInterface> = [], sortColumns: Array<sortColumnInterface> = []) {
        this.items.value = data;

        filters.forEach(filter => this.filters.push(filter));
        if (sortColumns.length) {
            sortColumns.forEach(sort => this.sortColumns.push(sort));
        }
    }

    items = ref<Array<tableItemInterface & T>>([]) as Ref<Array<tableItemInterface & T>>; // Must be cast to avoid UnwrapRefSimple error: https://github.com/vuejs/core/issues/2136

    pageLength = 20;
    pageNumber = ref(0);
    sortColumns = reactive<sortColumnInterface[]>([]);
    filterSearch = ref('');
    filterSearchColumns: string[] = [];
    filterOpen = ref(false);
    filters = reactive<filterInterface[]>([]);
    checkedItems = reactive<Array<tableItemInterface & T>>([]) as Array<tableItemInterface & T>;
    itemsLength = computed(() => { return this.items.value.length; });
    itemsLoading = ref(false);

    setItems = (items: Array<tableItemInterface & T>) => {
        this.items.value = items;
    };

    pushItems = (items: Array<tableItemInterface & T>) => {
        items.forEach((newItem) => {
            const index = this.items.value.findIndex(existingItem => existingItem.id === newItem.id);
            if (index !== -1) {
                this.items.value.splice(index, 1, newItem);
            } else {
                this.items.value.push(newItem);
            }
        });
    };

    removeItem = (item: tableItemInterface & T) => {
        const itemIndex = this.items.value.findIndex(findItem => findItem.id === item.id);

        this.items.value.splice(itemIndex, 1);
    };

    filtersSet = computed(() => {
        for (let i = 0; i < this.filters.length; i++) {
            if (this.filters[i].value) {
                return true;
            }
            if (this.filters[i].from) {
                return true;
            }
            if (this.filters[i].to) {
                return true;
            }
        }
        return false;
    });

    filteredItems = computed(() => {
        return this.items.value.filter((item) => {
            if (!this.filtersSet.value) {
                // If no specific filters are set
                if (!this.filterSearch.value) {
                    // and no general filter is set, the item should display
                    return true;
                }
                // If a general filter is set, show the item if any of the columns match
                return this.filterFromSearch(item);
            }
            // Check specific filters
            return this.filter(item);
        });
    });

    currentItems: ComputedRef<Array<tableItemInterface & T>> = computed((): Array<tableItemInterface & T> => {
        let sortStack = firstBy<any>(() => 0);
        for (let i = 0; i < this.sortColumns.length; i++) {
            if (this.sortColumns[i].customOrder?.length) {
                sortStack = sortStack.thenBy(this.sortColumns[i].column, {
                    cmp: this.sortByCustomOrder(this.sortColumns[i].customOrder!),
                    direction: this.sortColumns[i].order,
                });
            } else {
                const columnName = this.sortColumns[i].column;

                sortStack = sortStack.thenBy((item) => {
                    return get(item, columnName);
                }, this.sortColumns[i].order);
            }
        }

        return this.filteredItems.value.sort(sortStack).slice(this.pageStart.value, this.pageEnd.value);
    });

    pageStart = computed((): number => {
        return this.pageNumber.value * this.pageLength;
    });

    pageEnd = computed((): number => {
        return this.pageStart.value + this.pageLength;
    });

    pageCount = computed((): number => {
        const l = this.filteredItems.value.length - 1;
        const s = this.pageLength;
        return Math.floor(l / s);
    });

    allSelected = computed((): boolean => {
        const checkedItemsCount = this.checkedItems.length;

        if (checkedItemsCount >= this.filteredItems.value.length) {
            return true;
        }
        return false;
    });

    currentItemStart = computed((): number => {
        if (!this.currentItems.value.length) {
            return 0;
        }
        return (this.pageNumber.value * this.pageLength) + 1;
    });

    currentItemEnd = computed(() => {
        const maxSize = (this.pageNumber.value + 1) * this.pageLength;
        return Math.min(this.filteredItems.value.length, maxSize);
    });

    sortByCustomOrder = (sorts: Array<string>) => {
        return (a: string, b: string) => {
            return sorts.indexOf(a) - sorts.indexOf(b);
        };
    };

    pageNumberPos = (index: number): number => {
        const pos = this.pageNumber.value + index;

        if (pos < 0) {
            return -1;
        }
        if (pos > this.pageCount.value) {
            return -1;
        }

        return pos;
    };

    pageNumberClick = (index: number) => {
        const pos = this.pageNumberPos(index);
        if (pos !== -1) {
            this.pageNumber.value = pos;
        }
    };

    selectAll = () => {
        if (this.allSelected.value) {
            this.clearCheckedItems();
        } else {
            this.clearCheckedItems();

            this.filteredItems.value.forEach((item) => {
                this.checkItem(item);
            });
        }
    };

    sortClicked = (column: string) => {
        if (this.sortColumns[0].column === column) {
            this.sortColumns[0].order = this.sortColumns[0].order === 'asc' ? 'desc' : 'asc';

            return;
        }

        remove(this.sortColumns, (item) => {
            return item.column === column;
        });

        this.sortColumns.unshift({
            column,
            order: 'asc',
            customOrder: [],
        });

        this.sortColumns.splice(2);
    };

    sortIcon = (column: string) => {
        const index = this.sortColumns.findIndex(item => item.column === column);

        if (index > -1) {
            return this.sortColumns[index].order === 'asc' ? BarsArrowUpIcon : BarsArrowDownIcon;
        }
        return BarsArrowUpIcon;
    };

    sortColour = (column: string) => {
        const index = this.sortColumns.findIndex(item => item.column === column);

        if (index === 1) {
            return 'text-primary-200';
        } else if (index === 0) {
            return 'text-primary-500';
        } else {
            return 'text-gray-400';
        }
    };

    /**
     * Filter functions
     */
    filter = (item: tableItemInterface & T) => {
        const check = true;
        for (let i = 0; i < this.filters.length; i++) {
            // Go through each filter
            if (this.filterSet(this.filters[i])) {
                // and if that filter has a value
                if (!this.filterType(item, this.filters[i])) {
                    // Filter out any items that don't match that filter
                    return false;
                };
            }
        }

        return check;
    };

    filterType = (item: tableItemInterface & T, filter: filterInterface): boolean => {
        switch (filter.type) {
            case 'date':
                if (filter.from || filter.to) {
                    if (filter.column) {
                        return this.filterDate(item[filter.column], filter.from, filter.to);
                    } else if (filter.columns) {
                        return filter.columns.some(column => this.filterDate(item[column], filter.from, filter.to));
                    }
                }
                break;
            case 'number':
                if (filter.from || filter.to) {
                    if (filter.column) {
                        return this.filterNumber(item[filter.column], Number.parseInt(filter.from ?? ''), Number.parseInt(filter.to ?? ''));
                    } else if (filter.columns) {
                        return filter.columns.some(column => this.filterNumber(item[column], Number.parseInt(filter.from ?? ''), Number.parseInt(filter.to ?? '')));
                    }
                }
                break;
            case 'text':
                if (filter.value) {
                    const filterValue = filter.value;

                    if (filter.column) {
                        return this.filterText(item[filter.column], filterValue);
                    } else if (filter.columns) {
                        return filter.columns.some(column => this.filterText(item[column], filterValue));
                    }
                }
                break;
            case 'select':
                if (filter.value) {
                    const filterValue = filter.value;

                    if (filter.column) {
                        return this.filterSelect(item[filter.column], filterValue);
                    } else if (filter.columns) {
                        return filter.columns.some(column => this.filterSelect(item[column], filterValue));
                    }
                }
                break;
        }

        return false;
    };

    filterNumber = (value: number, from?: number, to?: number): boolean => {
        if (from) {
            if (value < from) {
                return false;
            }
        }
        if (to) {
            if (value > to) {
                return false;
            }
        }

        return true;
    };

    filterDate = (dateString: string, from?: string, to?: string) => {
        const date = dayjs(dateString).set('hour', 12);
        if (from) {
            const fromDate = dayjs(from).startOf('day');
            if (date.isBefore(fromDate)) {
                return false;
            }
        }
        if (to) {
            const toDate = dayjs(to).endOf('day');
            if (date.isAfter(toDate)) {
                return false;
            }
        }

        return true;
    };

    filterText = (text: string, value: string) => {
        return text.toLowerCase().includes(value.toLowerCase());
    };

    filterSelect = (columnValue: string | string[], selectedValue: string): boolean => {
        if (Array.isArray(columnValue)) {
            return columnValue.some(value => selectedValue.toLowerCase() === value.toLowerCase());
        }

        return columnValue.toLowerCase() === selectedValue.toLowerCase();
    };

    updateFilterSearch = (value: string) => {
        this.filterSearch.value = value;
    };

    updateFilterInput = (column: string, prop: 'value' | 'from' | 'to', value: string) => {
        for (let i = 0; i < this.filters.length; i++) {
            if (this.filters[i].column === column) {
                this.filters[i][prop] = value;
            };
        }
    };

    toggleFilterOpen = () => {
        this.filterOpen.value = !this.filterOpen.value;
    };

    filterFromSearch = (item: tableItemInterface & T): boolean => {
        for (let i = 0; i < this.filterSearchColumns.length; i++) {
            if (get(item, this.filterSearchColumns[i]) && get(item, this.filterSearchColumns[i]).toString().toLowerCase().includes(this.filterSearch.value.toLowerCase())) {
                return true;
            }
        }
        return false;
    };

    filterInputs = (type: 'date' | 'text' | 'select' | 'number') => {
        return this.filters
            .filter(filter => filter.label)
            .filter(filter => filter.type === type);
    };

    selectFilterOptions = (filter: filterInterface): Set<string> => {
        const options: Set<string> = new Set();
        const possibleOptions: Array<string | string[]> = [];
        for (let i = 0; i < this.items.value.length; i++) {
            if (filter.column) {
                possibleOptions.push(this.items.value[i][filter.column]);
            }
            if (filter.columns) {
                filter.columns.forEach((column) => {
                    possibleOptions.push(this.items.value[i][column]);
                });
            }
        }

        possibleOptions.forEach((option) => {
            if (Array.isArray(option)) {
                return option.forEach(addOption => options.add(addOption));
            }

            return options.add(option);
        });

        return options;
    };

    filterSet = (filter: filterInterface) => {
        if (filter.value) {
            return true;
        }
        if (filter.from) {
            return true;
        }
        if (filter.to) {
            return true;
        }
        return false;
    };

    clearFilters = () => {
        for (let i = 0; i < this.filters.length; i++) {
            this.filters[i].value = '';
            this.filters[i].from = '';
            this.filters[i].to = '';
        }
        this.filterSearch.value = '';
    };

    /**
     * Checkbox functions
     */
    checked = (item: tableItemInterface & T): boolean => {
        return this.checkedItems.some(checkedItem => checkedItem.id === item.id);
    };

    toggleCheckbox = (item: tableItemInterface & T) => {
        if (this.checked(item)) {
            this.uncheckItem(item);
        } else {
            this.checkItem(item);
        }
    };

    checkItem = (item: tableItemInterface & T) => {
        if (!this.checked(item)) {
            this.checkedItems.push(item);
        }
    };

    uncheckItem = (item: tableItemInterface & T) => {
        const itemIndex = this.checkedItems.findIndex(checkedItem => checkedItem.id === item.id);

        if (itemIndex !== -1) {
            this.checkedItems.splice(itemIndex, 1);
        }
    };

    clearCheckedItems = () => {
        this.checkedItems.splice(0, this.checkedItems.length);
    };

    setPageNumber = (number: number) => {
        this.pageNumber.value = number;
    };
}
