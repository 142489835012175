import { UnwrapRef } from 'vue';
import { MerchantUserRole, merchantInterface } from './merchants';
import { ServiceProviderUserRole, serviceProviderInterface } from './serviceProviders';
import { availableToursEnum } from '@/composables/useUserOnboarding';
import axios from '@/helpers/forms/axios';
import { SparkFormType, sendForm } from '@/helpers/forms/form';

export type userOnboardingInterface = Record<availableToursEnum, number | 'complete' | 'cancelled'>;

export interface merchantWithRolesInterface extends merchantInterface {
    roles: MerchantUserRole[]
}

export interface serviceProviderWithRolesInterface {
    id: number
    name: string
    url: string
    faviconFile: string
    roles: ServiceProviderUserRole[]
}

export interface userInterface {
    id: number
    createdAt: string
    name: string
    email: string
    photoUrl: string
    phone: string
    onboarding?: userOnboardingInterface
}

export interface userWithRolesInterface extends userInterface {
    roles: Array<ServiceProviderUserRole | MerchantUserRole>
}

export interface userMembershipInvitationInterface {
    id: number
    createdAt: string
    email: string
    token: string
}

export interface merchantUserMembershipInvitationInterface extends userMembershipInvitationInterface {
    role: MerchantUserRole
    merchant: merchantInterface
}

export interface serviceProviderUserMembershipInvitationInterface extends userMembershipInvitationInterface {
    role: ServiceProviderUserRole
    serviceProvider: serviceProviderInterface
}

export interface apiTokenInterface {
    id: string
    name: string
    createdAt: string
    lastUsedAt?: string
    token: string
}

export interface createApiTokenFormInterface {
    name: string
}

async function fetchCurrentUser(): Promise<userInterface> {
    const response = await axios.get<{ data: userInterface }>('/api/users/current');

    return response.data.data;
}

async function inviteUserToServiceProvider(email: string, serviceProviderId: number, role: ServiceProviderUserRole): Promise<void> {
    return await axios.post('/api/users/invitations/serviceProvider', {
        email,
        serviceProviderId,
        role,
    });
}

async function acceptServiceProviderInvitation(token: string): Promise<void> {
    return await axios.get(`/api/users/invitations/serviceProvider/${token}/accept`);
}

async function declineServiceProviderInvitation(token: string): Promise<void> {
    return await axios.get(`/api/users/invitations/serviceProvider/${token}/decline`);
}

async function unassignUserRoleForServiceProvider(userId: number, role: ServiceProviderUserRole): Promise<void> {
    await axios.post(`/api/users/${userId}/roles/serviceProvider/unassign`, {
        role,
    });
}

async function editUserRoleForServiceProvider(userId: number, role: ServiceProviderUserRole): Promise<void> {
    await axios.put(`/api/users/${userId}/roles/serviceProvider`, {
        role,
    });
}

async function fetchAccountUsers(): Promise<userWithRolesInterface[]> {
    const response = await axios.get<userWithRolesInterface[]>('/api/users');
    return response.data;
}

async function inviteUserToMerchant(email: string, merchantId: number, role: MerchantUserRole): Promise<void> {
    return await axios.post('/api/users/invitations/merchant', {
        email,
        merchantId,
        role,
    });
}

async function fetchMerchantsForCurrentUser(): Promise<merchantWithRolesInterface[]> {
    const response = await axios.get<{ merchants: merchantWithRolesInterface[] }>('/api/users/merchants');

    return response.data.merchants;
}

async function fetchMerchantInvitationsForCurrentUser(): Promise<merchantUserMembershipInvitationInterface[]> {
    const response = await axios.get<{ data: merchantUserMembershipInvitationInterface[] }>('/api/users/invitations/merchant');
    return response.data.data;
}

async function fetchServiceProvidersForCurrentUser(): Promise<serviceProviderWithRolesInterface[]> {
    const response = await axios.get<{ serviceProviders: serviceProviderWithRolesInterface[] }>('/api/users/serviceProviders');

    return response.data.serviceProviders;
}

async function fetchServiceProviderInvitationsForCurrentUser(): Promise<serviceProviderUserMembershipInvitationInterface[]> {
    const response = await axios.get<{ data: serviceProviderUserMembershipInvitationInterface[] }>('/api/users/invitations/serviceProvider');
    return response.data.data;
}

async function acceptMerchantInvitation(token: string): Promise<void> {
    return await axios.get(`/api/users/invitations/merchant/${token}/accept`);
}

async function declineMerchantInvitation(token: string): Promise<void> {
    return await axios.get(`/api/users/invitations/merchant/${token}/decline`);
}

async function unassignUserRoleForMerchant(userId: number, role: MerchantUserRole): Promise<void> {
    await axios.post(`/api/users/${userId}/roles/merchant/unassign`, {
        role,
    });
}
async function editUserRoleForMerchant(userId: number, role: MerchantUserRole): Promise<void> {
    await axios.put(`/api/users/${userId}/roles/merchant`, {
        role,
    });
}

export const fetchApiTokens = async (): Promise<apiTokenInterface[]> => {
    const response = await axios.get<{ data: apiTokenInterface[] }>('/api/users/api-tokens');

    return response.data.data;
};

export const createApiToken = async (form: UnwrapRef<SparkFormType<createApiTokenFormInterface>>): Promise<apiTokenInterface> => {
    const response = await sendForm({ method: 'POST', uri: '/api/users/api-tokens', form });

    return response.data;
};

export const deleteApiToken = async (tokenId: string): Promise<void> => {
    return await axios.delete(`/api/users/api-tokens/${tokenId}`);
};

export default {
    fetchCurrentUser,
    fetchServiceProvidersForCurrentUser,
    fetchServiceProviderInvitationsForCurrentUser,
    inviteUserToServiceProvider,
    acceptServiceProviderInvitation,
    declineServiceProviderInvitation,
    unassignUserRoleForServiceProvider,
    editUserRoleForServiceProvider,
    fetchAccountUsers,
    fetchMerchantsForCurrentUser,
    fetchMerchantInvitationsForCurrentUser,
    inviteUserToMerchant,
    acceptMerchantInvitation,
    declineMerchantInvitation,
    unassignUserRoleForMerchant,
    editUserRoleForMerchant,
    fetchApiTokens,
    createApiToken,
    deleteApiToken,
};
