import { Ref, ref } from 'vue';
import { countryInterface, subdivisionInterface } from '@/api/addresses';
import API from '@/api';

export class GeographyRepository {
    loadingCountries: Ref<Promise<countryInterface[]> | false> = ref(false);
    loadingSubdivisions: Ref<{
        [key: string]: Promise<subdivisionInterface[]> | undefined // Keyed by country code
    }> = ref({});

    countries: Ref<countryInterface[]> = ref([]);
    subdivisions: Ref<{
        [key: string]: subdivisionInterface[] | undefined // Keyed by country code
    }> = ref({});

    getCountries = async (): Promise<countryInterface[]> => {
        if (this.loadingCountries.value !== false) {
            return await this.loadingCountries.value;
        }

        if (!this.countries.value.length) {
            this.loadingCountries.value = API.addresses.fetchCountries();
            this.countries.value = await this.loadingCountries.value;
            this.loadingCountries.value = false;
        }

        return this.countries.value;
    };

    getSubdivisionsForCountry = async (countryCode: string): Promise<subdivisionInterface[]> => {
        const promise = this.loadingSubdivisions.value[countryCode];

        if (promise !== undefined) {
            return await promise;
        }

        let subdivisions = this.subdivisions.value[countryCode];
        if (subdivisions === undefined) {
            const promise = API.addresses.fetchSubdivisions(countryCode);

            this.loadingSubdivisions.value[countryCode] = promise;

            const results = await promise;

            this.subdivisions.value[countryCode] = results;
            this.loadingSubdivisions.value[countryCode] = undefined;

            subdivisions = results;
        }

        return subdivisions;
    };
}
