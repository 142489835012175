<template>
    <div>
        <BaseSelect
            :value="pageSize"
            :options="pageSizeOptions"
            options-placement="top-start"
            @update:model-value="handleSelectChange"
        />
    </div>
</template>

<script lang="ts" setup generic="TData extends {[key: string]: any}">
import { PropType, computed } from 'vue';
import BaseSelect from '../form/BaseSelect.vue';

const props = defineProps({
    pageSize: {
        type: Number,
        required: true,
    },
    availablePageSizes: {
        type: Array as PropType<number[]>,
        default: () => [5, 10, 20, 50, 100, 200],
    },
});

const emit = defineEmits<{
    (e: 'change-page-size', value: number): void
}>();

const pageSizeOptions = computed(() => {
    return props.availablePageSizes.map(size => ({
        name: `Show ${size}`,
        value: size,
    }));
});

const handleSelectChange = (value: number | string | boolean | null) => {
    if (typeof value !== 'number') {
        return;
    }

    emit('change-page-size', value);
};
</script>
