import { UnwrapRef } from 'vue';
import { merchantInterface } from '../repositories/merchants';
import { merchantUserMembershipInvitationInterface } from '../repositories/users';
import { moneyInterface } from '@/compiler/types';
import axios from '@/helpers/forms/axios';
import { SparkFormType, sendForm } from '@/helpers/forms/form';

interface clientBalanceResponseInterface {
    openCharges: moneyInterface | null
    unpaidCharges: moneyInterface | null
}

const getFixCount = async (merchantId: number): Promise<number> => {
    const response = await axios.get<{ fixCount: number }>(`/api/merchants/${merchantId}/fixCount`);

    return response.data.fixCount;
};

const loadClientBalance = async (clientId: number): Promise<clientBalanceResponseInterface> => {
    const response = await axios.get<clientBalanceResponseInterface>(`/api/merchants/${clientId}/balances`);

    return response.data;
};

const fetchMerchantPendingInvitations = async (): Promise<merchantUserMembershipInvitationInterface[]> => {
    const response = await axios.get<{ data: merchantUserMembershipInvitationInterface[] }>('/api/merchants/invitations');

    return response.data.data;
};

const resendMerchantInvitation = async (token: string): Promise<void> => {
    await axios.get(`/api/users/invitations/merchant/${token}/resend`);
};

export interface createMerchantFormInterface {
    name: string
    primaryEmail: string
}

const registerMerchant = async (form: UnwrapRef<SparkFormType<createMerchantFormInterface>>): Promise<merchantInterface> => {
    const response = await sendForm({
        method: 'POST',
        uri: '/api/register-merchant',
        form,
    });

    return response.merchant;
};

export default {
    getFixCount,
    loadClientBalance,
    fetchMerchantPendingInvitations,
    resendMerchantInvitation,
    registerMerchant,
};
