<template>
    <transition
        enter-active-class="ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-if="show"
            class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 baseNotification"
        >
            <div class="p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0 -mt-0.5">
                        <!-- Icon -->
                        <component
                            :is="icon as any"
                            class="h-6 w-6"
                            :class="iconColour"
                        />
                    </div>
                    <div class="ml-3 w-0 flex-1">
                        <p class="text-sm font-medium text-gray-900">
                            {{ notification.title }}
                        </p>

                        <div
                            v-if="notification.type === 'action'"
                            class="mt-4 flex"
                        >
                            <BaseButton
                                :text="notification.actionText"
                                design="white"
                                :colour="buttonColour"
                                :click-func="performAction"
                            />
                        </div>
                    </div>
                    <div class="ml-4 flex-shrink-0 flex">
                        <button
                            class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 closeButton"
                            @click="show = false"
                        >
                            <span class="sr-only">Close</span>
                            <XMarkIcon
                                class="h-5 w-5"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" setup>
import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/vue/20/solid';
import { PropType, computed, onMounted, ref } from 'vue';
import BaseButton from './BaseButton.vue';
import { PopupNotification } from '@/stores/overlays/popupNotifications';
import { BaseColour } from '@/compiler/types';

const props = defineProps({
    notification: {
        type: Object as PropType<PopupNotification>,
        default: null,
    },
});

const show = ref(true);
const icon = computed(() => {
    const icons: { [key: string]: Function } = {
        error: ExclamationCircleIcon,
        action: ExclamationCircleIcon,
        success: CheckCircleIcon,
    };

    return icons[props.notification.type];
});

const iconColour = computed(() => {
    const colours: { [key: string]: string } = {
        error: 'text-red-400',
        action: 'text-primary-400',
        success: 'text-green-400',
    };

    return colours[props.notification.type];
});

const buttonColour = computed(() => {
    const colours: { [key: string]: BaseColour } = {
        error: BaseColour.Red,
        action: BaseColour.Primary,
        success: BaseColour.Green,
    };

    return colours[props.notification.type];
});

const performAction = () => {
    show.value = false;

    if (props.notification.action) {
        props.notification.action();
    }
};

onMounted(() => {
    if (!props.notification.permanent) {
        const timeout = props.notification.type === 'success' ? 2000 : 6000;

        setTimeout(() => {
            show.value = false;
        }, timeout);
    }
});
</script>
