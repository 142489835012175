import axios from '@/helpers/forms/axios';

export interface itemGroupConfigurationInterface {
    id: number
    configurationId: number
    createdAt: string
    updatedAt: string
    itemId: number
    quantity: number
    partialQuantity: number
    type: 'box' | 'pallet'
    weightGm: number
    lengthMm: number
    heightMm: number
    widthMm: number
    contains: itemGroupConfigurationInterface[]
    default: boolean
}

export interface itemGroupConfigurationFormInterface {
    quantity: number
    type: 'box' | 'pallet'
    lengthMm: number
    widthMm: number
    heightMm: number
    weightGm: number
    contains: itemGroupConfigurationInterface[]
}

const fetchConfigurations = async (itemId: number): Promise<itemGroupConfigurationInterface[]> => {
    const response = await axios.get<{ configurations: itemGroupConfigurationInterface[] }>(`/api/inventory/${itemId}/group-configurations`);

    return response.data.configurations;
};

const deleteConfigurations = async (itemId: number, configurationId: number): Promise<void> => {
    return await axios.delete(`/api/inventory/${itemId}/group-configurations/${configurationId}`);
};

const makeDefaultConfiguration = async (itemId: number, configurationId: number): Promise<void> => {
    return await axios.get(`/api/inventory/${itemId}/group-configurations/${configurationId}/default`);
};

export default {
    fetchConfigurations,
    deleteConfigurations,
    makeDefaultConfiguration,
};
