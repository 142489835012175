import axios from '@/helpers/forms/axios';
import { TransportationCarrierEnum } from '@/compiler/transportation';

export interface carrierInterface {
    carrier: TransportationCarrierEnum
}

export const fetchCarriers = async (): Promise<carrierInterface[]> => {
    const response = await axios.get<carrierInterface[]>('/api/staff/settings/carriers');

    return response.data;
};
