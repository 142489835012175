import { defineStore } from 'pinia';

interface State {
    largeImageSrc: string | null
}

export const useLargeImageStore = defineStore('largeImage', {
    state: (): State => ({
        largeImageSrc: null,
    }),
    getters: {
        getLargeImageSrc: state => state.largeImageSrc,
    },
    actions: {
        setLargeImageSrc(value: string): void {
            this.largeImageSrc = value;
        },
        clearLargeImageSrc(): void {
            this.$reset();
        },
    },
});
