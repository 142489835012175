import dayjs from 'dayjs';
import usePopupNotifications from './usePopupNotifications';

export default function useValidation() {
    const { notifyError } = usePopupNotifications();

    const validateExpiryDate = (value: string): boolean => {
        const parsedDate = dayjs(value);

        if (!parsedDate.isValid() || parsedDate.year() < 2020 || parsedDate.year() > 2200) {
            return false;
        }

        if (parsedDate.isBefore(dayjs())) {
            notifyError('The expiry date must not be in the past.');

            return false;
        }

        return true;
    };

    return {
        validateExpiryDate,
    };
};
