<template>
    <Combobox :id="id" v-model="selectedOption" as="div" @update:model-value="handleSelect">
        <ComboboxLabel
            v-if="label"
            class="block text-sm font-medium text-gray-700"
            :class="labelClasses"
        >
            {{ label }}
        </ComboboxLabel>
        <div class="relative mt-1">
            <ComboboxInput
                data-lpignore="true"
                class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                :placeholder="placeholder"
                :display-value="(option) => (option as comboboxOptionInterface)?.name"
                @change="query = $event.target.value"
                @focus="handleInputFocus"
                @click="handleInputClick"
            />
            <ComboboxButton
                :id="`comboboxButton-${id}`"
                ref="comboboxButtonRef"
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            >
                <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            <ComboboxOptions
                v-if="filteredOptions.length > 0"
                class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
                <ComboboxOption
                    v-for="option in filteredOptions"
                    :id="`comboboxOption-${id}-${option.value}`"
                    :key="option.value"
                    v-slot="{ active, selected, disabled }"
                    :value="option"
                    :disabled="option.disabled !== undefined ? option.disabled : false"
                    as="template"
                >
                    <li
                        class="relative select-none py-2 pl-3 pr-9" :class="[{
                            'bg-indigo-600 text-white': active,
                            'bg-gray-50 text-gray-300 cursor-not-allowed': disabled,
                            'text-gray-800': !disabled && !active,
                            'cursor-default': !disabled,
                        }]"
                        :data-select-value="option.value"
                    >
                        <div class="flex items-center">
                            <img
                                v-if="option.imageUrl"
                                :src="option.imageUrl"
                                :alt="option.name"
                                class="h-6 w-6 flex-shrink-0 rounded-full"
                            >
                            <span
                                class="ml-3 truncate" :class="[selected && 'font-semibold']"
                            >{{ option.name }}</span>
                        </div>

                        <span
                            v-if="selected"
                            class="absolute inset-y-0 right-0 flex items-center pr-4" :class="[active ? 'text-white' : 'text-indigo-600']"
                        >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
    </Combobox>
</template>

<script lang="ts" setup>
import { PropType, computed, nextTick, ref, watch } from 'vue';
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue';

export interface comboboxOptionInterface {
    value: string | number
    name: string
    imageUrl?: string
    disabled?: boolean
}

const props = defineProps({
    options: {
        type: Array as PropType<comboboxOptionInterface[]>,
        required: true,
    },
    selectedValue: {
        type: [String, Number],
    },
    label: String,
    labelClasses: String,
    id: {
        type: String,
        default: () => {
            return `Combobox-${Math.floor(Math.random() * 100000)}${1}`;
        },
    },
    placeholder: {
        type: String,
        default: null,
    },
});

const emit = defineEmits<{
    (e: 'option-selected', option: comboboxOptionInterface): void
}>();

const comboboxButtonRef = ref<InstanceType<typeof ComboboxButton>>();
const query = ref<string>('');
const selectedOption = ref<comboboxOptionInterface | undefined>(props.selectedValue ? props.options.find(option => option.value === props.selectedValue) : undefined);
const filteredOptions = computed(() =>
    query.value === ''
        ? props.options
        : props.options.filter((option) => {
            return option.name.toLowerCase().includes(query.value.toLowerCase());
        }),
);

const handleSelect = (option: comboboxOptionInterface) => {
    emit('option-selected', option);

    nextTick(() => {
        nextTick(() => {
            (document.activeElement as HTMLInputElement).blur(); // Defocus input
        });
    });
};

const handleInputFocus = (event: Event) => {
    const target = event.target as HTMLInputElement;

    target.setSelectionRange(0, target.value.length);
};

const handleInputClick = () => {
    const comboboxButton = document.querySelector(`#comboboxButton-${props.id}`);

    if (comboboxButton) {
        (comboboxButton as HTMLButtonElement).click(); // Open the options list
    }
};

watch(() => props.selectedValue, (newValue) => {
    selectedOption.value = props.options.find(option => option.value === props.selectedValue);
});
</script>
