import { customerAddressInterface, customerInterface } from './customers';
import { orderItemInterface } from './orderItems';
import { packageInterface } from './packages';
import { attachmentInterface } from './attachments';
import { companyServiceWithPivotInterface } from './companyServices';
import { itemInterface } from './items';
import { locationInterface } from './locations';
import { trackingNumberInterface } from './trackingNumbers';
import { tagInterface } from './tags';
import { fulfillmentRequestInterface } from './fulfillment';
import { moneyInterface } from '@/compiler/types';
import { TransportationCarrierEnum, TransportationCarrierServiceLevelEnum, TransportationProviderEnum } from '@/compiler/transportation';
import axios from '@/helpers/forms/axios';

export interface orderQuoteItemInterface {
    carrierRate: object
    parcels: object[]
}

export interface orderQuoteInterface {
    id: string
    total: moneyInterface
    carrier: TransportationCarrierEnum
    serviceLevel: TransportationCarrierServiceLevelEnum
    provider: TransportationProviderEnum
    deliveryDate: string
    deliveryDays: number
    quoteItems: orderQuoteItemInterface[]
}

export interface orderQuoteSummaryInterface {
    selectedQuoteId: string
    cheapestQuoteId: string
    fastestQuoteId: string
    preferenceQuoteIds?: string[]
    quotes: orderQuoteInterface[]
    messages: { source: string, text: string }[]
    errors: { source: string, text: string }[]
}

export interface orderInterface {
    id: number
    createdAt: string
    updatedAt: string
    customerId: number
    customer: customerInterface
    shippingAddressId: number
    shippingAddress?: customerAddressInterface
    billingAddressId: number
    orderId: string
    channelId: number
    channelOrderId: string
    orderDate: string
    shipBy: string
    arriveBy: string
    status: 'waiting' | 'pending' | 'picked' | 'packed' | 'shipped' | 'partially_shipped' | 'delivered' | 'error' | 'cancelled'
    warehouseNotes: string
    giftNote: string
    shippingMethodPreference: string
    pickAssignedTo: number
    packAssignedTo: number
    quotes: orderQuoteSummaryInterface | null
    errors: { source: string, text: string }[] | null
    orderItems: orderItemInterface[]
    toteCode?: string
    packages?: packageInterface[]
    attachments?: attachmentInterface[]
    trackingNumbers?: trackingNumberInterface[]
    tags: tagInterface[]
};

export interface packOrderInterface extends orderInterface {
    packages: packageInterface[]
    attachments: attachmentInterface[]
    companyServices: companyServiceWithPivotInterface[]
    shippingAddress: customerAddressInterface
}

export interface pickDataInterface {
    toteCode?: string
    quantityPicked?: number
    scans?: {
        type: string
        code: string
        scannedAt: string
        scannedByUserId: number
    }[]
}

export interface pickInterface {
    id: number
    item: itemInterface
    orders: orderInterface[]
    locations: locationInterface[]
}

export interface pickSettingsInterface {
    locationScan?: 'optional' | 'required' | 'disabled'
    itemScan?: 'optional' | 'required' | 'required_first_pick' | 'disabled'
}

interface pickResponseInterface {
    picks: pickInterface[]
    pickSettings: pickSettingsInterface
}

interface orderResponseInterface {
    order: orderInterface
    fulfillmentRequests: fulfillmentRequestInterface[]
}

const fetchOrder = async (orderId: number): Promise<orderResponseInterface> => {
    const response = await axios.get<orderResponseInterface>(`/api/orders/${orderId}`);

    return response.data;
};

const fetchFulfillableOrders = async (): Promise<orderInterface[]> => {
    const response = await axios.get<{ orders: orderInterface[] }>('/api/orders?fulfillable=1');
    return response.data.orders;
};

const fetchAllOrders = async (): Promise<orderInterface[]> => {
    const response = await axios.get<{ orders: orderInterface[] }>('/api/orders');
    return response.data.orders;
};

const fetchPackOrders = async (): Promise<packOrderInterface[]> => {
    const response = await axios.get<{ orders: packOrderInterface[] }>('/api/orders/packs');
    return response.data.orders;
};

const fetchPickOrders = async (): Promise<pickResponseInterface> => {
    const response = await axios.get<pickResponseInterface>('/api/orders/picks');

    return response.data;
};

const deleteOrders = async (ids: number[]): Promise<null> => {
    await axios.post('/api/orders/batch/delete', { ids });

    return null;
};

const removeTagFromOrder = async (orderId: number, tagId: number): Promise<void> => {
    return await axios.delete(`/api/orders/${orderId}/tags/delete-tag/${tagId}`);
};

const addTagToOrder = async (orderId: number, tagId: number): Promise<void> => {
    return await axios.post(`/api/orders/${orderId}/tags/add-tag/${tagId}`);
};

export default {
    fetchOrder,
    fetchFulfillableOrders,
    fetchPackOrders,
    fetchPickOrders,
    deleteOrders,
    removeTagFromOrder,
    addTagToOrder,
    fetchAllOrders,
};
