<template>
    <span
        v-tooltip="tooltipText"
        :class="spanClasses"
        @click="click"
    >
        <component
            :is="icon as any"
            v-if="!clicked"
            class="h-4 w-4 inline mb-0.5"
        />
        <Transition
            enter-active-class="origin-right transition-transform ease-in-out duration-200"
            enter-from-class="scale-y-0"
            enter-to-class="scale-y-100"
            leave-active-class="hidden"
        >
            <CheckIcon
                v-if="clicked"
                class="w-4 h-4 inline text-green-500"
            />
        </Transition>
    </span>
</template>

<script lang="ts" setup>
import { CheckIcon } from '@heroicons/vue/20/solid';
import { PropType, computed, ref } from 'vue';
import { BaseColour } from '@/compiler/types';

const props = defineProps({
    colour: {
        type: String as PropType<BaseColour>,
        default: BaseColour.Primary,
    },
    icon: {
        type: Function,
        required: true,
    },
    tooltip: {
        type: String,
        default: null,
    },
    clickFunc: {
        type: Function,
        required: true,
    },
});

const spanClasses = computed((): string => {
    let classes = 'cursor-pointer whitespace-nowrap ';

    classes += `text-${props.colour}-400 hover:text-${props.colour}-500`;

    return classes;
});

const tooltipText = computed((): string | null => {
    return props.tooltip;
});

const clicked = ref(false);
const click = () => {
    clicked.value = true;
    props.clickFunc();

    setTimeout(() => {
        clicked.value = false;
    }, 2000);
};
</script>
