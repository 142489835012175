<template>
    <div :class="{ 'flex flex-col': !inline, 'inline': inline }">
        <span v-if="convertedAmount !== undefined">
            <span>{{ displayMoneyValue(convertedAmount, displayCurrency) }}</span>
        </span>
        <span
            v-if="originalMoney.currency !== displayCurrency"
            class="text-xs text-gray-400 whitespace-nowrap"
        >
            <span v-if="inline">&nbsp;(</span>
            <span>{{ displayMoneyValue(originalMoney.amount, originalMoney.currency) }}</span>
            <span v-if="inline">)</span>
            <span
                v-tooltip.right="'Estimated Exchange Rate Using Today\'s Rate'"
                class="ml-1"
            >@ {{ displayCurrencyExchangeRate }}</span>
        </span>

        <span v-if="convertedAmount === undefined">
            <span>{{ displayMoneyValue(originalMoney.amount, originalMoney.currency) }}</span>
        </span>
        <span
            v-if="convertedAmount === undefined"
            class=" text-red-800/70 text-xs whitespace-nowrap"
        >
            <span
                v-tooltip.right="'The currency conversion failed, falling back to original currency. Please try again later.'"
            >(FX Error)</span>
        </span>
    </div>
</template>

<script lang="ts" setup>
import { PropType, ref, watch } from 'vue';
import Dinero, { Currency } from 'dinero.js';
import dayjs from 'dayjs';
import useLocalization from '@/composables/useLocalization';
import useFormatters from '@/composables/useFormatters';
import { moneyInterface } from '@/compiler/types';
import API from '@/api';

const props = defineProps({
    originalMoney: {
        type: Object as PropType<moneyInterface>,
        required: true,
    },
    inline: {
        type: Boolean,
        default: false,
    },
    precise: {
        type: Boolean,
        default: false,
    },
});

const displayMoneyValue = (amount: number, currency: Currency) => {
    if (props.precise) {
        return preciseMoney(amount, currency);
    }
    return money(amount, currency);
};

const convertedAmount = ref<number>();
const convertMoney = async () => {
    const numberOfExponents = props.precise ? 6 : numberOfExponentsInCurrency(props.originalMoney.currency);

    const originalAmountMinorUnits = Math.round(props.originalMoney.amount * 10 ** numberOfExponents);

    const original = Dinero({
        amount: originalAmountMinorUnits,
        currency: props.originalMoney.currency,
        precision: numberOfExponents,
    });

    if (original.getCurrency() === displayCurrency.value) {
        convertedAmount.value = original.toUnit();
        return;
    }

    try {
        const rates = await getExchangeRates(original.getCurrency());
        displayCurrencyExchangeRate.value = rates[displayCurrency.value];

        const converted = await original.convert(displayCurrency.value, {
            endpoint: new Promise(resolve => resolve({ rates })),
        });

        convertedAmount.value = converted.toUnit();
    } catch (e) {
        console.error('Failed converting money', e);
    }
};

const getExchangeRates = async (baseCurrency: Currency): Promise<{ [key: string]: number }> => {
    let rates: { [key: string]: { [key: string]: number } } = {};

    const dateString = dayjs().format('YYYY-MM-DD');

    const localRates = localStorage.getItem(`exchangeRates-${dateString}`);

    if (localRates) {
        rates = JSON.parse(localRates);
    }

    const exchangeRates = rates[baseCurrency];
    if (exchangeRates) {
        return exchangeRates;
    }

    const externalRates = await API.external.exchangeRates.fetchExchangeRates(baseCurrency);

    rates[baseCurrency] = externalRates;

    localStorage.setItem(`exchangeRates-${dateString}`, JSON.stringify(rates));

    return rates[baseCurrency];
};

const displayCurrencyExchangeRate = ref<number>();
const { displayCurrency, numberOfExponentsInCurrency } = useLocalization();
const { money, preciseMoney } = useFormatters();

convertMoney();
watch(() => props.originalMoney, (newValue) => {
    convertMoney();
});
</script>
