<template>
    <base-modal size="3xl" :open="show && Boolean(imageSrc)" :close-func="close">
        <img
            v-show="imageSrc"
            :src="imageSrc || ''"
            class="max-h-96 mx-auto"
            @load="imageLoaded = true"
        >
        <div v-if="!imageLoaded" class="flex flex-row items-center">
            <base-spinner class="h-4 mr-1" />
            <span>Loading Image...</span>
        </div>
    </base-modal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useLargeImageStore } from '@/stores/overlays/largeImage';

const largeImageStore = useLargeImageStore();
const imageSrc = computed(() => largeImageStore.getLargeImageSrc);
const imageLoaded = ref(false);
const show = ref(false);

largeImageStore.$onAction(({ after }) => {
    after(() => {
        if (imageSrc.value) {
            show.value = true;
        }
    });
});

const close = () => {
    show.value = false;

    setTimeout(() => {
        largeImageStore.clearLargeImageSrc();
        imageLoaded.value = false;
        show.value = true;
    }, 500);
};
</script>
