export interface sortInterface {
    field: string
    direction: 'asc' | 'desc'
    order: number
}

export interface ValidFilter {
    field: string
    name: string
    type: 'string' | 'number' | 'date' | 'enum'
}

export interface ValidEnumFilter extends ValidFilter {
    type: 'enum'
    validValues: string[]
}

export interface Tabular<TData> {
    getCurrentData: () => TData[]
    getId: (item: TData) => string | number

    getSorts: () => sortInterface[]
    sortBy: (field: string, direction?: 'asc' | 'desc', order?: number) => void
    clearSort: (key: string) => void

    readonly search: string
    setSearch: (search: string) => void
}

export interface SimplePaginationTabular<TData> extends Tabular<TData> {
    readonly page: number
    readonly pageSize: number
    goToPage: (page: number) => void
    setPageSize: (pageSize: number) => void

    readonly pageStart: number
    readonly pageEnd: number
    readonly totalDataCount: number | null
}

export interface IdGetterFunction<TData> {
    (item: TData): string | number
}

export function isSimplePaginationTabular(tabular: Tabular<any>): tabular is SimplePaginationTabular<any> {
    return (tabular as SimplePaginationTabular<any>).pageSize !== undefined;
};

export interface RemoteSimplePaginationTabular<TData> extends SimplePaginationTabular<TData> {
    requestData: (page: number, pageSize?: number) => void
    readonly isLoading: boolean
    readonly isPending: boolean
}

export function isRemoteSimplePaginationTabular(tabular: Tabular<any>): tabular is RemoteSimplePaginationTabular<any> {
    return (tabular as RemoteSimplePaginationTabular<any>).isLoading !== undefined;
};
