import { PusherPrivateChannel } from 'laravel-echo/dist/channel';
import { inject } from 'vue';
import { providerKeys } from '@/composables/useProviders';

export default function useWebsockets() {
    const echo = inject(providerKeys.echo);

    const subscribeToPrivateChannel = (channel: string): PusherPrivateChannel | undefined => {
        return echo?.private(channel) as PusherPrivateChannel | undefined;
    };

    const leaveChannel = (channel: string): void | undefined => {
        return echo?.leave(channel);
    };

    return {
        subscribeToPrivateChannel,
        leaveChannel,
    };
}
