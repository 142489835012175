import { UnwrapRef } from 'vue';
import { userInterface, userOnboardingInterface } from '../repositories/users';
import axios from '@/helpers/forms/axios';
import { SparkFormType, sendForm } from '@/helpers/forms/form';

export interface registerUserFormInterface {
    name: string
    email: string
    password: string
    passwordConfirmation: string
}

async function registerUser(form: UnwrapRef<SparkFormType<registerUserFormInterface>>): Promise<userInterface> {
    const response = await sendForm({
        method: 'post',
        uri: '/api/register',
        form,
    });

    return response.user;
}

async function updateOnboarding(userId: number, onboarding: userOnboardingInterface): Promise<userOnboardingInterface> {
    const response = await axios.post<{ onboarding: userOnboardingInterface }>(`/api/users/${userId}/onboarding`, onboarding);

    return response.data.onboarding;
};

export default {
    registerUser,
    updateOnboarding,
};
