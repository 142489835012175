import onScan from 'onscan.js';
import { ComputedRef, Ref, computed, ref } from 'vue';

export interface BarcodeSubscriberCallback {
    (barcode: string): void
};

export class BarcodeListener {
    constructor(defaultSubscriber: BarcodeSubscriberCallback) {
        this.defaultSubscriber = defaultSubscriber;
    }

    isScanning: ComputedRef<boolean> = computed(() => this.processingKeyCount.value >= 6);
    scannedBarcode: Ref<string> = ref('');

    defaultSubscriber: BarcodeSubscriberCallback;
    subscriber: BarcodeSubscriberCallback | null = null;

    changeSubscriber = (subscriber: BarcodeSubscriberCallback) => {
        this.subscriber = subscriber;
    };

    removeSubscriber = () => {
        this.subscriber = null;
    };

    handleBarcodeScan = (barcode: string) => {
        this.scannedBarcode.value = barcode;
        setTimeout(() => {
            this.scannedBarcode.value = '';
        }, 2000);
        this.processingKeyCount.value = 0;

        if (this.handleCommandCodes(barcode)) {
            return;
        }

        if (this.handleActiveElements(barcode)) {
            return;
        }

        if (this.subscriber) {
            this.subscriber(barcode);
        } else {
            this.defaultSubscriber(barcode);
        }
    };

    handleActiveElements = (barcode: string): boolean => {
        const activeElement = document.activeElement;

        if (activeElement instanceof HTMLInputElement) {
            activeElement.value = barcode;
            activeElement.dispatchEvent(new Event('input'));

            return true;
        }

        if (activeElement instanceof HTMLTextAreaElement) {
            // Fix issues with typing really fast, mostly on Dusk tests
            if (activeElement.value.includes(barcode)) {
                return true;
            }

            activeElement.value += `\n${barcode}`;
            activeElement.dispatchEvent(new Event('input'));

            return true;
        }

        return false;
    };

    /**
     * Example command codes:
     * $$(Control+/) - Sends Ctrl + / at the same time
     * $$(ArrowDown)$$(Enter) - Sends the down arrow, then the enter key
     */
    handleCommandCodes = (barcode: string): boolean => {
        if (barcode.includes('$$(')) {
            const keys: string[] = [];

            barcode.split('$$').forEach((barcodeKey) => {
                if (!barcodeKey) {
                    return;
                }

                keys.push(barcodeKey
                    .replace('(', '')
                    .replace(')', ''),
                );
            });

            this.sendKeyCombination(keys);

            return true;
        }
        return false;
    };

    sendKeyCombination = (keyCombinations: string[]): void => {
        keyCombinations.forEach((keyCombination) => {
            let key = keyCombination;
            let ctrlKey = false;
            if (keyCombination.includes('+')) {
                const splitKey = key.split('+');
                if (splitKey[0] === 'Control') {
                    ctrlKey = true;
                }

                key = splitKey[splitKey.length - 1];
            }

            document.dispatchEvent(new KeyboardEvent('keydown', { key, ctrlKey }));
            document.dispatchEvent(new KeyboardEvent('keyup', { key }));
        });
    };

    processingKeyCount: Ref<number> = ref(0);
    handleKeyProcess = (keyCode: string, _keyEvent: KeyboardEvent): void => {
        if (!keyCode) return;
        this.processingKeyCount.value++;
    };

    // eslint-disable-next-line unused-imports/no-unused-vars
    handleScanError = (debug: any): void => {
        this.processingKeyCount.value = 0;
    };

    shouldReactOnPaste: boolean = import.meta.env.DEV;
    reactOnPaste = (): void => {
        this.shouldReactOnPaste = true;
        this.initScanner();
    };

    dontReactOnPaste = (): void => {
        this.shouldReactOnPaste = false;
        this.initScanner();
    };

    initScanner = () => {
        if (onScan.isAttachedTo(document)) {
            onScan.detachFrom(document);
        }

        onScan.attachTo(document, {
            reactToPaste: this.shouldReactOnPaste,
            onScan: scannedCode => this.handleBarcodeScan(scannedCode),
            suffixKeyCodes: [13],
            timeBeforeScanTest: 300,
            avgTimeByChar: 50,
            keyCodeMapper(scanKeyEvent) {
                // Return the key if it's in the allowed characters list
                const allowedCharactersRegex = /[\-,'\s_a-zA-Z0-9$\/]/;
                if (scanKeyEvent.key?.length === 1 && allowedCharactersRegex.test(scanKeyEvent.key)) {
                    return scanKeyEvent.key;
                }

                return null;
            },
            onScanError: _debugData => this.handleScanError(_debugData),
            onKeyProcess: (scanKeyCode: string, scanKeyEvent: KeyboardEvent) => this.handleKeyProcess(scanKeyCode, scanKeyEvent),
        });
    };
}
