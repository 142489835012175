import { Currency } from 'dinero.js';
import axios from '@/helpers/forms/axios';

export enum FbaTransportVersions {
    V0 = 'v0',
    V2024 = 'v2024',
}

export interface FbaTransportV0PlanItem {
    id: string
    title: string
    sellerSku: string
    fnsku: string
    quantity: number
    expiration: string | null
}

export interface FbaTransportV0BoxGroupItem {
    id: string
    createdAt: string
    planItemId: string
    quantity: number
}

export interface TransportDimensions {
    lengthMm: number
    widthMm: number
    heightMm: number
    weightGm: number
}

export interface FbaTransportV0BoxGroup extends TransportDimensions {
    id: string
    boxNumbers: number[]
    items: FbaTransportV0BoxGroupItem[]
}

export interface PalletDetails extends TransportDimensions {
    repeat: number
    stackable: boolean
}

export interface FbaTransportV0Pallet extends PalletDetails {
    id: string
}

export interface FbaTransportV0NonPartneredLtlTracking {
    id: string
    carrier: string
    proNumber: string
    numberOfPallets: number
}

export interface FbaTransportV0NonPartneredSmallParcelTracking {
    id: string
    carrier: string
    trackingNumbers: string[]
}

export interface FbaTransportV0PartneredLtlDetails {
    id: string
    contactName: string
    contactEmail: string
    contactPhone: string
    freightClass: number
    totalValue: number | null
    totalValueCurrency: string | null
    readyDate: string
}

export interface FbaTransportAddress {
    city: string
    countryCode: string
    postalCode: string
    name: string
    addressLine1: string
    addressLine2?: string
    stateOrProvinceCode: string
    districtOrCounty: string
}

export enum FbaTransportMode {
    PartneredLtl = 'partnered_ltl',
    NonPartneredLtl = 'non_partnered_ltl',
    PartneredSmallParcel = 'partnered_small_parcel',
    NonPartneredSmallParcel = 'non_partnered_small_parcel',
}

export enum FbaTransportV0LabelPrepPreference {
    SellerLabel = 'SELLER_LABEL',
    AmazonLabelOnly = 'AMAZON_LABEL_ONLY',
}

export enum FbaTransportPartneredCarrier {
    Ups = 'UNITED_PARCEL_SERVICE_INC',
    Dhl = 'DHL_STANDARD',
}

export interface FbaTransportV0PartneredEstimate {
    voidDeadline: string | null
    estimateAmount: number
    estimateCurrency: Currency
    isVoided: boolean
}

export interface FbaTransportV0Label {
    id: string
    createdAt: string
    updatedAt: string
    name: string
    url: string
    type: 'box' | 'pallet' | 'package' | 'bill_of_lading'
    paperSize: 'US_Letter' | 'PackageLabel_Letter_2' | 'PackageLabel_A4_4' | '4x6'
}

export interface FbaTransportV0Plan {
    id: string
    createdAt: string
    outboundShipmentId: number
    channelId: number
    mode: FbaTransportMode
    labelPrepPreference: FbaTransportV0LabelPrepPreference
    name: string
    fbaShipmentId: string
    destinationId: string
    shipFromAddress: FbaTransportAddress
    destinationAddress: FbaTransportAddress
    isAccepted: boolean
    isRejected: boolean
    isMarkedAsShipped: boolean
    isMarkedAsDeleted: boolean
    areCasesRequired: boolean
    using2dBarcodes: boolean
    partneredCarrier: FbaTransportPartneredCarrier
    items: FbaTransportV0PlanItem[]
    boxGroups: FbaTransportV0BoxGroup[]
    pallets: FbaTransportV0Pallet[]
    nonPartneredLtlTracking: FbaTransportV0NonPartneredLtlTracking | null
    nonPartneredSmallParcelTracking: FbaTransportV0NonPartneredSmallParcelTracking | null
    partneredLtlDetails: FbaTransportV0PartneredLtlDetails | null
    partneredEstimate: FbaTransportV0PartneredEstimate | null
    transportStatusCheck: FbaTransportV0TransportStatusCheck | null
}

export interface FbaTransportV0RequestSubmission {
    id: string
    createdAt: string
    updatedAt: string
    successResponse: object
    errorResponse: object
}

const getPlans = async (outboundShipmentId: number): Promise<FbaTransportV0Plan[]> => {
    const url = new URL(`/api/fba-transport/v0/plans`, location.origin);
    url.searchParams.append('outbound_shipment_id', outboundShipmentId.toString());

    const response = await axios.get<{ data: FbaTransportV0Plan[] }>(url.toString());

    return response.data.data;
};

const showPlan = async (planId: string): Promise<FbaTransportV0Plan> => {
    const response = await axios.get<{ data: FbaTransportV0Plan }>(`/api/fba-transport/v0/plans/${planId}`);

    return response.data.data;
};

const requestPlans = async (
    outboundShipmentId: number,
    channelId: number,
    labelPrepPreference: FbaTransportV0LabelPrepPreference,
    optimizeSplitsEnabled: boolean,
): Promise<FbaTransportV0Plan[]> => {
    const response = await axios.post<{ data: FbaTransportV0Plan[] }>(`/api/fba-transport/v0/plans/request`, {
        outboundShipmentId,
        channelId,
        labelPrepPreference,
        optimizeSplitsEnabled,
    });

    return response.data.data;
};

const acceptPlan = async (
    planId: string,
    optimizeSplitsEnabled: boolean,
): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/accept`, {
        optimizeSplitsEnabled,
    });
};

const rejectPlan = async (planId: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/reject`);
};

const changeMode = async (planId: string, mode: FbaTransportMode): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/change-mode`, { mode });
};

const changeUsing2dBarcodes = async (planId: string, using2dBarcodes: boolean): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/change-using-2d-barcodes`, { using2dBarcodes });
};

const changePartneredCarrier = async (planId: string, partneredCarrier: FbaTransportPartneredCarrier): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/change-partnered-carrier`, { partneredCarrier });
};

const resetBoxContents = async (planId: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/reset-box-contents`);
};

const updatePlanItem = async (planId: string, planItemId: string, data: Pick<FbaTransportV0PlanItem, 'expiration'>): Promise<void> => {
    return axios.put(`/api/fba-transport/v0/plans/${planId}/items/${planItemId}`, data);
};

const createBoxGroup = async (planId: string, dimensions: TransportDimensions): Promise<string> => {
    const response = await axios.post<{ boxGroupId: string }>(`/api/fba-transport/v0/plans/${planId}/box-groups`, dimensions);

    return response.data.boxGroupId;
};

const updateBoxGroup = async (planId: string, boxGroupId: string, dimensions: TransportDimensions): Promise<void> => {
    return axios.put(`/api/fba-transport/v0/plans/${planId}/box-groups/${boxGroupId}`, dimensions);
};

const removeBoxGroup = async (planId: string, boxGroupId: string): Promise<void> => {
    return axios.delete(`/api/fba-transport/v0/plans/${planId}/box-groups/${boxGroupId}`);
};

const setBoxQuantityForBoxGroup = async (planId: string, boxGroupId: string, quantity: number): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/box-groups/${boxGroupId}/set-box-quantity`, { quantity });
};

const createBoxGroupItem = async (
    planId: string,
    boxGroupId: string,
    item: Omit<FbaTransportV0BoxGroupItem, 'id' | 'createdAt'>,
): Promise<FbaTransportV0BoxGroupItem> => {
    const response = await axios.post<{ boxGroupItem: FbaTransportV0BoxGroupItem }>(`/api/fba-transport/v0/plans/${planId}/box-groups/${boxGroupId}/items`, item);

    return response.data.boxGroupItem;
};

const updateBoxGroupItem = async (
    planId: string,
    boxGroupId: string,
    itemId: string,
    quantity: number,
): Promise<FbaTransportV0BoxGroupItem> => {
    const response = await axios.put<{ boxGroupItem: FbaTransportV0BoxGroupItem }>(`/api/fba-transport/v0/plans/${planId}/box-groups/${boxGroupId}/items/${itemId}`, {
        quantity,
    });

    return response.data.boxGroupItem;
};

const removeBoxGroupItem = async (planId: string, boxGroupId: string, itemId: string): Promise<void> => {
    return axios.delete(`/api/fba-transport/v0/plans/${planId}/box-groups/${boxGroupId}/items/${itemId}`);
};

const createPallet = async (planId: string, details: PalletDetails): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/pallets`, details);
};

const updatePallet = async (planId: string, palletId: string, details: PalletDetails): Promise<void> => {
    return axios.put(`/api/fba-transport/v0/plans/${planId}/pallets/${palletId}`, details);
};

const removePallet = async (planId: string, palletId: string): Promise<void> => {
    return axios.delete(`/api/fba-transport/v0/plans/${planId}/pallets/${palletId}`);
};

const setNonPartneredLtlTracking = async (planId: string, tracking: Omit<FbaTransportV0NonPartneredLtlTracking, 'id'>): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/set-non-partnered-ltl-tracking`, tracking);
};

const setNonPartneredSmallParcelTracking = async (planId: string, tracking: Omit<FbaTransportV0NonPartneredSmallParcelTracking, 'id'>): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/set-non-partnered-small-parcel-tracking`, tracking);
};

const setPartneredLtlDetails = async (planId: string, details: Omit<FbaTransportV0PartneredLtlDetails, 'id'>): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/set-partnered-ltl-details`, details);
};

interface GetSubmissionsResponseRaw {
    cartonDataSubmission: FbaTransportV0RequestSubmission | []
    transportDataSubmission: FbaTransportV0RequestSubmission | []
    requestEstimateSubmission: FbaTransportV0RequestSubmission | []
    confirmEstimateSubmission: FbaTransportV0RequestSubmission | []
    voidEstimateSubmission: FbaTransportV0RequestSubmission | []
}

export interface GetSubmissionsResponse {
    cartonDataSubmission: FbaTransportV0RequestSubmission | null
    transportDataSubmission: FbaTransportV0RequestSubmission | null
    requestEstimateSubmission: FbaTransportV0RequestSubmission | null
    confirmEstimateSubmission: FbaTransportV0RequestSubmission | null
    voidEstimateSubmission: FbaTransportV0RequestSubmission | null
}

const getSubmissions = async (planId: string): Promise<GetSubmissionsResponse> => {
    const response = await axios.get<GetSubmissionsResponseRaw>(`/api/fba-transport/v0/plans/${planId}/submissions`);

    const convertSubmission = (submission: FbaTransportV0RequestSubmission | [] | null): FbaTransportV0RequestSubmission | null => {
        if (submission === null || (Array.isArray(submission) && submission.length === 0)) {
            return null;
        } else if (Array.isArray(submission)) {
            return null;
        }

        return submission;
    };

    return {
        cartonDataSubmission: convertSubmission(response.data.cartonDataSubmission),
        transportDataSubmission: convertSubmission(response.data.transportDataSubmission),
        requestEstimateSubmission: convertSubmission(response.data.requestEstimateSubmission),
        confirmEstimateSubmission: convertSubmission(response.data.confirmEstimateSubmission),
        voidEstimateSubmission: convertSubmission(response.data.voidEstimateSubmission),
    };
};

const submitCartonData = async (planId: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/submit-carton-data`);
};

const submitTransportData = async (planId: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/submit-transport-data`);
};

const requestEstimate = async (planId: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/request-estimate`);
};

const confirmEstimate = async (planId: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/confirm-estimate`);
};

const voidEstimate = async (planId: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/void-estimate`);
};

const markAsShipped = async (planId: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/mark-as-shipped`);
};

const markAsDeleted = async (planId: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/mark-as-deleted`);
};

const resetSubmissions = async (planId: string): Promise<void> => {
    return axios.post(`/api/fba-transport/v0/plans/${planId}/reset-submissions`);
};

const getLabels = async (planId: string): Promise<FbaTransportV0Label[]> => {
    const response = await axios.get<{ data: FbaTransportV0Label[] }>(`/api/fba-transport/v0/plans/${planId}/labels`);

    return response.data.data;
};

export interface FnskuLabel {
    fnsku: string
    title: string
    condition: string
    expiration: string | null
}

const requestFnskuLabels = async (labels: FnskuLabel[]): Promise<void> => {
    return await axios.post(`/api/fba-transport/request-fnsku-labels`, { labels });
};

const requestShippingLabels = async (planId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v0/plans/${planId}/request-shipping-labels`);
};

const requestBoxLabels = async (planId: string, boxGroupId: string | null): Promise<void> => {
    return await axios.post(`/api/fba-transport/v0/plans/${planId}/request-box-labels`, { boxGroupId });
};

const requestPalletLabels = async (planId: string, pallets: { number: number }[]): Promise<void> => {
    return await axios.post(`/api/fba-transport/v0/plans/${planId}/request-pallet-labels`, { pallets });
};

const downloadPalletLabels = async (planId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v0/plans/${planId}/download-pallet-labels`);
};

const requestBillOfLading = async (planId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v0/plans/${planId}/request-bill-of-lading`);
};

const resizeToFourBySix = async (planId: string, labelId: string): Promise<void> => {
    return await axios.post(`/api/fba-transport/v0/plans/${planId}/labels/${labelId}/resize-to-4x6`);
};

export enum TransportStatus {
    NotAvailable = 'not_available',
    Working = 'working',

    Estimating = 'estimating',
    Estimated = 'estimated',
    ErrorOnEstimating = 'error_on_estimating',

    Confirming = 'confirming',
    Confirmed = 'confirmed',
    ErrorOnConfirming = 'error_on_confirming',

    Voiding = 'voiding',
    ErrorOnVoiding = 'error_on_voiding',
}

export enum AmazonStatus {
    Working = 'working',
    Shipped = 'shipped',
    Receiving = 'receiving',
    Cancelled = 'cancelled',
    Deleted = 'deleted',
    Closed = 'closed',
    Error = 'error',
    InTransit = 'in_transit',
    Delivered = 'delivered',
    CheckedIn = 'checked_in',
    ReadyToShip = 'ready_to_ship',
}

export interface FbaTransportV0TransportStatusCheck {
    status: TransportStatus
}

const checkTransportStatus = async (planId: string): Promise<TransportStatus> => {
    const response = await axios.post<{ status: TransportStatus }>(`/api/fba-transport/v0/plans/${planId}/check-transport-status`);

    return response.data.status;
};

const checkAmazontStatus = async (planId: string): Promise<AmazonStatus> => {
    const response = await axios.post<{ status: AmazonStatus }>(`/api/fba-transport/v0/plans/${planId}/check-amazon-status`);

    return response.data.status;
};

export interface ImportedFbaPlanItem {
    quantityShipped: string
    quantityInCase: string
    fulfillmentNetworkSku: string
    sellerSku: string
}

export interface ImportedFbaPlan {
    id: string
    name: string
    destinationId: string
    casePacked: boolean
    status: AmazonStatus
    items: Array<ImportedFbaPlanItem>
}

export interface ImportFbaPlanResponse {
    inboundShipmentId: number
    outboundShipmentId: number
    fbaShipmentId: string
    fbaTransportV0PlanId: string
}

const listPlans = async (status: AmazonStatus | null, shipmentId: string | null, refresh: boolean = false): Promise<ImportedFbaPlan[]> => {
    const url = new URL(`/api/fba-transport/list-plans`, location.origin);
    if (status) {
        url.searchParams.append('status', status);
    }
    if (shipmentId) {
        url.searchParams.append('shipment_id', shipmentId);
    }
    if (refresh) {
        url.searchParams.append('refresh', '1');
    }

    const response = await axios.get<{ shipments: ImportedFbaPlan[] }>(url.toString());

    return response.data.shipments;
};

const importPlan = async (
    name: string,
    amazonId: string,
    amazonDestinationId: string,
    casePacked: boolean,
    items: Array<ImportedFbaPlanItem>,
    warehouseId: number,
    channelId: number,
    skipInbound: boolean,
): Promise<ImportFbaPlanResponse> => {
    const response = await axios.post<ImportFbaPlanResponse>(`/api/fba-transport/import`, {
        name,
        amazonId,
        amazonDestinationId,
        casePacked,
        items,
        warehouseId,
        channelId,
        skipInbound,
    });

    return response.data;
};

const pullExpiryDatesFromShipment = async (planId: string): Promise<void> => {
    await axios.post<void>(`/api/fba-transport/v0/plans/${planId}/pull-expiry-dates-from-shipment`);
};

const getPickListUrl = async (planId: string): Promise<string> => {
    const res = await axios.get<{ url: string }>(`/api/fba-transport/v0/plans/${planId}/pick-list`);

    return res.data.url;
};

const getPackListUrl = async (planId: string): Promise<string> => {
    const res = await axios.get<{ url: string }>(`/api/fba-transport/v0/plans/${planId}/pack-list`);

    return res.data.url;
};

export default {
    getPlans,
    showPlan,
    requestPlans,
    acceptPlan,
    rejectPlan,
    changeMode,
    changeUsing2dBarcodes,
    changePartneredCarrier,
    resetBoxContents,

    updatePlanItem,

    createBoxGroup,
    updateBoxGroup,
    removeBoxGroup,
    setBoxQuantityForBoxGroup,

    createBoxGroupItem,
    updateBoxGroupItem,
    removeBoxGroupItem,

    createPallet,
    updatePallet,
    removePallet,

    setNonPartneredLtlTracking,
    setNonPartneredSmallParcelTracking,
    setPartneredLtlDetails,

    getSubmissions,
    submitCartonData,
    submitTransportData,
    requestEstimate,
    confirmEstimate,
    voidEstimate,
    checkTransportStatus,
    checkAmazontStatus,
    markAsShipped,
    markAsDeleted,
    resetSubmissions,

    getLabels,
    requestFnskuLabels,
    requestShippingLabels,
    requestBoxLabels,
    requestPalletLabels,
    downloadPalletLabels,
    requestBillOfLading,
    resizeToFourBySix,

    listPlans,
    importPlan,

    pullExpiryDatesFromShipment,

    getPickListUrl,
    getPackListUrl,
};
