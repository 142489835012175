import { UnwrapRef } from 'vue';
import axios from '@/helpers/forms/axios';
import { SparkFormType, sendForm } from '@/helpers/forms/form';
import { BaseColour, baseModelInterface } from '@/compiler/types';

export enum TagType {
    InboundShipment = 'inbound_shipment',
    Item = 'item',
    Order = 'order',
    OutboundShipment = 'outbound_shipment',
}

export interface tagInterface extends baseModelInterface {
    name: string
    companyId: number
    type: TagType
    colour: BaseColour
}

export interface createTagFormInterface {
    name: string
    colour: BaseColour
    type: TagType
}

export interface editTagFormInterface {
    name: string
    colour: BaseColour
}

const fetchTags = async (type?: TagType): Promise<tagInterface[]> => {
    const url = new URL('/api/tags', location.origin);
    if (type) {
        url.searchParams.append('type', type);
    }

    const response = await axios.get<tagInterface[]>(url.toString());

    return response.data;
};

const deleteTag = async (tagId: number): Promise<void> => {
    return axios.delete(`/api/tags/${tagId}`);
};

const createTag = async (form: UnwrapRef<SparkFormType<createTagFormInterface>>): Promise<tagInterface> => {
    const response = await sendForm({ method: 'POST', uri: '/api/tags', form });

    return response.tag;
};

const editTag = async (tagId: number, form: UnwrapRef<SparkFormType<editTagFormInterface>>): Promise<void> => {
    await sendForm({ method: 'put', uri: `/api/tags/${tagId}`, form });
};

export default {
    fetchTags,
    deleteTag,
    createTag,
    editTag,
};
