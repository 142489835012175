<template>
    <base-slideover title="Add Order Exception" :close-func="close">
        <base-alert>This order will not be assigned anymore.</base-alert>

        <div>
            <base-select
                class="mt-2"
                label="Type"
                :form="orderExceptionForm"
                form-prop="source"
                :options="sourceOptions"
            />

            <base-textarea
                id="notes"
                class="mt-2"
                label="Notes"
                placeholder="Notes..."
                :form="orderExceptionForm"
                form-prop="text"
            />
        </div>

        <template #footer>
            <base-button
                class="w-full sm:mt-0 sm:w-auto ml-2"
                text="Save"
                :click-func="submitOrderExceptionForm"
                :busy="orderExceptionForm.busy"
            />
        </template>
    </base-slideover>
</template>

<script lang="ts" setup>
import { computed, reactive } from 'vue';
import { SparkForm, sendForm } from '@/helpers/forms/form';

import { SelectOption } from '@/compiler/types';
import useInertia from '@/composables/useInertia';
import { useBarcodeScannerStore } from '@/stores/barcodeScanner';

const props = defineProps({
    itemId: {
        type: Number,
        default: 0,
    },
    orderId: {
        type: Number,
        default: 0,
    },
});

const emit = defineEmits<{
    (e: 'close'): void
}>();

const barcodeScannerStore = useBarcodeScannerStore();

const sourceOptions = computed(() => {
    const options = [
        { value: 'Location Stock Issue', name: 'Location Stock Issue' },
        { value: 'Other', name: 'Other' },
    ] as SelectOption[];

    return options;
});

const orderExceptionForm = reactive(new SparkForm({
    source: 'Location Stock Issue',
    text: '',
    item_id: props.itemId,
}));

const submitOrderExceptionForm = () => {
    sendForm({ method: 'post', uri: `/api/orders/${props.orderId}/addError`, form: orderExceptionForm }).then(() => {
        orderExceptionForm.reset();
        barcodeScannerStore.setClosed();
        useInertia().visit('/staff/orders/');
        close();
    });
};

const close = () => {
    emit('close');
};
</script>
