import dayjs, { Dayjs } from 'dayjs';
import { defineStore } from 'pinia';
import { HidDevice } from 'qz-tray';
import useAzlabelsTray from '@/composables/useAzlabelsTray';

interface State {
    printers: string[]
    loadingPrinters: boolean
    printersLoadedAt: Dayjs | null
    usbDevices: HidDevice[]
    loadingUsbDevices: boolean
    usbDevicesLoadedAt: Dayjs | null
}

export const useAzlabelsTrayStore = defineStore('azlabelsTray', {
    state: (): State => ({
        printers: [],
        loadingPrinters: false,
        printersLoadedAt: null,
        usbDevices: [],
        loadingUsbDevices: false,
        usbDevicesLoadedAt: null,
    }),
    getters: {
        lastDeviceCheck(state): Dayjs | null {
            if (!state.printersLoadedAt && !state.usbDevicesLoadedAt) {
                return null;
            }
            if (!state.printersLoadedAt) {
                return state.usbDevicesLoadedAt;
            }
            if (!state.usbDevicesLoadedAt) {
                return state.printersLoadedAt;
            }

            return state.printersLoadedAt.isAfter(state.usbDevicesLoadedAt) ? state.printersLoadedAt : state.usbDevicesLoadedAt;
        },
    },
    actions: {
        async loadPrinters(): Promise<void> {
            this.loadingPrinters = true;
            useAzlabelsTray().findPrinters().then((printers) => {
                this.printers = printers;
            }).catch(() => {
                this.printers = [];
            }).finally(() => {
                this.printersLoadedAt = dayjs();
                this.loadingPrinters = false;
            });
        },
        async loadUsbDevices(): Promise<void> {
            this.loadingUsbDevices = true;
            useAzlabelsTray().findUsbDevices().then((printers) => {
                this.usbDevicesLoadedAt = dayjs();
                this.usbDevices = printers;
            }).finally(() => {
                this.loadingUsbDevices = false;
            });
        },
    },
});
