import { Link } from '@inertiajs/vue3';
import { App } from 'vue';
import useProviders from './composables/useProviders';

// register components
export const registerBaseComponents = (app: App): void => {
    const baseComponents = import.meta.glob('./components/elements/**/Base*.vue', { eager: true });

    Object.entries(baseComponents).forEach(([path, component]) => {
        const name = path.split('/').slice(-1)[0].replace('.vue', '');

        app.component(name, (component as any).default);
    });

    app.component('Link', Link);
};

export const registerProviders = (app: App): void => {
    const { provideEcho, provideGeographyRepository } = useProviders();

    provideGeographyRepository(app);
    provideEcho(app);
};
