<template>
    <div>
        <SwitchGroup :id="id" as="div" class="flex items-center justify-between">
            <SwitchLabel v-if="label" as="span" class="flex-grow flex flex-col" passive>
                <span class="text-sm font-medium text-gray-900 mr-2">{{ label }}</span>
            </SwitchLabel>
            <Switch v-model="toggleValue" :class="switchClasses">
                <span class="sr-only">Use setting</span>
                <span aria-hidden="true" :class="spanClasses" />
            </Switch>
        </SwitchGroup>

        <!-- Error Text -->
        <p v-if="form && form.errors.has(formProp)" class="mt-1 text-sm text-red-600">
            <span>{{ form.errors.get(formProp) }}</span>
        </p>
    </div>
</template>

<script lang="ts" setup>
import { PropType, UnwrapRef, computed, onMounted, ref, watch } from 'vue';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import get from 'lodash/get';
import { SparkFormType } from '@/helpers/forms/form';

const props = defineProps({
    id: {
        type: String,
        default: () => {
            return `Input-${Math.floor(Math.random() * 100000)}${1}`;
        },
    },
    label: {
        type: String,
        default: null,
    },
    form: {
        type: Object as PropType<UnwrapRef<SparkFormType<any>>>,
        default: null,
    },
    formProp: {
        type: String,
        default: null,
    },
    reverse: {
        type: Boolean,
        default: false,
    },
    value: {
        type: Boolean,
        default: undefined,
    },
});

const emit = defineEmits<{
    (event: 'update:value', value: boolean): boolean
}>();

const toggleValue = ref(false);
const model = defineModel<boolean>();

if (props.value !== undefined) {
    model.value = props.value;
}

const isForm = computed(() => {
    return Boolean(props.form && props.formProp);
});

watch(toggleValue, (newValue) => {
    if (isForm.value) {
        props.form.setProperty(props.formProp, newValue);
    }
    emit('update:value', newValue);
    model.value = newValue;
});

watch(() => props.value, (newValue) => {
    if (newValue) {
        toggleValue.value = newValue;
    }
});

watch(() => props.form, (newValue) => {
    if (isForm.value) {
        toggleValue.value = get(props.form, props.formProp);
    }
}, { deep: true });

onMounted(() => {
    if (isForm.value) {
        toggleValue.value = get(props.form, props.formProp);
    } else if (typeof model.value === 'boolean') {
        toggleValue.value = model.value;
    } else if (props.value !== undefined) {
        toggleValue.value = props.value;
    }
});

const spanClasses = computed(() => {
    let classes
        = 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200';

    if (props.reverse) {
        classes += toggleValue.value ? ' translate-x-0' : ' translate-x-5';
    } else {
        classes += toggleValue.value ? ' translate-x-5' : ' translate-x-0';
    }

    return classes;
});

const switchClasses = computed(() => {
    let classes
        = 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500';

    if (props.reverse) {
        classes += toggleValue.value ? ' bg-gray-200' : ' bg-green-600';
    } else {
        classes += toggleValue.value ? ' bg-green-600' : ' bg-gray-200';
    }

    return classes;
});
</script>
