<template>
    <div>
        <div :class="headerClass">
            <div class="sm:hidden my-1">
                <label
                    :for="selectId"
                    class="sr-only"
                >Select a tab</label>
                <select
                    :id="selectId"
                    v-model="tabSelect"
                    class="focus:ring-primary-500 focus:border-primary-500 block w-full"
                    @input="handleSelectEvent"
                >
                    <option
                        v-for="tab in allTabs"
                        :key="tab.name"
                        :value="tab.name"
                    >
                        {{ snakeToTitleCase(tab.name) }}
                    </option>
                </select>
            </div>
            <div class="mb-0.5" :class="[$slots.header ? 'flex flex-row w-full' : '']">
                <div class="hidden sm:block">
                    <div class="border-b border-gray-200">
                        <nav class="flex -mb-px space-x-8">
                            <a
                                v-for="(tab, index) in allTabs"
                                :key="tab.name"
                                :href="`#${tab.name}`"
                                :class="aClass(tab, index)"
                                :aria-current="tab.active ? 'page' : undefined"
                                class="group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
                                @click="switchTab(tab.name)"
                            >
                                <component
                                    :is="tab.icon as any"
                                    v-if="tab.icon"
                                    class="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600"
                                />

                                <span>{{ snakeToTitleCase(tab.name) }}</span>

                                <span
                                    v-if="tab.count !== undefined"
                                    class="ml-1 inline-flex items-center py-0.5 rounded-full whitespace-nowrap font-medium px-2.5 text-xs leading-4"
                                    :class="tab.count > 0 ? 'bg-primary-100 text-primary-800' : 'bg-primary-100 text-gray-400'"
                                >{{ numeric(tab.count) }}</span>
                            </a>
                        </nav>
                    </div>
                </div>
                <slot name="header" />
            </div>
        </div>

        <div
            v-for="tab in allTabs"
            v-show="tabActive(tab.name)"
            :key="tab.name"
            role="tabcard"
        >
            <slot :name="tab.name" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType, watch } from 'vue';
import useTabs, { Tab } from '@/composables/useTabs';
import useFormatters from '@/composables/useFormatters';

const props = defineProps({
    tabs: {
        type: Array as PropType<Tab[]>,
        default: null,
    },
    headerClass: {
        type: String,
        default: '',
    },
});

const selectId = `tabSelect-${Math.random().toString(36).slice(-5)}`;
const { numeric, snakeToTitleCase } = useFormatters();

const handleSelectEvent = (event: Event) => {
    const target = event.target as HTMLSelectElement;

    switchTab(target.value);
};

const { allTabs, setTabs, aClass, tabSelect, switchTab, tabActive } = useTabs(props.tabs);

watch(() => props.tabs, (newTabs: Tab[]) => {
    if (JSON.stringify(props.tabs) === JSON.stringify(allTabs.value)) return;

    setTabs(newTabs);
}, { deep: true });
</script>
