import labels from './labels';
import packs from './packs';
import picks from './picks';
import upload from './upload';

export default {
    labels,
    packs,
    picks,
    upload,
};
