import { defineStore } from 'pinia';
import { scannerOptions } from '@/composables/usePickOrders';

interface State {
    open: boolean
    loading: boolean
    scannerOptions: scannerOptions | null
    scanSkipped: boolean
    hardwareScan: string
    barcodeScan: string
    correctBarcodeScan: string
}

export const useBarcodeScannerStore = defineStore('barcodeScanner', {
    state: (): State => ({
        open: false,
        loading: false,
        scannerOptions: null,
        scanSkipped: false,
        hardwareScan: '',
        barcodeScan: '',
        correctBarcodeScan: '',
    }),
    getters: {
        isOpen: state => state.open,
        isLoading: state => state.loading,
        getScannerOptions: state => state.scannerOptions,
    },
    actions: {
        setOpen(value: boolean = true): void {
            this.open = value;
        },
        setClosed(): void {
            this.open = false;
        },
        setLoading(value: boolean = true): void {
            this.loading = value;
        },
        setScannerOptions(value: scannerOptions | null): void {
            this.scannerOptions = value;
        },
        setScanSkipped(value: boolean): void {
            this.scanSkipped = value;
        },
        setHardwareScan(value: string): void {
            this.hardwareScan = value;
        },
        setBarcodeScan(value: string): void {
            this.barcodeScan = value;
        },
        setCorrectBarcodeScan(value: string): void {
            this.correctBarcodeScan = value;
        },
    },
});
