import axios from '@/helpers/forms/axios';
import { sendForm } from '@/helpers/forms/form';

export const fetchMerchantTermsAndConditionsPdf = async (): Promise<{ pdfUrl: string }> => {
    const response = await axios.get<{ pdfUrl: string }>('/api/serviceProviderConfiguration/merchantTermsAndConditions/pdf');

    return response.data;
};

export interface setMerchantTermsAndConditionsPdfFormInterface {
    pdfUrl: string
}

export const setMerchantTermsAndConditionsPdf = async (form: setMerchantTermsAndConditionsPdfFormInterface): Promise<void> => {
    return sendForm({
        method: 'POST',
        uri: '/api/serviceProviderConfiguration/merchantTermsAndConditions/pdf',
        form,
    });
};

export default {
    fetchMerchantTermsAndConditionsPdf,
    setMerchantTermsAndConditionsPdf,
};
