import axios, { fetchPaginatedDataFunction } from '@/helpers/forms/axios';

export interface PrintJobEventRecord {
    id: string
    event: 'print_job_failed' | 'print_job_completed' | 'print_job_cancelled' | 'print_job_attempted'
    occurredAt: string
    data: {
        failureReason?: string
        cancellationReason?: string
    }
}

export interface PrintJob {
    id: string
    createdAt: string
    updatedAt: string
    status: 'waiting' | 'completed' | 'cancelled'
    name: string
    source: string
    printer: {
        identifier: string
        brand?: string
        density?: number
        orientation?: 'auto' | 'landscape' | 'portrait'
    }
    events: PrintJobEventRecord[]
}

export interface DetailedPrintJob extends PrintJob {
    document: {
        url: string
        heightMm: number
        widthMm: number
        type: 'pdf' | 'zpl' | 'epl'
    }
}

const fetchPrintJobs = fetchPaginatedDataFunction<PrintJob>('/api/printing/jobs');

const attemptPrint = async (printJobId: string): Promise<DetailedPrintJob> => {
    const response = await axios.post<DetailedPrintJob>(`/api/printing/jobs/${printJobId}/attempt`);

    return response.data;
};

const printFailed = async (printJobId: string, failureReason: string): Promise<void> => {
    const response = await axios.post<void>(`/api/printing/jobs/${printJobId}/failed`, { failureReason });

    return response.data;
};

const cancelPrint = async (printJobId: string, cancellationReason: string): Promise<void> => {
    const response = await axios.post<void>(`/api/printing/jobs/${printJobId}/cancel`, { cancellationReason });

    return response.data;
};

const completePrint = async (printJobId: string): Promise<void> => {
    const response = await axios.post<void>(`/api/printing/jobs/${printJobId}/complete`);

    return response.data;
};

export default {
    fetchPrintJobs,
    attemptPrint,
    printFailed,
    cancelPrint,
    completePrint,
};
