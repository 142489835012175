<template>
    <button
        class="border-b-[2.5px] border-primary-500 hover:border-primary-700 leading-none inline-block"
        @click="click"
    >
        <div class="flex flex-row gap-1 items-end">
            <div v-if="!busy && icon">
                <component
                    :is="icon as any"
                    v-if="!clicked"
                    class="h-4 w-4"
                />
                <Transition
                    enter-active-class="origin-right transition-transform ease-in-out duration-200"
                    enter-from-class="scale-y-0"
                    enter-to-class="scale-y-100"
                    leave-active-class="hidden"
                >
                    <CheckIcon
                        v-if="clicked"
                        class="w-4 h-4 inline text-green-500"
                    />
                </Transition>
            </div>

            <ArrowPathIcon
                v-if="busy"
                class="w-4 h-4 inline animate-spin"
            />

            <slot />
        </div>
    </button>
</template>

<script lang="ts" setup>
import { ArrowPathIcon, CheckIcon } from '@heroicons/vue/20/solid';
import { ref } from 'vue';

defineProps({
    icon: {
        type: Function,
        default: null,
    },
    busy: {
        type: Boolean,
        default: false,
    },
});

const clicked = ref(false);
const click = () => {
    clicked.value = true;

    setTimeout(() => {
        clicked.value = false;
    }, 2000);
};
</script>
