<template>
    <li>
        <div class="relative pb-8">
            <span
                v-if="!last"
                class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
            />
            <div
                class="relative flex space-x-3"
                :class="{ 'animate-pulse': status === 'in-progress' }"
            >
                <div>
                    <span
                        class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                        :class="iconColourClass"
                    >
                        <CheckIcon
                            v-if="status === true"
                            class="h-5 w-5 text-white"
                            aria-hidden="true"
                        />
                        <XMarkIcon
                            v-if="status === 'failed'"
                            class="h-5 w-5 text-white"
                            aria-hidden="true"
                        />
                        <EllipsisHorizontalIcon
                            v-if="status !== true && status !== 'failed'"
                            class="h-5 w-5 text-white"
                            aria-hidden="true"
                        />
                    </span>
                </div>
                <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                        <p class="text-sm text-gray-500">
                            {{ text }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue';
import { CheckIcon, EllipsisHorizontalIcon, XMarkIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    text: {
        type: String,
        default: '',
    },
    status: {
        type: [Boolean, String] as PropType<boolean | 'failed' | 'in-progress'>,
        default: false,
    },
    last: {
        type: Boolean,
        default: false,
    },
});

const iconColourClass = computed(() => {
    if (props.status === true) {
        return 'bg-green-400';
    }
    if (props.status === 'failed') {
        return 'bg-red-400';
    }
    if (props.status === 'in-progress') {
        return 'bg-yellow-400';
    }

    return 'bg-gray-400';
});
</script>
