import { ItemConditionTypeEnum, itemInterface } from '../repositories/items';
import axios from '@/helpers/forms/axios';

export interface inventorySearchFilters {
    conditionType: ItemConditionTypeEnum
}

export const searchInventory = async (query: string, abortSignal: AbortSignal): Promise<itemInterface[]> => {
    const response = await axios.post<{ items: itemInterface[] }>('/api/inventory/search', {
        q: query,
    }, {
        signal: abortSignal,
    });

    return response.data.items;
};

export const searchInventoryByTitleSimilarity = async (title: string, filters?: inventorySearchFilters): Promise<itemInterface[]> => {
    const response = await axios.post<{ items: itemInterface[] }>('/api/inventory/search', {
        q: title,
        mode: 'title_similarity',
        filters,
    });

    return response.data.items;
};
