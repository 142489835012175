import axios from '@/helpers/forms/axios';

export interface trackingNumberInterface {
    id: number
    createdAt: string
    updatedAt: string
    number: string
    carrier: string
    deliveredAt: string
    eta: string
    url: string
    packageIdentifier: string
    status?: string
}

const markAsDelivered = (
    trackingNumberId: number,
): Promise<void> => {
    return axios.post(`/api/trackingNumbers/${trackingNumberId}/markAsDelivered`);
};

export default {
    markAsDelivered,
};
