import axios from '@/helpers/forms/axios';

export interface barcodeLabelInterface {
    barcode: string
    title: string
}

const requestBarcodeLabels = async (labels: barcodeLabelInterface[]): Promise<void> => {
    await axios.post('/api/labels/barcode', { labels });
};

export default {
    requestBarcodeLabels,
};
