import { itemInterface } from '../repositories/items';
import { channelInterface } from '.';
import axios from '@/helpers/forms/axios';

export interface listingInterface {
    id: number
    createdAt: string
    updatedAt: string
    channelId: number
    channelIdentifier: string
    itemId: number
    item: itemInterface
    status: string
    channel: channelInterface
    amazonPrepInstructions: string[]
    shopifyInventoryItemId: number
}

const fetchListings = async (channelId: number): Promise<listingInterface[]> => {
    const response = await axios.get<{ listings: listingInterface[] }>(`/api/channels/${channelId}/listings`);

    return response.data.listings;
};

const findListingsByChannelIdentifiers = async (channelId: number, channelIdentifier: string[]): Promise<listingInterface[]> => {
    const response = await axios.post<{ listings: listingInterface[] }>(`/api/channels/${channelId}/find-listings-by-channel-identifiers`, {
        channelIdentifier,
    });

    return response.data.listings;
};

export default {
    fetchListings,
    findListingsByChannelIdentifiers,
};
