<template>
    <div class="col-span-4 mb-0.5">
        <div v-if="breadcrumbs && breadcrumbs.length">
            <nav class="sm:hidden">
                <BaseLink
                    :href="breadcrumbs[0].link"
                    class="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 focus:outline-none focus:underline transition duration-150 ease-in-out"
                >
                    <ArrowLeftIcon class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" />Back
                </BaseLink>
            </nav>

            <nav class="hidden sm:flex items-center text-sm leading-5 font-medium">
                <template v-for="(breadcrumb, index) in breadcrumbs" :key="breadcrumb.link">
                    <BaseLink
                        :href="breadcrumb.link"
                        class="focus:outline-none transition duration-150 ease-in-out"
                        :class="breadcrumbs.length !== (index + 1) ? 'text-gray-500 focus:underline hover:text-gray-700' : 'text-gray-400 cursor-default'"
                    >
                        {{ truncate(breadcrumb.text, 30) }}
                    </BaseLink>
                    <ChevronRightIcon
                        v-if="breadcrumbs.length !== (index + 1)"
                        class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400"
                    />
                </template>
            </nav>
        </div>
        <div class="flex sm:flex-row flex-col items-center justify-between">
            <slot name="title">
                <div class="flex-1 min-w-0">
                    <h2
                        class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate"
                    >
                        {{
                            title
                        }}
                    </h2>
                </div>
            </slot>
            <div v-if="$slots.footer" class="flex-shrink-0 flex md:mt-0 md:ml-4">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { ArrowLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';
import BaseLink from './BaseLink.vue';
import useFormatters from '@/composables/useFormatters';

export interface breadcrumbInterface {
    text: string
    link: string
}

defineProps({
    breadcrumbs: {
        type: Array as PropType<Array<breadcrumbInterface>>,
        default: null,
    },
    title: {
        type: String,
        default: null,
    },
});

const { truncate } = useFormatters();
</script>
