import axios from '@/helpers/forms/axios';
import { SparkForm, sendForm } from '@/helpers/forms/form';

export enum IntegrationProvider {
    Intercom = 'intercom',
    Crisp = 'crisp',
}

export interface integrationSetting {
    integrationProvider: IntegrationProvider
    identifier: string
}

export interface nullIntegrationSetting {
    integrationProvider: null
    identifier: null
}

export interface updateIntegrationSettingForm {
    integrationProvider: IntegrationProvider
    identifier: string
    identityVerificationSecret: string
}

const getIntegrationSetting = async (): Promise<integrationSetting | nullIntegrationSetting> => {
    const response = await axios.get<integrationSetting>('/api/staff/settings/customer-support-integration');
    return response.data;
};

const updateIntegrationSetting = async (form: updateIntegrationSettingForm): Promise<void> => {
    await sendForm({ method: 'PUT', uri: '/api/staff/settings/customer-support-integration', form });
};

const clearIntegrationSetting = async (): Promise<void> => {
    await sendForm({ method: 'DELETE', uri: '/api/staff/settings/customer-support-integration', form: new SparkForm({}) });
};

export default {
    getIntegrationSetting,
    updateIntegrationSetting,
    clearIntegrationSetting,
};
