<template>
    <span class="relative inline-flex group">
        <button
            id="navOperationsButton"
            class="relative inline-flex flex-row items-center group-hover:bg-gray-100/75 p-1.5 rounded-lg text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 focus:ring-primary-500"
            @click="openOperations"
        >
            <span class="sr-only">View operations</span>
            <div class="relative">
                <CpuChipIcon class="h-6 w-6 z-10" aria-hidden="true" />
                <span v-if="operationsStore.activeOperationsCount" class="flex absolute inset-0 items-center justify-center pointer-events-none -z-1">
                    <span class="relative inline-flex rounded-full h-3 w-3 backdrop-blur-md shadow-lg drop-shadow-xl animate-pulse" :style="dotStyle" />
                </span>
            </div>

            <span class="ml-1">{{ operationsStore.activeOperationsCount }}</span>
        </button>
    </span>
    <teleport to="body">
        <OperationListOverlay
            :open="showOperations"
            @close="showOperations = false"
        />
    </teleport>
</template>

<script lang="ts" setup>
import { CpuChipIcon } from '@heroicons/vue/24/outline';
import { computed, ref } from 'vue';
import OperationListOverlay from '@/components/operations/OperationListOverlay.vue';
import { useOperationsStore } from '@/stores/operations/operations';
import { useExportOperationsStore } from '@/stores/operations/exportOperations';

const showOperations = ref(false);
const openOperations = () => {
    showOperations.value = true;
};

const dotStyle = computed(() => ({
    background: 'radial-gradient(circle, rgba(230, 113, 0, 0.8) 0%, rgba(230, 113, 0, 0.2) 90%, rgba(230, 113, 0, 0.0) 90%)',
}));

const operationsStore = useOperationsStore();
operationsStore.addOperationSource('exportOperations', useExportOperationsStore().getOperationSource());
</script>
