import { defineStore } from 'pinia';

export interface crispWidgetSettings {
    userName: string
    userEmail: string
    identityHash: string
    merchantName: string
}

export const useCrispWidget = defineStore('crisp', {
    state: () => ({
        installed: false,
        booted: false,
    }),
    actions: {
        install(appId: string) {
            if (this.installed) {
                console.warn('Crisp widget already installed');
                return;
            }

            // THIS CODE IS PROVIDED BY CRISP
            (<any>window).$crisp = [];
            (<any>window).CRISP_WEBSITE_ID = appId;

            (function () {
                const d = document;
                const s = d.createElement('script');
                s.src = 'https://client.crisp.chat/l.js';
                s.async = true;
                d.getElementsByTagName('head')[0].appendChild(s);
            })();

            this.installed = true;
        },
        boot(settings: crispWidgetSettings) {
            if (!this.installed) {
                console.warn('Crisp widget not installed');
                return;
            }

            const c = (<any>window).$crisp;
            c.push(['do', 'session:reset']);
            c.push(['set', 'user:email', [settings.userEmail, settings.identityHash]]);
            c.push(['set', 'user:nickname', [settings.userName]]);
            c.push(['set', 'user:company', [settings.merchantName]]);

            this.booted = true;
        },
    },
});
