import { UnwrapRef } from 'vue';
import { userInterface } from './users';
import axios from '@/helpers/forms/axios';
import { SparkFormType, sendFileForm, sendForm } from '@/helpers/forms/form';

export enum MerchantUserRole {
    Owner = 'owner',
    Assistant = 'client-assistant',
}

export interface merchantInterface {
    id: number
    name: string
    notes: string
    primaryEmail: string
    photoUrl: string
    billingCycle: 'monthly' | 'manual'
    perItemAdjustment: number
    enabled: boolean
    isOrdersEnabled: boolean
    isAmazonShipmentsEnabled: boolean
}

export interface fileInterface {
    name: string
    file: any
}

const fetchMerchants = async (): Promise<merchantInterface[]> => {
    const response = await axios.get<{ data: merchantInterface[] }>('/api/merchants');

    return response.data.data;
};

export const fetchMerchant = async (merchantId: number): Promise<merchantInterface> => {
    const response = await axios.get<{ data: merchantInterface }>(`/api/merchants/${merchantId}`);

    return response.data.data;
};

export const fetchMerchantOwner = async (merchantId: number): Promise<userInterface | undefined> => {
    const response = await axios.get<{ data?: userInterface }>(`/api/merchants/${merchantId}/owner`);

    return response.data.data;
};

export interface registerMerchantFormInterface {
    name: string
    primaryEmail: string
}

export const createMerchant = async (form: UnwrapRef<SparkFormType<registerMerchantFormInterface>>): Promise<merchantInterface> => {
    const response = await sendForm({
        method: 'POST',
        uri: '/api/merchants',
        form,
    });

    return response.merchant;
};

export const updateMerchantProfilePhoto = async (form: UnwrapRef<SparkFormType<object>>, files: fileInterface[], currentMerchantId: number): Promise<void> => {
    await sendFileForm({
        method: 'POST',
        uri: `/api/merchants/${currentMerchantId}/photo`,
        form,
        files,
    });
};

export const updateMerchantName = async (form: UnwrapRef<SparkFormType<object>>, currentMerchantId: number): Promise<void> => {
    await sendForm({
        method: 'PUT',
        uri: `/api/merchants/${currentMerchantId}/name`,
        form,
    });
};

export const deleteMerchant = async (merchantId: number): Promise<void> => {
    await axios.delete(`/api/merchants/${merchantId}`);
};

export default {
    fetchMerchants,
    fetchMerchant,
    fetchMerchantOwner,
    deleteMerchant,
    createMerchant,
};
