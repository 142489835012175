import { ComputedRef, onMounted, ref } from 'vue';

export interface Tab {
    name: string
    active: boolean
    count?: number | ComputedRef<number>
    icon?: Function
}

export default function useTabs(tabsData: Tab[]) {
    const allTabs = ref<Tab[]>(tabsData);
    const tabSelect = ref('');

    const setTabs = (tabs: Tab[]) => {
        allTabs.value = tabs as any;

        if (tabSelect.value) {
            switchTab(tabSelect.value);
        }
    };

    const switchTab = (name: string): void => {
        if (allTabs.value.length === 0) return;

        const tabIndex = allTabs.value.findIndex(tab => tab.name === name);

        if (tabIndex === -1) {
            return switchTab(allTabs.value[0].name);
        }

        for (let i = 0; i < allTabs.value.length; i++) {
            allTabs.value[i].active = false;
        }

        allTabs.value[tabIndex].active = true;

        location.hash = name;
    };

    const selectTabFromHash = () => {
        let hash = window.location.hash.substring(1);

        const parameters = hash.split('/')?.shift();

        if (!parameters) {
            return;
        }

        hash = decodeURI(parameters);

        if (allTabs.value.find(tab => tab.name === hash)) {
            switchTab(hash);
        }
    };

    const tabActive = (name: string) => {
        const tab = allTabs.value.find(tab => tab.name === name);

        if (!tab) {
            return false;
        }

        if (tab.active) {
            tabSelect.value = tab.name;
        }

        return tab.active;
    };

    const aClass = (tab: Tab, index: number) => {
        let classes = '';
        if (tabActive(tab.name)) {
            classes += 'border-primary-500 text-primary-600 focus:text-primary-800 focus:border-primary-700';
        } else {
            classes += 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300';
        }
        if (index !== 0) {
            classes += ' ml-8';
        }
        return classes;
    };

    const tabCount = (tab: Tab) => {
        if (!tab.count) {
            return false;
        }

        return tab.count;
    };

    onMounted(() => {
        selectTabFromHash();
    });

    return {
        allTabs,
        setTabs,
        tabSelect,
        switchTab,
        tabActive,
        aClass,
        tabCount,
    };
}
