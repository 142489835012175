import axios from '@/helpers/forms/axios';

export interface priceRecordInterface {
    amount: number
    effectiveBefore: string
    setAt: string
    setByUserId: number
}

export interface companyServiceInterface {
    id: number
    createdAt: string
    updatedAt: string
    archivedAt: string | null
    name: string
    type: string
    unit: string
    whenToCharge: 'attached' | 'always'
    charge: number
    advancedOptions: any
    companyId: number
    deletable: boolean
    priceRecords?: priceRecordInterface[]
}

export interface companyServicePivotInterface extends companyServiceInterface {
    companyServiceId: number
    createdAt: string
    inboundShipmentId: number
    quantity: number
    updatedAt: string
}

export interface companyServiceWithPivotInterface extends companyServiceInterface {
    pivot: companyServicePivotInterface
}

export const fetchCompanyServices = async (type?: string[]): Promise<companyServiceInterface[]> => {
    let uri = '/api/services';
    if (type && type.length) {
        uri += `?type[]=${type.join('&type[]=')}`;
    }

    const response = await axios.get<{ services: companyServiceInterface[] }>(uri);

    return response.data.services;
};

export const fetchCompanyService = async (companyServiceId: number): Promise<companyServiceInterface> => {
    const response = await axios.get<{ service: companyServiceInterface }>(`/api/services/${companyServiceId}`);

    return response.data.service;
};

export const deleteService = async (companyServiceId: number) => {
    return await axios.delete(`/api/services/${companyServiceId}`);
};

export const archiveService = async (companyServiceId: number) => {
    return await axios.post(`/api/services/${companyServiceId}/archive`);
};

export const fetchArchivedServices = async (): Promise<companyServiceInterface[]> => {
    const response = await axios.get<{ services: companyServiceInterface[] }>('/api/services/archived');
    return response.data.services;
};
