<template>
    <dl>
        <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
            <span>{{ title }}</span>
            <div v-if="count" class="inline ml-2">
                <BaseLink v-if="countHref" :href="countHref">
                    <base-badge class="hover:bg-primary-200 hover:underline">
                        {{ numeric(count) }}
                    </base-badge>
                </BaseLink>
                <base-badge v-if="!countHref">
                    {{ numeric(count) }}
                </base-badge>
            </div>
        </dt>
        <dd class="mt-1 text-3xl leading-9 font-semibold text-gray-900">
            <span v-if="stat">{{ stat }}</span>
            <LocalMoney v-if="money" :original-money="money" />
        </dd>
    </dl>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import BaseLink from './BaseLink.vue';
import LocalMoney from './LocalMoney.vue';
import useFormatters from '@/composables/useFormatters';
import { moneyInterface } from '@/compiler/types';

defineProps({
    title: {
        type: String,
        default: null,
    },
    count: {
        type: Number,
        default: null,
    },
    countHref: {
        type: String,
        default: null,
    },
    stat: {
        type: String,
        default: null,
    },
    money: {
        type: Object as PropType<moneyInterface>,
        default: null,
    },
});

const { numeric } = useFormatters();
</script>
