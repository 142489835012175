import axios from '@/helpers/forms/axios';
import { SparkForm, sendFileForm } from '@/helpers/forms/form';

const printShippingLabels = async (order_id: number) => {
    const response = await axios.post<{ url?: string }>(`/api/orders/${order_id}/printShippingLabels`);

    return response.data;
};

const voidLabels = async (order_id: number) => {
    return await axios.post(`/api/orders/${order_id}/voidLabels`);
};

const uploadShippingLabel = async (order_id: number, file: File) => {
    await sendFileForm({
        method: 'post',
        uri: `/api/orders/${order_id}/uploadShippingLabel`,
        form: new SparkForm({}),
        files: [{ name: 'file', file }],
        parameters: [],
    });
};

export default {
    printShippingLabels,
    voidLabels,
    uploadShippingLabel,
};
