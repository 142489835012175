<template>
    <TransitionRoot as="template" :show="open">
        <Dialog static as="div" class="fixed z-10 inset-0 overflow-y-auto" :open="dialogOpen" @close="close">
            <div
                :id="id"
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >&#8203;</span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <DialogPanel
                        class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6"
                        :class="rootDivClasses"
                    >
                        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                            <button />
                            <!-- To avoid autofocusing the x button -->
                            <button
                                type="button"
                                class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                @click="close"
                            >
                                <span class="sr-only">Close</span>
                                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div class="sm:flex sm:items-start">
                            <div
                                v-if="icon"
                                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
                                :class="iconDivClasses"
                            >
                                <component
                                    :is="icon"
                                    v-if="icon"
                                    class="h-6 w-6"
                                    :class="iconClasses"
                                />
                            </div>
                            <div
                                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full overflow-auto p-1"
                            >
                                <slot />
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <slot name="footer" />
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script lang="ts" setup>
import { PropType, computed, nextTick, ref, watch } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { BaseColour } from '@/compiler/types';

const props = defineProps({
    id: {
        type: String,
        default: null,
    },
    open: {
        type: Boolean,
        default: false,
    },
    closeFunc: {
        type: Function,
        default: null,
    },
    icon: {
        type: Function,
        default: null,
    },
    size: {
        type: String,
        validator: (prop: string) => ['3xl', '2xl', 'xl', 'lg', 'md', 'sm'].includes(prop),
        default: 'md',
    },
    colour: {
        type: String as PropType<BaseColour>,
        default: BaseColour.Primary,
    },
});
const close = () => {
    if (props.closeFunc) {
        props.closeFunc();
    }
};

const rootDivClasses = computed(() => {
    let classes = '';

    const widthClasses: { [key: string]: string } = {
        '3xl': 'sm:max-w-3xl',
        '2xl': 'sm:max-w-2xl',
        'xl': 'sm:max-w-xl',
        'lg': 'sm:max-w-lg',
        'md': 'sm:max-w-md',
        'sm': 'sm:max-w-sm',
    };

    classes += ` ${(widthClasses[props.size] || widthClasses.md)}`;

    return classes;
});

const iconClasses = computed(() => {
    const colourClasses: { [key: string]: string } = {
        primary: 'text-primary-600',
        yellow: 'text-yellow-600',
        red: 'text-red-600',
        gray: 'text-gray-600',
    };

    return colourClasses[props.colour];
});

const iconDivClasses = computed(() => {
    const colourClasses: { [key: string]: string } = {
        primary: 'bg-primary-100',
        yellow: 'bg-yellow-100',
        red: 'bg-red-100',
        gray: 'bg-gray-100',
    };

    return colourClasses[props.colour];
});

// smooth open
const dialogOpen = ref(props.open);
watch(() => props.open, () => nextTick(() => dialogOpen.value = props.open));
</script>
