import { defineStore } from 'pinia';

interface State {
    sessionExpired: boolean
}

export const useSessionExpiredStore = defineStore('sessionExpired', {
    state: (): State => ({
        sessionExpired: false,
    }),
    getters: {
        getSessionExpired: state => state.sessionExpired,
    },
    actions: {
        setSessionExpired(value: boolean): void {
            this.sessionExpired = value;
        },
    },
});
