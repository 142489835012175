import useFormatters from './useFormatters';
import useStations from './useStations';
import usePopupNotifications from './usePopupNotifications';
import { userNotificationInterface } from '@/api/notifications';
import { UserWebsocketSubscriber } from '@/helpers/userWebsocketListener';
import API from '@/api';

export default function useUserWebsocketListener() {
    const { keysToCamelCase } = useFormatters();
    const { downloadLabelsIfNoAvailablePrinter } = useStations();
    const { notifyError, notifyAction } = usePopupNotifications();

    const isUserNotification = (notification: any): notification is userNotificationInterface => {
        if (typeof notification !== 'object') {
            return false;
        }

        return 'id' in notification;
    };
    const isPrintJobCreatedNotification = (notification: any): notification is { printJobId: string, userId: number } => {
        if (typeof notification !== 'object') {
            return false;
        }

        return 'printJobId' in notification;
    };

    const defaultWebsocketSubscriber: UserWebsocketSubscriber = {
        id: 'default',
        onOneTab: true,
        callback: (event: string, data: object) => {
            if (isUserNotification(data)) {
                handleUserNotification(data);
                return;
            }
            if (isPrintJobCreatedNotification(data)) {
                handlePrintJobCreated(data);
            }
        },
    };

    const handlePrintJobCreated = (data: { printJobId: string, userId: number }) => {
        API.printing.attemptPrint(data.printJobId).then((printJob) => {
            useStations().handlePrintJob(printJob);
        });
    };

    const handleUserNotification = (notification: userNotificationInterface) => {
        if (notification.notificationType === 'error') {
            notifyError(notification.body);
            return;
        }

        if (notification.notificationType === 'action') {
            notifyAction(
                notification.body,
                notification.actionText,
                () => window.open(notification.actionUrl, '_blank'),
            );

            if (notification.actionType) {
                if (notification.actionType === 'print_labels') {
                    const printData = keysToCamelCase(notification.actionData);

                    if (printData.printerInfo) {
                        printData.printerInfo = keysToCamelCase(printData.printerInfo);
                    }

                    downloadLabelsIfNoAvailablePrinter(printData);
                }
            }
        }
    };

    return {
        defaultWebsocketSubscriber,
    };
}
