<template>
    <base-card v-if="!loadingMerchants" class="min-w-full">
        <div class="flow-root my-6">
            <base-alert
                v-if="!merchants.length"
                :colour="BaseColour.Yellow"
                class="mb-4"
            >
                No merchants were found for your account.
            </base-alert>
            <ul role="list" class="-my-5 divide-y divide-gray-200">
                <li v-for="invitation in invitations" :key="invitation.id" class="py-4">
                    <div class="flex items-center space-x-4">
                        <div class="flex-shrink-0">
                            <img
                                class="h-8 w-8 rounded-full"
                                :src="invitation.merchant.photoUrl"
                                :alt="invitation.merchant.name"
                            >
                        </div>
                        <div class="flex-1 min-w-0">
                            <base-badge size="sm" :colour="BaseColour.Yellow">
                                Pending Invitation
                            </base-badge>

                            <p
                                class="text-sm font-medium text-gray-900 truncate"
                            >
                                {{ invitation.merchant.name }}
                            </p>
                            <p class="text-sm text-gray-500 truncate">
                                <span>{{ slugToTitleCase(invitation.role) }}</span>
                            </p>
                        </div>
                        <div class="flex flex-col space-y-2">
                            <base-button
                                :icon="CheckCircleIcon"
                                :colour="BaseColour.Green"
                                size="xs"
                                text="Accept"
                                design="outline"
                                :click-func="() => acceptInvitation(invitation)"
                                :busy="respondingToInvitation"
                            />
                            <base-button
                                :icon="CheckCircleIcon"
                                :colour="BaseColour.Red"
                                size="xs"
                                text="Decline"
                                design="outline"
                                :click-func="() => declineInvitation(invitation)"
                                :busy="respondingToInvitation"
                                :confirm-action="true"
                                confirm-title="Decline Invitation"
                            />
                        </div>
                    </div>
                </li>
                <li v-for="merchant in merchants" :key="merchant.id" class="py-4">
                    <div class="flex items-center space-x-4">
                        <div class="flex-shrink-0">
                            <img
                                class="h-8 w-8 rounded-full"
                                :src="merchant.photoUrl"
                                :alt="merchant.name"
                            >
                        </div>
                        <div class="flex-1 min-w-0">
                            <p
                                class="text-sm font-medium text-gray-900 truncate"
                            >
                                {{ merchant.name }}
                            </p>
                            <p class="text-sm text-gray-500 truncate">
                                <span v-for="role in merchant.roles" :key="role">{{ slugToTitleCase(role) }}</span>
                            </p>
                        </div>
                        <div>
                            <base-button
                                :icon="CheckCircleIcon"
                                text="Manage"
                                design="outline"
                                :click-func="() => selectMerchant(merchant)"
                                :busy="selectingMerchant"
                            />
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <template v-if="hasRegisterMerchantsPermission" #footer>
            <base-button
                :icon="PlusIcon"
                :colour="BaseColour.Gray"
                design="outline"
                size="xs"
                text="Create a new Merchant"
                href="/register-merchant"
            />
        </template>
    </base-card>

    <base-alert v-if="loadingMerchants" class="my-2">
        <div class="flex flex-row items-center">
            <base-spinner class="h-4 mr-1" />
            <p>Loading merchants...</p>
        </div>
    </base-alert>
</template>

<script lang="ts" setup>
import { CheckCircleIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { BaseColour } from '@/compiler/types';
import { merchantUserMembershipInvitationInterface, merchantWithRolesInterface } from '@/api/repositories/users';
import { useNarrativeStore } from '@/stores/narrative';
import useInertia from '@/composables/useInertia';
import API from '@/api';
import useFormatters from '@/composables/useFormatters';
import useAppPermissions from '@/composables/useAppPermissions';

const narrativeStore = useNarrativeStore();
const { hasRegisterMerchantsPermission } = useAppPermissions();
const { slugToTitleCase } = useFormatters();

const merchants = ref<merchantWithRolesInterface[]>([]);
const invitations = ref<merchantUserMembershipInvitationInterface[]>([]);

const loadingMerchants = ref(false);
const loadMerchants = async () => {
    loadingMerchants.value = true;
    API.repositories.users.fetchMerchantsForCurrentUser().then((merchantsResponse) => {
        merchants.value = merchantsResponse;
    }).finally(() => {
        loadingMerchants.value = false;
    });
};

const loadingInvitations = ref(false);
const loadInvitations = async () => {
    loadingInvitations.value = true;
    invitations.value = await API.repositories.users.fetchMerchantInvitationsForCurrentUser();
    loadingInvitations.value = false;
};

const respondingToInvitation = ref(false);
const acceptInvitation = (invitation: merchantUserMembershipInvitationInterface) => {
    respondingToInvitation.value = true;
    API.repositories.users.acceptMerchantInvitation(invitation.token).then(() => {
        loadMerchants();
        loadInvitations();
    }).finally(() => {
        respondingToInvitation.value = false;
    });
};

const declineInvitation = (invitation: merchantUserMembershipInvitationInterface) => {
    respondingToInvitation.value = true;
    API.repositories.users.declineMerchantInvitation(invitation.token).then(() => {
        loadMerchants();
        loadInvitations();
    }).finally(() => {
        respondingToInvitation.value = false;
    });
};

const selectingMerchant = ref(false);
const selectMerchant = (merchant: merchantWithRolesInterface) => {
    selectingMerchant.value = true;
    narrativeStore.setCurrentMerchant(merchant);

    useInertia().visit('/dashboard');
};

loadMerchants();
loadInvitations();
</script>
